import React from "react";
import AdminMarkers from "./admin-markers";
import ControlButtons from "items/control-buttons/control-buttons";
import AddTopicsItemContainer from "../add-admin-topic-item/add-admin-topic-item-container";
import AdminMarkersAddItemContainer from "./admin-markers-add-item/admin-marker-add-item-container";

import moment from "moment";
import { getFormatDate } from "service/format-date";

class AdminMarkersController extends React.Component{

    constructor(props){
        super(props);
        
        this.state = {
            currentPage: props.currentPage,
            showedItemsCount: props.showedItemsCount,
            topicsCount: props.adminTopics ? props.adminTopics.totalPages : 0,
            topicsPage: props.topicsPage,
            selectedItem: null,
            searchText: ''
        }
        this.controlButtons = [];
    }

    componentDidMount(){
        //if(!this.props.adminMarkers){
            this.props.getAllMarkers(0, this.state.showedItemsCount);
        //}
        //if(!this.props.adminTopics){
            this.props.getAllTopics(0, 9999, 'ACTIVE');
            
        //}
        //this.props.getMarkers();
    }

    setTopicsPageNumber = (page) => {
        this.props.setTopicsPage(page)
    }

    componentDidUpdate(){
        if(this.props.currentPage !== this.state.currentPage || this.props.showedItemsCount !== this.state.showedItemsCount){
            if(this.props.currentPage !== this.state.currentPage){
                this.setSelectedItem(null)
                this.closeRightMenu()
            }
            this.props.getAllMarkers(this.props.currentPage, this.props.showedItemsCount);
            this.setState({
                currentPage: this.props.currentPage,
                showedItemsCount: this.props.showedItemsCount,
                
            })
        }
        else{
            this.props.setItemsCount(this.props.adminMarkers && this.props.adminMarkers.totalElements );
            
        }
    }

    closeRightMenu = () => {
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
        this.setState({
            selectedItem: null
        })
    }

    formatDate = (date) =>{
        return getFormatDate(date, "HH:mm DD.MM.YY")//moment(date).format("HH:mm DD.MM.YY");
    }

    getTableHeader = (header) => {
        return  this.props.deviceType === 'mobile' ? header.filter((k,i) => [2,3,5].indexOf(i) === -1) : header.map(k=> this.props.getValue(k))
    }

    addMarkerToTopic = (topicId) => {
        this.props.makeTopicVisibleToMarker(this.props.adminMarkers.content[this.state.selectedItem].id, topicId)
        this.closeRightMenu()
    }

    showRightMenu = (show, markerId, index) => {
        this.closeRightMenu()
        if(show){
            let fmarker = this.props.adminMarkers.content[index];
            this.props.setShowRightMenu(true)
            switch(show){
                case 'addItem':
                    this.props.setRightMenuComponent(<AdminMarkersAddItemContainer markerId={fmarker.id} companyId={fmarker.companyId} role={fmarker.role}/>, 'markerItems', null, this.props.getValue('ATTACH_TOPIC_TO_MARKER'), true, this.props.getValue('ATTACH_TOPIC_TO_MARKER'), this.closeRightMenu);
                    //this.props.setRightMenuComponent(<AddTopicsItemContainer markerId={fmarker.id} page={this.props.topicsPage} setPage={this.setTopicsPageNumber} topic={fmarker} topicsCount={this.props.topicsCount} type={'topics'} callback={this.addMarkerToTopic} getValue={this.props.getValue}/>, 'markerItems', null, this.props.getValue('ATTACH_TOPIC_TO_ACCOUNT'), true, this.props.getValue('ATTACH_TOPIC_TO_ACCOUNT'));
                    break;
                case 'show':
                    this.props.setRightMenuComponent(<AdminMarkersAddItemContainer showOnly={true} markerId={fmarker.id} companyId={fmarker.companyId} role={fmarker.role}/>, 'markerItems', null, this.props.getValue('MAREKER_TOPICS'), true, this.props.getValue('ATTACH_TOPIC_TO_MARKER'), this.closeRightMenu);
            }          
            
        }
    }

    onTableItemsClick = (type, key, index) => {
        this.setState({
            title: type
        })
        this.title = type;

        switch(type){
            case 'add':
                this.showRightMenu('addItem', key, index)    
                break;
            case 'show':
                this.showRightMenu('show', key, index)    
                break;
        }
    }

    getControlButtonItems = (id, status, items, index, isSubtopic) => {
        return <ControlButtons keyId={id} callback={this.onTableItemsClick} status={status} items={items} index={index} isSubtopic={isSubtopic}/>
    }

    setTableValues = (tableValues) => {
        return tableValues.map( (k, i) => {
            let ctrl = [];
            ctrl = this.props.controlButtons 

                //this.controlButtons.push(this.getControlButtonItems(k.id, k.status, ctrl, i))
                let b = this.getControlButtonItems(k.id, k.status, ctrl, i)
            return [i+1, k.login, , k.email, this.formatDate(k.createdAt), this.props.getValue(k.status), b];
        })
    }

    setSelectedItem = (item) => {
        this.setState({
            selectedItem: item
        })
    }

    render(){
        return(
            <div>
                {this.props.adminMarkers && this.props.adminMarkers.content ? <AdminMarkers    
                                                    getValue={this.props.getValue} 
                                                    tableHeader={this.getTableHeader(this.props.tableHeader)}
                                                    controllerButtons={this.controlButtons}
                                                    controlButtons={this.props.controlButtons}
                                                    data={this.props.adminMarkers && this.props.adminMarkers.content.length && this.setTableValues(this.props.adminMarkers.content)}
                                                    markers={this.props.markers}
                                                    selectedItem={this.state.selectedItem}
                                                    setSelectedItem={this.setSelectedItem}
                                                    closeRightMenu={this.closeRightMenu}
                                                    deviceType={this.props.deviceType}
                            />
        : null}
            </div>

        )
        
    }

    componentWillUnmount(){
        this.closeRightMenu();
        this.props.setItemsCount(0);
        this.props.setCurrentPage(0);
        this.props.setShowedItemsCount(10);
        this.props.setTopicsPage(0)
    }
}

export default AdminMarkersController