import React, {useState, useEffect} from "react";
import PieChart from 'react-apexcharts';
import { ArrowUp, ArrowDown } from "../../commons/icon-contols" 
import s from "./apex-pie-chart.module.scss"

const ApexPiehart = (props) => {

  const getTotal = (values) => {
    var sum = values.reduce((accumulator, currentValue) => {
      return accumulator + currentValue
    },0);

    return sum
  }
    const [today, setToday] = useState(props.today);
    const [series, setSeries] = useState(props.data && props.data.values);
    const [labels,  setLabels] = useState(props.data && props.data.labels);
    const [width, setWidht] = useState(props.width ? props.width : 400)
    const [total, setTotal] = useState(getTotal(props.data.values))
    //const [isLegendClick, setIsLegendClick] = useState(false);
    
    useEffect(() => {                             
        setToday(props.today);
        setSeries(props.data.values);
        setLabels(props.data.labels);
        setWidht(props.width)
        setTotal(getTotal(props.data.values))
    }, [props]);

  const getArrowByValues = (yesterday, today) => {
    return today >= yesterday ? '↑' : '↓';
  }

  const formatNumber = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  const getText = (isText = true) => {
    return isText ? props.getValue('TOTAL_2') : total
  }


  let isLegendClick = false

    let options = {
      chart: {
        id: props.title,
        type: props.type,
        foreColor: '#ffffff',
        events: {
          legendClick: function(chartContext, seriesIndex, config) {
            isLegendClick = true
          },
          dataPointSelection: (e, chartContext, config)=>{!isLegendClick &&  props.clickOnElement(e, chartContext, config)},

        },
        stroke: {
          show: false,
          width:0
      },
        foreColor: 'var( --caption)'
      },
      title: {
        text: props.title ? props.getValue(props.title) : '',
        align: 'left',
        style: {
          color: 'var(--caption)'
        }
      },
      labels: labels,//    white-space: break-spaces;
      legend:{show: props.legend, position: 'right', whiteSpace: 'break-spaces', labels: {whiteSpace: 'break-spaces'} },

      /*responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: width
          },
          legend: {
            position: 'right'
          }
        }
      }],*/
      dataLabels: {
        enabled: false
      },
      noData: {
        text: props.noData ? props.noData : '',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '12px',
          fontFamily: undefined
        }
      },
      plotOptions: {
        pie: {
          size: 200,
          donut: {
            size: '85%',
            labels: {
              show: true,
            },
            
          },
        }
      }
      //colors: props.colors
    }
    if(props.colors) options.colors = props.colors
    const st = !parseInt(today) ? 'onCenter' : ''

    return (
        <div id={props.title} className={st} style={{background: props.background && props.background}}>
            <PieChart options={options} series={series} type={props.type} width={props.width} height={props.height}/>
        </div>
    )
}

export default ApexPiehart;