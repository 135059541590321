import React, { useEffect, useState } from "react";
import ApexChartContainer from "apex-charts/apex-chart-container";
import WordsBlock from "pages/extended-analytic/analytic/words-block/words-block";
import s from "./analytic.module.scss"

const Analytics = (props) => {
    const getLocations = (location) => {
        let name = props.locationsName && props.locationsName[location] ? props.locationsName[location] : location
        return name
    }

    const getData = (data) => {
        /*let res = {}
        res = data*/
        if(data.chartId === "AuthorsLocations"){
            data.labels = props.locationsName ? data.labels.map(k => getLocations(k)) : data.labels
        }
        return data
    }

    const [firstData, setFirstData] = useState(getData(props.firstData));
    const [secondData, setSecondData] = useState(props.secondData ? props.secondData : props.secondData);
    const [thirdData, setThirdData] = useState(props.thirdData ? props.thirdData : props.thirdData)
    const [firstMax, setFirstMax] = useState(props.firstMax);
    const [secondMax, setSecondMax] = useState(props.secondMax);

    useEffect(() => {
        setFirstData(getData(props.firstData));
        setSecondData(props.secondData ? props.secondData : props.secondData);
        setThirdData(props.thirdData ? props.thirdData : props.thirdData)
        setFirstMax(props.firstMax);
        setSecondMax(props.secondMax)
    }, [props.firstData, props.secondData, props.thirdData, props.deviceType])

    let firstHeight = firstData.height//firstData.chartId === 'AuthorsSentiment' || firstData.chartId === 'MediaType' || firstData.chartId === 'AuthorsLocations' || firstData.chartId === 'Top20' || firstData.chartId === 'KeyWords' ? 450 : 290
    let secondHeight = secondData.height//secondData.chartId === 'FollowersCount' || secondData.chartId === 'Top20Languages'  ? 450 : 290
    let thirdHeight = thirdData.height
    let firstWidth = firstData.width
    let secondWidth = secondData.width
    let thirdWidth = thirdData.width

    const setMaximum = (chart, max) => {
        props.setMaxData(chart, max)
    }

    return <div className={s.Analytics}>
        <div style={{width: '100%'}}>
        {((firstData && firstData.chartId === 'KeyWords') || (thirdData && thirdData.chartId === 'PopularWords' ) || (firstData.chartId === 'Tags')) 
        && <WordsBlock data={thirdData} getValue={props.getValue} pageType={thirdData.chartId}/>}
        </div>
        <div className={s.AnalyticChart} style={props.deviceType !== 'mobile' || props.deviceType !== 'tablet' ? { width : firstWidth} : {width: '100%'}}>
            {firstData && firstData.data && firstData.data.length ? 
                <ApexChartContainer 
                                    height={firstHeight}
                                    width={'100%'}
                                    setMax={setMaximum.bind(this, 'first')} 
                                    showLimit={firstData.max ? firstMax : null}
                                    title={firstData.title}
                                    type={firstData.chartType} 
                                    getValue={props.getValue} 
                                    data={{values: firstData.data, labels: firstData.labels, topicIDs: firstData.topicIDs}} 
                                    labels={firstData.labels} 
                                    colors={firstData.colors}
                                    fields={firstData.fields}
                                    fieldsType={firstData.fieldsType}
                                    legendPosition={firstData.chartId === "AuthorsLocations" && props.deviceType === 'mobile' ? 'bottom' : 'right'}
                                    stacked={true}
                                    legend={true}
                /> : <div></div>}
                
        </div>
        {secondData && secondData.data && secondData.data.length 
            ? <div className={s.AnalyticChart} style={ props.deviceType === 'mobile' || props.deviceType === 'tablet' ?  { width : '100%'} : {width: thirdWidth}}>
            
                <ApexChartContainer 
                                    height={secondHeight}
                                    width={'100%'}
                                    setMax={setMaximum.bind(this, 'second')} 
                                    showLimit={secondData.max ? secondMax : null}
                                    title={secondData.title}
                                    type={secondData.chartType} 
                                    getValue={props.getValue} 
                                    data={{values: secondData.data, labels: secondData.labels, topicIDs: secondData.topicIDs}} 
                                    labels={secondData.labels} 
                                    colors={secondData.colors}
                                    fields={secondData.fields}
                                    fieldsType={secondData.fieldsType}
                                    stacked={true}
                                    legend={true}
                                    /> 
            </div>
        : <div></div>}
        {thirdData && thirdData.data && thirdData.data.length && ((firstData && firstData.chartId !== 'KeyWords') && (firstData && firstData.chartId !== 'PopularWordsBurst' ) && (firstData.chartId !== 'Tags')) ?
            <div className={s.AnalyticChart} style={props.deviceType === 'mobile' || props.deviceType === 'tablet' ? { width : '100%'} : {width: thirdWidth}}><ApexChartContainer 
                height={thirdHeight}
                width={'100%'}
                setMax={setMaximum.bind(this, 'third')} 
                showLimit={thirdData.max ? firstMax : null}
                title={thirdData.title}
                type={thirdData.chartType} 
                getValue={props.getValue} 
                data={{values: thirdData.data, labels: thirdData.labels, topicIDs: thirdData.topicIDs}} 
                labels={thirdData.labels} 
                colors={thirdData.colors}
                fields={thirdData.fields}
                fieldsType={thirdData.fieldsType}
                legendPosition={thirdData.chartId === "AuthorsLocations" && props.deviceType === 'mobile' ? 'bottom' : 'right'}
                stacked={true}
                legend={true}
    /></div> : null
        }
    </div>
}

export default Analytics