import React, {useRef, useState, useEffect} from "react";
import s from "./multi-post-panel.module.scss";
import { StandartButton } from "commons/form-controls";
import { ReactComponent as SmileIcon } from 'assets/icons/smile.svg';
import { ReactComponent as NeutralSmileIcon } from 'assets/icons/neutral-smile.svg'; //agressive_smile
import { ReactComponent as SadSmileIcon } from 'assets/icons/sad-smile.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as AngleUpIcon } from 'assets/icons/angle-up.svg';
import { ReactComponent as RecycleBinIcon } from 'assets/icons/recycle-bin.svg';
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite.svg';
import Badge from "../badge/badge";
import useComponentVisible from "hooks/useComponentVisible"

const MultiPostPanel = (props) => {

    const [sentiment, setSentiment] = useState(null);
    const [favoriteStatus, setFavouriteStatus] = useState(props.page === 'FavouritePublications' ? false : true)
    const [pressedToFavouriteButton, setPressedToFavouriteButton] = useState(false);
    const [pressedToRecycleBinButton, setPressedToRecycleBinButton] = useState(false);
    const [posts, setPosts] = useState(props.posts)
    let {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false);

    useEffect(() => {
        setPosts(props.posts)
    }, [props.posts])

    const showTagsBlock = () => {
        setIsComponentVisible(!isComponentVisible);
        setFavouriteStatus(props.page === 'FavouritePublications' ? false : true);
        //(!showTags)
        
    }

    const getFilters = () => {
        let subtopics = props.selectedSubUUId ? {subtopicIds: [props.selectedSubUUId]} : [];
        let topics = {...props.selectedMyFilter}
        if(topics.topics)
            delete topics.topics
        return {...topics, ...subtopics}  
    }

    const setSentimentToList = (sentiment) => {
        let feedType = props.getFeedType(props.page)
        let filters = getFilters();
        setSentiment(sentiment)
        if(props.searchList)
            filters.clauses = props.searchList
        props.selectedAllList 
                            ? props.updateMultipleTopics(sentiment, props.selectedUUId ? props.selectedUUId : props.selectedTopics, feedType, props.startDate, props.endDate, props.grouping, filters, props.posts) //props.updateSentimentByFilter(props.selectedUUId, sentiment, props.startDate, props.endDate, props.grouping, props.selectedMyFilter) 
                            : props.SentimentsOfList(props.selectedPostItems, sentiment)
    }

    const getFormatedTag = (text) => {
        if(text && text.length >= 10)
            return text.substr(0, 10)+'...'
        else return text 
    }

    const getTagByUUId = (tagUUId) => {
        return props.tags.filter(k => tagUUId === k.uuid )
    }

    let otherTagsCount = 0;
    const tagsF = props.documentListTags && Object.entries(props.documentListTags).filter((k) => k[1] === props.selectedPostItems.length)

    const tags = tagsF.map( (k, i ) => {
                                        if(i < 3){
                                            let t = getTagByUUId(k[0])[0]; 
                                            return <span key={t.id} className={s.Tag} style={{background: t.color, color: t.textColor}}>{getFormatedTag(t.name)}</span>    
                                        }
                                        else{
                                            return null}
                            })
                                                                 
    const otherTags = tagsF.map( (k, i ) => {
        if(i >= 3){
            otherTagsCount++
            let t = getTagByUUId(k[0])[0]; 
            return <span key={t.id} className={s.Tag} style={{background: t.color, color: t.textColor}}>{getFormatedTag(t.name)}</span>    
        }
        else
            return null
    })

    const closeModal = () => {

    }

    const addTag = () => {
        props.modalShowToggle('AddTags', true, closeModal.bind(this), props.getValue('ADD_EXISTING_TAGS'), props.selectedAllList ? {signature: posts, type: 'list'} : {signature: props.selectedPostItems, type: 'page'})
    }

    const toRecycleBinButtonPress = (pressed) => {
        setPressedToRecycleBinButton(pressed)
    }

    const toRecycleBin = () => {
        if(pressedToRecycleBinButton) return
        let feedType = props.getFeedType(props.page)
        let filters = getFilters();
        if(props.searchList)
            filters.clauses = props.searchList
        let status = props.page === 'RecycleBin' ? 'RESTORED' : 'DELETED'
        props.selectedAllList 
                    ? props.updateMultipleTopicsStatus(status, props.selectedUUId ? props.selectedUUId : props.selectedTopics, feedType, props.startDate, props.endDate, props.grouping, filters, props.posts) //props.setStatusByFilter(props.selectedTopics, props.page === 'RecycleBin' ? 'RESTORED' : 'DELETED', props.startDate, props.endDate, props.grouping, props.selectedMyFilter) 
                    : props.setDocumentStatus(props.selectedPostItems, status, 'group')
        toRecycleBinButtonPress(true)
    }

    const toFavouriteButtonPress = (pressed) => {
        setPressedToFavouriteButton(pressed)
    }

    const toFavourite = () => {
        if(pressedToFavouriteButton) return
        let feedType = props.getFeedType(props.page)
        let filters = getFilters();
        if(props.searchList)
            filters.clauses = props.searchList
        props.selectedAllList
                            ? props.updateMultipleTopicsToFavourite(favoriteStatus, props.selectedUUId ? props.selectedUUId : props.selectedTopics, feedType, props.startDate, props.endDate, props.grouping, filters, props.posts)//props.setDocumentFavouriteFilterStatus(props.selectedUUId, favoriteStatus, props.startDate, props.endDate, props.grouping, props.selectedMyFilter) 
                            : props.setDocumentFavouriteStatus(props.selectedUUId ? props.selectedUUId : props.selectedTopics, favoriteStatus, props.selectedPostItems)
        toFavouriteButtonPress(true)
    }

    return (
        <div className={s.MultiPostPanel} ref={ref}>
            <div className={s.buttonsPanel}>
                {!props.selectedAllList && <div className={s.multiItem}>
                    <span className={s.labelText}>{props.getValue('SELECTED')}</span>
                    <Badge sum={props.selectedPostItems && props.selectedPostItems.length} />
                </div>}
                <div className={s.multiItem}>
                    <div className={s.sentiments}>
                        <div className={sentiment === 'POSITIVE' ? s.green +' '+ s.IconAttribute : s.IconAttribute} onClick={setSentimentToList.bind(this, 'POSITIVE')}><SmileIcon /></div>
                        <div className={sentiment === 'NEUTRAL' ? s.purple +' '+ s.IconAttribute : s.IconAttribute} onClick={setSentimentToList.bind(this, 'NEUTRAL')}><NeutralSmileIcon /></div>
                        <div className={sentiment === 'NEGATIVE' ? s.red +' '+ s.IconAttribute : s.IconAttribute} onClick={setSentimentToList.bind(this, 'NEGATIVE')}><SadSmileIcon /></div>
                    </div>
                </div>
                <div className={s.multiItem}>
                    {tags}
                    {(otherTags && otherTags.length > 3) && 
                                                        <div className={s.multiItem}>
                                                            <Badge label={'+'} sum={otherTags.length - 3}/>
                                                            <div>
                                                                <StandartButton background={'var(--white)'} color={'var(--darkPurple)'} callback={showTagsBlock} icon={AngleUpIcon}/>
                                                                { isComponentVisible && 
                                                                            <div className={s.tagsBlock}>
                                                                                {otherTags}
                                                                            </div>                                                                
                                                                    }
                                                            </div>
                                                        </div>}
                {props.role !== 'ROLE_MARKER' 
                                            ? <StandartButton name={props.getValue('ADD_TAG')} title={props.deviceType !== 'mobile' ? props.getValue('ADD_TAG') : ''} background={'var(--background)'} color={'var(--darkPurple)'} callback={addTag} icon={PlusIcon}/>
                                            : null
                }
                </div>
            </div>
            <div className={s.buttonsPanel}>
                    { props.role !== 'ROLE_MARKER' 
                                    ? <StandartButton name={props.getValue(favoriteStatus ? 'TO_FAVORITE' : 'FROM_FAVOURITE')} title={props.deviceType !== 'mobile' ? props.getValue(favoriteStatus ? 'TO_FAVORITE' : 'FROM_FAVOURITE') : ''} background={!pressedToFavouriteButton && 'var(--background)'} color={!pressedToFavouriteButton && 'var(--darkPurple)'} callback={toFavourite} icon={FavoriteIcon}/>
                                    : null
                    }
                {props.page !== 'RecycleBin' 
                    ? <StandartButton name={props.getValue('TO_TRASHBIN')} title={props.deviceType !== 'mobile' ? props.getValue('TO_TRASHBIN') : ''} background={!pressedToRecycleBinButton && 'var(--background)'} color={!pressedToRecycleBinButton && 'var(--darkPurple)'} callback={toRecycleBin} icon={RecycleBinIcon}/>
                    : <StandartButton name={props.getValue('FROM_TRASHBIN')} title={props.deviceType !== 'mobile' ? props.getValue('FROM_TRASHBIN') : ''} background={!pressedToRecycleBinButton && 'var(--background)'} color={!pressedToRecycleBinButton && 'var(--darkPurple)'} callback={toRecycleBin} icon={RecycleBinIcon}/>
                }
            </div>
        </div>
    )
}

export default MultiPostPanel

