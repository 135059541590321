import React, { useState, useEffect } from "react";
import { SelectDropdown } from "commons/input-controls";
import s from "./sort-filter.module.scss"

const SortFilter = (props) => {
    
    const getSort = () => {
        let sort = props.sortFilters.map((k, i) => {return {name: props.getValue(k.name), list: k.list}});
        return sort
    }

    const [selected, setSelected] = useState({name: props.getValue(props.sort.name), list: props.sort.list});
    const [sortItems, setSortItems] = useState(getSort());

    useEffect(() => {
        setSelected({name: props.getValue(props.sort.name), list: props.sort.list});
        setSortItems(getSort())
    }, [props.sort, props.sortFilters]);

    const setSelectedItem = (item, list) => {
        props.setSort(item)
    }

    return (
        <div className={s.SortFilter}>
            <SelectDropdown selected={selected.name} items={sortItems} setSelected={setSelectedItem} labelText={props.getValue('SORTING')}/>
        </div>
    )
}

export default SortFilter;