import { AccountService } from "../connection/account-service";
import { setSelectedLanguage } from "./languages"
import { checkErrorMessage, getErrorMessage } from "./errors";
import { setSuperadminCompany } from "store/superadmin"
import { setSuperadminMenuButtons } from "store/header";
import { AdminService } from "connection/admin-service";

const SET_ACCOUNT = "SET-ACCOUNT";
const CLEAR_ACCOUNT = "CLEAR_ACCOUNT";
const SET_ERROR_ACCOUNT = "SET-ERROR-ACCOUNT";
const SET_ADMIN_COMPANY_NAME = "SET-ADMIN-COMPANY-NAME";

let userAccount = {
    id: 0,
    companyId: 0,
    companyName: null,
    login: "",
    fio: "",
    email: "",
    phone: "",
    status: "",
    createdAt: "",
    updatedAt: "",
    role: null,
    demo: false,
    isSuperadmin: false,
    errorAccount: null
}

const getLanguageByCode = (lang) => {
    switch(lang){
        case 'ru':
            return 'RUS';
        case 'en':
            return 'ENG'
        case 'kk':
            return 'KAZ';
        default:
            return 'RUS'
    } 
}

const Account = (state = userAccount, action) => {
    switch(action.type){
        case SET_ACCOUNT:
            let account = action.account;
            account.isSuperadmin = (account.role === 'ROLE_SUPER_ADMIN' && !account.companyId) ? true : false
            return {...account}
        case CLEAR_ACCOUNT:
            return {...userAccount}
        case SET_ERROR_ACCOUNT:
            return {...state, errorAccount: action.errorAccount}
        case SET_ADMIN_COMPANY_NAME:
            return {...state, companyName: action.companyName}
        default:
            return state
    }
}

export default Account;

export const setAdminCompanyName = (company) => {
    return {
        type: SET_ADMIN_COMPANY_NAME,
        companyName: company
    }
}

export const setErrorAccount = (error) => {
    return {
        type: SET_ERROR_ACCOUNT,
        errorAccount: error
    }
}

export const setAccount = (data) => {
    return {
        type: SET_ACCOUNT,
        account: data
    }
}

export const clearAccount = () => {
    return {
        type: CLEAR_ACCOUNT
    }
}

export const getAdminCompany = () => {
    return (dispatch) => {
        AdminService.getCompany().then(data => {
            dispatch(setAdminCompanyName(data.name))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const getMe = (isSetSuperadminCompany = null, isSetSuperadminMenuButtons = null) => {
    
    return (dispatch) => {
        AccountService.getMe().then(data=>{
            let accountData = data.data
            dispatch(setAccount(accountData));
            if(accountData.locale) dispatch(setSelectedLanguage('type', getLanguageByCode(accountData.locale)));
            if(accountData && accountData.role){
                if(accountData.role === "ROLE_SUPER_ADMIN"){
                    
                    if(accountData.companyId){
                        dispatch(getAdminCompany(accountData.companyId))
                        if(isSetSuperadminCompany && isSetSuperadminMenuButtons){
                            dispatch(window.location.href = "/page/short-analytic")
                            dispatch(setSuperadminCompany(accountData.companyId))
                            dispatch(setSuperadminMenuButtons(isSetSuperadminCompany))
                        }
                    }
                    else{
                        if(isSetSuperadminMenuButtons === false){
                            dispatch(window.location.href = "/page/admin");
                            dispatch(setSuperadminCompany(null))
                            dispatch(setSuperadminMenuButtons(null))
                        }
                        else if((window.document.location.pathname !== '/page/admin') && ( window.document.location.pathname !== '/page/admin-accounts')){
                            dispatch(window.location.href = "/page/admin");
                            dispatch(setSuperadminCompany(null))
                            dispatch(setSuperadminMenuButtons(null))
                        }
                    }                    
                }
                else if(accountData.role === "ROLE_ADMIN"){
                    dispatch(getAdminCompany(accountData.companyId))
                }        
                
            }
            /*if(data.data.role === "ROLE_SUPER_ADMIN" && data.data.companyId && isSetSuperadminCompany && isSetSuperadminMenuButtons){

                    dispatch(window.location.href = "/page/short-analytic")

                dispatch(setSuperadminCompany(data.data.companyId))
                dispatch(setSuperadminMenuButtons(isSetSuperadminCompany))
            }
            else if(data.data.role === "ROLE_SUPER_ADMIN" && !data.data.companyId && (isSetSuperadminMenuButtons === false)){
               dispatch(window.location.href = "/page/admin");
               dispatch(setSuperadminCompany(null))
               dispatch(setSuperadminMenuButtons(null))
            }
            else if ((data.data.role === "ROLE_SUPER_ADMIN" && !data.data.companyId && window.document.location.pathname !== '/page/admin') && (data.data.role === "ROLE_SUPER_ADMIN" && !data.data.companyId && window.document.location.pathname !== '/page/admin-accounts')){
                dispatch(window.location.href = "/page/admin");
                dispatch(setSuperadminCompany(null))
                dispatch(setSuperadminMenuButtons(null))
            }*/
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const updateAccountData = (fio, email, phone, locale) => {
    return (dispatch) => {
        AccountService.updateAccount(fio, email, phone, locale).then( data => {
            dispatch(getMe())
        }).catch(error => {
           // let e = error && error.response && error.response.s
            dispatch(setErrorAccount(getErrorMessage(error)))
        })
    }
}

export const updateAccount = (fio, email, phone, locale) => {
    return (dispatch) => {
        AccountService.updateAccountData(fio, email, phone, locale).then( data => {
            dispatch(getMe())
        }).catch(error => {
           // let e = error && error.response && error.response.s
            dispatch(setErrorAccount(getErrorMessage(error)))
        })
    }
}