import React, { useEffect, useState } from "react";
import { ReactComponent as LeftArrowIcon } from "assets/icons/left-arrow.svg";
import { ReactComponent as RightArrowIcon } from "assets/icons/right-arrow.svg";
import { StandartButton } from "commons/form-controls";
import { SelectDropdown, InputTimeBlock, InputDateBlock, InputText } from "commons/input-controls";
import { ReactComponent as OKIcon } from 'assets/icons/ok.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import moment from "moment";

import s from "./datepicker.module.scss"

const Datepicker = (props) => {
    const getYear = (date) => {
        return moment(date).format("YYYY")
    }

    const getYears = (minYear) => {
        let years = []
        while(minYear <= Number(moment().format("YYYY"))){
            years.push(Number(minYear));
            minYear++
        }
        return years;
    }

    const getStartMonths = (months, date, type) => {
        let monthsList = []
        months.forEach((k, i) => {
            if(moment(date).format("YYYY") < moment().format("YYYY")){
                
                monthsList[i] = {name: props.getValue(k), value: i} 
            }
            else if(moment(date).format("YYYY") === moment().format("YYYY") && (type === 'startDate' ? i+1 < moment().format("M") : i+1 <= moment().format("M"))){
                
                monthsList[i] = {name: props.getValue(k), value: i}  
            }
        })
        return monthsList
    }

    const getEndWeekDay = (date) => {
        return moment(date, "YYYY-MM").daysInMonth()
    }

    const getTime = (date) => {
        let hour = Number(moment(date).format("HH"))
        let minute = Number(moment(date).format("mm"))
        return {h:hour, m: minute}
    }

    const [startDate, setStartDate] = useState(props.selectedStartDate);
    //const [startFirstDayWeek, setStartFirstDayWeek] = useState(getStartWeekDay(true));
    //const [startLastDayWeek, setStartLastDayWeek] = useState(getEndWeekDay(moment(props.startDate).format("YYYY-MM")));
    const [startMonth, setStartMonth] = useState(props.getMonth(props.startDate));
    const [selectedStartYear, setSelectedStartYear] = useState(getYear(props.startDate));
    const [selectedStartDate, setSelectedStartDate] = useState(props.selectedStartDate);
    const [startTime, setStartTime] = useState(getTime(props.selectedStartDate));
    
    //const [endFirstDayWeek, setEndFirstDayWeek] = useState(getStartWeekDay(false));
    //const [endLastDayWeek, setEndLastDayWeek] = useState(getEndWeekDay(moment(props.endDate).format("YYYY-MM")));
    const [endDate, setEndDate] = useState(props.selectedEndDate);
    const [endMonth, setEndMonth] = useState(props.getMonth(props.endDate));
    const [selectedEndYear, setSelectedEndYear] = useState(getYear(props.endDate));
    const [selectedEndDate, setSelectedEndDate] = useState(props.selectedEndDate);
    const [endTime, setEndTime] = useState(props.selectedEndDate ? getTime(props.selectedEndDate) : {h: 23, m: 59});
    const [startMonths, setStartMonths] = useState(getStartMonths(props.months, props.startDate, 'startDate'));
    const [endMonths, setEndMonths] = useState(getStartMonths(props.months, props.deviceType === 'mobile' ? props.startDate : props.endDate, 'endDate'));
    const [years, setYears] = useState(getYears(props.minYear));
    const [endYears, setEndYears] = useState(props.deviceType === 'mobile' ? props.minYear : getYears(getYear(props.startDate)))
    const [onFirstDate, setOnFirstDate] = useState(false)
    const [onSecondDate, setOnSecondDate] = useState(false)
    const [deviceType, setDeviceType] = useState(props.deviceType);
    const [touchmove, setTouchmove] = useState(null)
    const [disabledButton, setDisabledButton] = useState(props.disabledButton);

    useEffect(() => {
        //setStartFirstDayWeek(getStartWeekDay(true));
        //setStartLastDayWeek(getEndWeekDay(moment(props.startDate).format("YYYY-MM")));
        //setEndFirstDayWeek(getStartWeekDay(false));
        //setEndLastDayWeek(getEndWeekDay(moment(props.endDate).format("YYYY-MM")));
        setStartDate(props.selectedStartDate);
        setEndDate(props.selectedEndDate);
        setStartTime(getTime(props.selectedStartDate))
        setEndTime(props.selectedEndDate ? getTime(props.selectedEndDate) : {h: 23, m: 59});
        setStartMonth(props.getMonth(props.startDate));
        setEndMonth(props.getMonth(props.endDate));
        setStartMonths(getStartMonths(props.months, props.startDate, 'startDate'));
        setEndMonths(getStartMonths(props.months, props.deviceType === 'mobile' ? props.startDate : props.endDate, 'endDate'));
        setYears(getYears(props.minYear));
        setSelectedStartYear(getYear(props.startDate));
        setSelectedEndYear(getYear(props.endDate));
        setSelectedStartDate(props.selectedStartDate);
        setSelectedEndDate(props.selectedEndDate);
        setEndYears(getYears(props.deviceType === 'mobile' ? props.minYear : getYear(props.startDate)));
        setDeviceType(props.deviceType);
        setDisabledButton(props.disabledButton);
        //setEndTime(getTime(props.endDate));
    }, [props.startDate, props.endDate, props.selectedStartDate, props.selectedEndDate, props.deviceType, props.disabledButton])
const getWeeksOnMonth = (isFirst) => {
        let startDayWeek = props.getStartWeekDay(isFirst)
        let endDayWeek = isFirst ? getEndWeekDay(moment(props.startDate).format("YYYY-MM"))
                                 : getEndWeekDay(moment(props.endDate).format("YYYY-MM"));
        let weeks = [];
        let index = 0;
        let start = false
        let block = []
        for(let i = 0; i <= 5; i++){
            weeks[i] = [];
            let tr = getDaysOnWeek(i, index, start, startDayWeek, endDayWeek, isFirst)
            index = tr[0]
            start = tr[2]
            block[i] = <div key={i} className={s.week}>{tr[1]}</div>
        }
        return block
    }

    const isDateSelected = (day, isFirst) => {
        let date = (isFirst ? selectedStartYear : selectedEndYear) +'-'+moment(isFirst ? props.startDate : props.endDate).format("MM") +"-"+(day < 10 ? '0'+day : day)

        if(date === moment(selectedStartDate, 'YYYY-MM-DD').format("YYYY-MM-DD") && date === moment(selectedEndDate, 'YYYY-MM-DD').format("YYYY-MM-DD")){
            return s.selectedDay
        }
        if(date === moment(selectedStartDate, 'YYYY-MM-DD').format("YYYY-MM-DD")){
            return s.selectedStartDay
        }
        else if(date === moment(selectedEndDate, 'YYYY-MM-DD').format("YYYY-MM-DD")){
            return s.selectedEndDay
        }
        else if(moment(date).isAfter(moment(selectedStartDate)) && moment(date).isBefore(moment(selectedEndDate)) ){
            return s.intermediate;
        }
        else if(moment(date).isAfter(moment()) || !selectedEndDate && moment(date).isBefore(moment(selectedStartDate))){
            return s.disabledDay
        }
    }

    const getDaysOnWeek = (i, lastDay, start, startDayWeek, endDayWeek, isFirst) => {
        let block = []
        let day = lastDay;
        let selectedDay = '';
        
        for(let j = 1; j <= 7; j++){
            if(i === 0 && j === startDayWeek && !start){
                
                start = true
                block[j] = <div key={j}>{1}</div>
                day = 1
            }
            else if(day > endDayWeek){
                start = false;
                day = null
            }
            //if(){
                selectedDay = day && isDateSelected(day, isFirst)
            //}
            /*else{
                selectedDay = ''
            }*/
            
            block[j] = day 
                            ? <div key={j} onClick={selectedDay !== s.disabledDay 
                                ? props.selectStartDay.bind(this, day, moment(isFirst ? props.startDate : props.endDate).format("MM"), isFirst ? selectedStartYear 
                                : selectedEndYear) : null} className={s.day+' '+s.withDay+' '+selectedDay}>{day}</div>
                            : <div key={j} className={s.day}></div>
            if(start) { day++}
        }
        
        return [day, block, start]
    }

    const setSelectedYearDate = (year) => {
        props.setStartYear(year)
    }

    const setSelectedEndYearDate = (year) => {
        props.setEndYear(year)
    }

    const setSelectedStartMonth = (month) => {
        props.setStartMonth(month)
    }

    const setSelectedEndMonth = (month) => {
        props.setEndMonth(month)
    }

    const SetTime = (index, value, type) => {
        if(index === 'startDate'){
            //if(startDate)
            props.setStartTime(type, value)
        }
        else if(index === 'endDate'){
            props.setEndTime(type, value)
        }
    }

    const scrollElement = (e) => {
        if(e.deltaY < 0){
            props.nextPreviousStartMonth('next')
        }
        else{
            props.nextPreviousStartMonth('previous')
        }
    }

    const scrollSecondElement = (e) => {
        if(e.deltaY < 0){
            props.nextPreviousEndMonth('next')
        }
        else{
            props.nextPreviousEndMonth('previous')
        }
    }

    const touchMove = (e) => {
        setTouchmove("touch")
    }
    
    const weekDays = props.days.map((k, i) => <div key={i}><span>{props.getValue(k)}</span></div>)

    return (
        <div className={s.Datepicker}>
            <div className={s.CalendarsBlock}>
                <div style={{display: deviceType === 'mobile' ? 'none' : 'block' }}>
                    <div className={s.TopHeader}>
                        <span>{props.getValue('BEGIN')}</span>
                    </div>
                    <div className={s.DatePickerHeader}>
                        <div className={s.MonthDateBlock}>
                            <StandartButton icon={LeftArrowIcon} callback={props.nextPreviousStartMonth.bind(this, 'previous')} background={'var(--background)'}/>
                            {/*<span>{props.getValue(props.months[startMonth-1])}</span>*/}
                            <SelectDropdown selected={props.getValue(props.months[startMonth-1])} items={startMonths} setSelected={setSelectedStartMonth} height={200}/>
                            <StandartButton icon={RightArrowIcon} callback={props.nextPreviousStartMonth.bind(this, 'next')} background={'var(--background)'}/>
                        </div>
                        <div>
                            <SelectDropdown selected={selectedStartYear} items={years} setSelected={setSelectedYearDate} height={200}/>
                        </div>
                    </div>
                    <div 
                       //onTouchMove={touchMove}
                        onWheel={scrollElement}
                        className={s.DaysBlock}>
                        <div className={s.WeekDaysBlock}>
                            {/*touchmove*/}
                            {weekDays}
                        </div>
                        {getWeeksOnMonth(true)}
                    </div>
                    <div>
                        <div className={s.showDate}>
                            <div className={s.DateBlock}>
                                <InputText value={moment(startDate).format("DD/MM/YYYY")} readOnly={true} bordered={true} />
                            </div>
                            <div className={s.TimeBlock}>
                                <InputTimeBlock values={startTime} name={'startDate'} callback={SetTime} maxFirst={23} minFirst={0} maxSecond={59} minSecond={0}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={s.TopHeader}>
                        <span>{deviceType === 'mobile' ? '' : props.getValue('END')}</span>
                    </div>
                    <div className={s.DatePickerHeader}>
                        <div className={s.MonthDateBlock}>
                            <StandartButton icon={LeftArrowIcon} callback={props.nextPreviousEndMonth.bind(this, 'previous')} background={'var(--background)'}/>
                            {/*<span>{props.getValue(props.months[endMonth-1])}</span>*/}
                            <SelectDropdown selected={props.getValue(props.months[endMonth-1])} items={endMonths} setSelected={setSelectedEndMonth} height={200}/>
                            <StandartButton icon={RightArrowIcon} callback={props.nextPreviousEndMonth.bind(this, 'next')} background={'var(--background)'}/>
                        </div>
                        <div>
                            <SelectDropdown selected={selectedEndYear} items={endYears} setSelected={setSelectedEndYearDate} height={200}/>
                        </div>
                    </div>
                    <div onWheel={scrollSecondElement} className={s.DaysBlock}>
                        <div className={s.WeekDaysBlock}>
                            {weekDays}
                        </div>
                        {getWeeksOnMonth(false)}
                    </div>
                    <div>
                        {props.deviceType === 'mobile' ? <div className={s.showDate}>
                            <div className={s.DateBlock}>
                                <InputText value={moment(startDate).format("DD/MM/YYYY")} readOnly={true} bordered={true} />
                            </div>
                            <div className={s.TimeBlock}>
                                <InputTimeBlock values={startTime} name={'startDate'} callback={SetTime} maxFirst={23} minFirst={0} maxSecond={59} minSecond={0}/>
                            </div>
                        </div>  : null}
                        <div className={s.showDate}>
                            <div className={s.DateBlock}>
                                <InputText value={endDate ? moment(endDate).format("DD/MM/YYYY") : ''} readOnly={true} bordered={true} />
                            </div>
                            <div className={s.TimeBlock}>
                                <InputTimeBlock readOnly={!selectedEndDate ? true : false}  values={endTime} name={'endDate'} callback={SetTime} maxFirst={23} minFirst={0} maxSecond={59} minSecond={0}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={s.DatepickerButtonsBlock}>
                <StandartButton disabled={disabledButton} title={props.getValue('APPLY')} icon={OKIcon} callback={props.setPeriod}/>
                <StandartButton title={props.getValue('CANCEL')} icon={CloseIcon} stroke={'white'} callback={props.closeDatePicker}/>
            </div>
        </div>
    )
}

export default Datepicker;