const Menu = {
    AnlyticPages: [
        {
            title: 'MENTIONS',
            show: true,
            charts: ['languageRange', 'language']
        },
        {
            title: 'TONE',
            show: true,
            charts: ['SentimentRange', 'Sentiment', 'SentimentData']
        },
        {
            title: 'TYPES_OF_POSTS',
            show: true,
            charts: ['PublicationRange', 'Publication']
        },
        {
            title: 'SPECIAL_MARKS',
        },
        {
            title: 'AUTHORS',
            show: true,
            charts: ['AuthorsSentiment', 'FollowersCount']
        },
        {
            title: 'COMMUNITIES',
        },
        {
            title: 'TOP_20_MASS_MEDIA',
            show: true,
            charts: ['Top20', 'Top20Languages']
        },
        {
            title: 'SOURCE_TYPE',
            show: true,
            charts: ['MediaType']
        },
        {
            title: 'REGIONS',
        },
        {
            title: 'TAGS',
            show: true,
            charts: ['TagsList', 'Tags', 'TagsRange']
        },
        {
            title: 'REACH_OF_SOURCE',
            show: true,
            charts: ['AuthorsInvolvementSentiment', 'AuthorsInvolvementMediaTypes']
        },
        {
            title: 'KEY_WORDS',
            show: true,
            charts: ['KeyWords', 'KeyWordsRange', 'KeyWordsList']
        },
        {
            title: 'POPULAR_WORDS',
            show: true,
            charts: ['PopularWordsBurst', 'PopularWordsFacets', 'PopularWords']
        },
        {
            title: 'ENTITIES',
        },
        {
            title: 'GEOGRAPHY_OF_AUTHORS',
            show: true,
            charts: ['AuthorsLocations']
        },
        {
            title: 'AUTHOR_STATISTICS',
            show: true,
            charts: ['AuthorsAge', 'AuthorsGender']
        },
    ],
    ComparisonPages: [
        {
            title: "MENTIONS",
            show: true
        },
        {
            title: "MEDIA_TYPES",
            show: true
        },
        {
            title: "SENTIMENTS",
            show: true
        },
        /*{
            title: "SPECIAL_MARKS",
            show: true
        },*/
        {
            title: "LANGUAGES",
            show: true
        },
        {
            title: "MEDIA_SCALES",
            show: true
        },
        {
            title: "PROFILE_LOCATIONS",
            show: true
        },
    ],
    SettinsPages: [
        {
            name: "theme",
            title: "TOPICS",
            show: true
        },
        {
            name: "users",
            title: "USERS",
            show: true
        },
        {
            name: "didgest",
            title: "DIGESTS",
            show: true
        },
        {
            name: "orders",
            title: "REPORTS",
            show: true
        },
        {
            name: "Telegram",
            title: "TELEGRAM"
        },
        {
            name: "notification",
            title: "SUBSCRIPTION_TO_NOTIFICATIONS",
            show: true
        },
        {
            title: "SAVED_FILTERS",
            show: true
        },
        {
            title: "CONNECTED_ACCOUNTS"
        },
        {
            title: "USERS_LOGS"
        },
        {
            title: 'USER_TAGS',
            show: true
        }
    ],
    SettinsAdminPages: [
        {
            name: "theme",
            title: "TOPICS",
            show: true
        },
        {
            name: "users",
            title: "USERS",
            show: true
        },
        {
            name: "didgest",
            title: "DIGESTS",
            show: true
        },
        {
            name: "orders",
            title: "REPORTS",
            show: true
        },
        {
            name: "notification",
            title: "NOTIFICATIONS"
        },
        {
            name: "Telegram",
            title: "TELEGRAM",
            show: true
        },
        {
            title: "SAVED_FILTERS",
            show: true
        },
        {
            title: "CONNECTED_ACCOUNTS"
        },
        {
            title: "USERS_LOGS"
        },
        {
            title: 'USER_TAGS',
            show: true
        }
    ]
}

export default Menu