import React, { useState, useEffect } from "react";
import PaginatorContainer from "items/paginator/paginator-container";
import TableItem from "items/table/table";
import { InputText } from "commons/input-controls";
import { StandartButton } from "commons/form-controls";
import {ReactComponent as SearchIcon } from "assets/icons/search.svg";
import {ReactComponent as CancelIcon } from "assets/icons/close.svg";
import s from './admin-topics.module.scss'

const AdminTopics = (props) => {

    const [selectedItem, setSelectedItem] = useState(props.selectedItem);
    const [searchText, setSearchText] = useState('');
    const [searchStart, setSearchStart] = useState(false)

    useEffect(()=>{
        setSelectedItem(props.selectedItem)
        //setSearchStart(false);

    }, [props.selectedItem, props.controllerButtons])

    const selectTableITem = (item) => {
        if(selectedItem !== item){
            props.setSelectedItem(item, props.data[item]);
        }
    }

    const setText = (text) => {
        setSearchText(text)
    }

    const searchItem = (start) => {
        if(!start){
            setSearchText('');
            props.search('', true)
        }
        else
            props.search(searchText, true)
        setSearchStart(start)
    }

    const cancelButton = searchStart ? <StandartButton icon={CancelIcon} callback={searchItem.bind(this, false)} background={'var(--background)'}/> : null

    return <div className={s.AdminTopics}>
        <PaginatorContainer />
        <div className={s.SearchBlock}>
            <InputText value={searchText} callback={setText}/>
            {cancelButton}
            <StandartButton title={props.getValue('SEARCH_BY_TITLE')} icon={SearchIcon} callback={searchItem.bind(this, true)} fill={'white'}/>
        </div>
        { <TableItem 
                                    data={[props.tableHeader, props.data]} 
                                    getValue={props.getValue} 
                                    //titleText={props.getValue('TOPICS_LIST')}
                                    hiddenColumns={props.hiddenColumns}
                                    topics={props.topics}
                                    selectItem={selectTableITem}
                                    selectedItem={selectedItem}
                                    //controllerButtons={props.controllerButtons}
                                    //controlButtons={props.controlButtons}
                                    tableColWidth={{index: 7, width: 40}}
                                    height={415}
                                    weekDays={props.weekDays}
                                    hidden={props.deviceType === 'mobile' ? [3,4,5,6] : []}
                                    hiddenInEdit={[3,4]}
                                    />}
    </div>
}

export default AdminTopics;