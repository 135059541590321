import { MigrationService } from "connection/migration-service";
import { checkErrorMessage, getErrorMessage } from "./errors";
import { setItemsCount } from "./paginator";
import { TopicService } from "connection/topics-service";
import { SuperadminService } from "connection/superadmin-service";

const SET_MIGRATION_OPERATIONS = "SET-MIGRATION-OPERATIONS";
const SET_MIGRATION_OPERATION = "SET-MIGRATION-OPERATION";
const SET_MIGRATION_ID = "SET-MIGRATION-ID";
const SET_NUMFOUND = "SET-NUMFOUND";
const SET_TOPIC = "SET-TOPIC";
const SET_GET_NUMFOUND = "SET-GET-NUMFOUND";
const SET_COMPANY_TOPICS = "SET-COMPANY-TOPICS";
const SET_ACTIVE_COMPANIES = "SET-ACTIVE-COMPANIES";
const SET_MIGRATION_STARTED = "SET-MIGRATION-STARTED";
const SET_UPDATE_MIGRATION = "SET-UPDATE-MIGRATION";

const MIGRATION_COLLECTION = {
    dev:[
        'archive-new'
    ],
    local:[
        'archive-new'
    ],
    staging: [
        'archive-2025',
        'archive-2024',
        'archive-2023',
        'archive-2022',
        'archive-2021',
        'archive-2020',
        'archive-pre-2020'
    ],
    production:[
        'archive-2025',
        'archive-2024',
        'archive-2023',
        'archive-2022',
        'archive-2021',
        'archive-2020',
        'archive-pre-2020'
    ]
}

const migration = {
    migrations: null,
    migration: null,
    migrationId: null,
    companies: null,
    page: 0,
    count: 0,
    tableHeader: ['#', 'TOPIC_NAME', 'COMPANY', 'CREATED_AT', 'DATE_START', 'DATE_END', 'EXPECTED_DOCS / CURRENT_DOCS', 'TYPE', 'STATUS'],
    controlButtons: ["show"],
    numfound: null,
    topic: null,
    getNumfound: false,
    companyTopics: null,
    collections: MIGRATION_COLLECTION[`${process.env.REACT_APP_ENVIRONMENT}`]
}

const Migration = (state = migration, action) => {
    switch(action.type){
        case SET_MIGRATION_OPERATIONS:
            let data = action.migrations && action.migrations.content ? action.migrations.content : null;
            let count = action.migrations && action.migrations.totalElements ? action.migrations.totalElements : 0;
            let page = action.migrations && action.migrations.number ? action.migrations.number : 0
            return {...state, migrations: data, count: count, page: page}
        case SET_MIGRATION_OPERATION:
            return {...state, migration: action.migration}
        case SET_MIGRATION_ID:
            return {...state, migrationId: action.migrationId}
        case SET_NUMFOUND:
            return {...state, numfound: action.numfound}
        case SET_COMPANY_TOPICS:
            return {...state, companyTopics: action.companyTopics.content}
        case SET_TOPIC:
            return {...state, topic: action.topic ? action.topic.data : null}
        case SET_GET_NUMFOUND:
            return {...state, getNumfound: action.getNumfound}
        case SET_ACTIVE_COMPANIES:
            return {...state, companies: action.companies ? action.companies.content : null}
        case SET_UPDATE_MIGRATION:
            let migrations = [...state.migrations]
            let f = migrations.findIndex(k => k.id === action.operationId)
            if(f !== -1)
                migrations[f] = action.data;
            return {...state, migrations: migrations}
        default:
            return {...state}
    }
}

export default Migration;

export const setUpdateMigration = (migration, operationId) => {
    return {
        type: SET_UPDATE_MIGRATION,
        operationId: operationId,
        data: migration
    }
}

export const setCompanies = (companies) => {
    return {
        type: SET_ACTIVE_COMPANIES,
        companies: companies
    }
}

export const setGetNumFound = (getNumfound) => {
    return {
        type: SET_GET_NUMFOUND,
        getNumfound: getNumfound
    }
}

export const setTopic = (topic) => {
    return {
        type: SET_TOPIC,
        topic: topic
    }
}

export const setCompanyTopics = (topics) => {
    return {
        type: SET_COMPANY_TOPICS,
        companyTopics: topics
    }
}

export const setNumFound = (numfound) => {
    return {
        type: SET_NUMFOUND,
        numfound: numfound
    }
}

export const setMigrationId = (migrationId) => {
    return {
        type: SET_MIGRATION_ID,
        migrationId: migrationId
    }
}

export const setMigrationOperations = (migrations) => {
    return {
        type: SET_MIGRATION_OPERATIONS,
        migrations: migrations
    }
}

export const setMigrationOperation = (migration) => {
    return {
        type: SET_MIGRATION_OPERATION,
        migration: migration
    }
}
//'ACTIVE', 0, 9999
export const getCompanies = (query, page, pageSize) => {
    
    return (dispatch) => {
        SuperadminService.getSuperadminCompanies(query, page, pageSize).then(data=>{
            dispatch(setCompanies(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })

    }
}

export const getCompanyTopics = (companyId) => {
    return (dispatch) => {
        SuperadminService.getSuperadminCompanyTopics(companyId).then(data=>{
            dispatch(setCompanyTopics(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        })

    }
}

export const getMigrationOperations = (page = 0, pageSize = 20) => {
    return (dispatch) => {
        
        MigrationService.getMigrationOperations(page, pageSize).then(data => {
            dispatch(setMigrationOperations(data))
            dispatch(setItemsCount(data.totalElements))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const getMigrationOperation = (operationId) => {
    return (dispatch) => {
        
        MigrationService.getMigrationOperation(operationId).then(data => {
            dispatch(setMigrationOperation(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const getMigrationNumFound = (topicUUID, type, collection, topic, filter) => {
    return (dispatch) => {
        
        MigrationService.getMigrationFoundNumber(topicUUID, type, collection, topic, filter).then(data => {
            dispatch(setNumFound(data))
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const startMigration = (topicUUID, type, collection, topic, filter) => {
    return (dispatch) => {
        
        MigrationService.startMigration(topicUUID, type, collection, topic, filter).then(data => {
            dispatch(getMigrationOperations)
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const pauseMigration = (operationId) => {
    return (dispatch) => {
        MigrationService.pauseMigration(operationId).then( data => {
            dispatch(setUpdateMigration(data, operationId))
        })
    }
}

export const resumeMigration = (operationId) => {
    return (dispatch) => {
        MigrationService.resumeMigration(operationId).then( data => {
            dispatch(setUpdateMigration(data, operationId))
        })
    }
}

export const retryMigration = (operationId) => {
    return (dispatch) => {
        MigrationService.retryMigration(operationId).then( data => {
            dispatch(setUpdateMigration(data, operationId))
        })
    }
}

export const getTopic = (topicId) => {
    return (dispatch) => {
        TopicService.getTopic(topicId).then( data => {
            dispatch(setTopic(data));
            dispatch(setGetNumFound(true));
        })
    }
}