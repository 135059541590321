import { createSelector } from "reselect";
import { getTranslate } from "assets/titles/title-text";
import { getTagsRangeSelector } from "./extended-analytic-selector";

const getFirst = (state, chartNumber) => {

    if(chartNumber === 'first'){
        let first = state.ExtendedAnalytic.first;
        if(state.ExtendedAnalytic.first && state.ExtendedAnalytic.first.type === 'Tags' && state.Tags.tags){
            first.tags = state.Tags.tags
            first.firstMax = state.ExtendedAnalytic.firstMax
        }
        return first
    }
    else if(chartNumber === 'second'){
        let second = state.ExtendedAnalytic.second;
        if(state.ExtendedAnalytic.second && state.ExtendedAnalytic.second.type === 'TagsRange' && state.Tags.tags){
            second.tags = state.Tags.tags
            second.secondMax = state.ExtendedAnalytic.firstMax
        }
        return second;
    }
    else if(chartNumber === 'third'){
        let st = state.ExtendedAnalytic.third
        if(state.ExtendedAnalytic.third && state.ExtendedAnalytic.third.type && state.ExtendedAnalytic.third.type === 'TagsList' && state.Tags.tags){
            st.tags = state.Tags.tags
        }
        return st
    }
    else return state.ExtendedAnalytic.first;
}

const getSecond = (state) => {
    return state.ExtendedAnalytic.second;
}

const red = "#E25353";
const green = "#29B593";
const blue = "#5EAAE1";
const grey = "#C6C6D1";
const gray = "#9A9EC3";

const getSentimentName = (sentiment) => {
    switch(sentiment){
        case 'neutral':
            return 'NEUTRAL_3'
        case 'undefined':
            return 'NOT_DETERMINDED_3'
        case "negative":
            return "NEGATIVE_3"
        case "positive":
            return "POSITIVE_3"
    }
}

const getToneColor = (sentiment) => {
    switch(sentiment){
        case 'neutral':
        case 'NEUTRAL':
        case 'NEUTRAL_3':
            return blue;
        case 'undefined':
        case 'UNDEFINED':
        case 'NOT_DETERMINDED_3':
            return gray;
        case "negative":
        case 'NEGATIVE':
        case "NEGATIVE_3":
            return red;
        case "positive":
        case 'POSITIVE':
        case "POSITIVE_3":
            return green;
    }
}

const getByField = () => {
    
}

const getColorsByChartType = (pageType, item) => {
    switch(pageType){
        case 'sentiment':
            return getToneColor(item);
        default:
            return null
    }
}

const getItems = (items, item) => {
    if(items.indexOf(item) === -1){
        items.push(item)
    }
    return items
}

const getTypeValues = (typeValue, items) => {
    let type = typeValue.value;
    let types = items;
    let index = -1;
    if(types.indexOf(type) !== -1){
        index = types.indexOf(type)
    }
    else{
        index = types.push(type)-1
    }
    return {index: index, count: typeValue.count, name: typeValue.value}
}

const getLang = (item) => { 
    let val = item.value ? item.value : item;
    switch(val){
        case 'kz':
            return {lang: 'kz', index: 0};
        case 'ru':
            return {lang: 'ru', index: 1};
        case 'en':
            return {lang: 'en', index: 2};
        default:
            return {lang: 'OTHERS', index: 3};
    }
}

const getTagName = (tagId, tags) => {
    let tag = tags ? tags.filter(k => k.uuid === tagId) : [];
    
    return tag && tag[0] ? tag[0] : null
}

const getLangItems = (item, items) => { 
    let val = item.value ? item.value : item;
    let it = 0
    if(val === 'kz' || val === 'ru' || val === 'en'){
        items.push({name: val, count: item.count})
        it = items.length
    }
    else{
        let othersIndex = items.findIndex(f => f.name === 'OTHERS')
        
        if(othersIndex !== -1){
            items[othersIndex].count += item.count
            it = othersIndex
        }
        else{
            items.push({name: 'OTHERS', count: item.count})
            it = items.length
        }
    }
    return {items: items, index: it}
}

const getLanguageIndex = (items, item) => {
    let langsList = ['kz', 'ru', 'en'];
    if(langsList.indexOf(item) === -1){
        item = 'OTHERS'
    }
    let index = items.indexOf(item)
    let list = {}
    if( index !== -1){
        list = {index: index, items: items, name: item}
    }
    else{
        items.push(item)
        list = {index: items.length-1, items: items, name: item}
    }
    return list
}

const setFields = (fields, field, item, index) => {

    let items = [];
    if(!fields[field]){
        fields[field] = [];
    }
    switch(field){
        case 'language':
            let l = getLang(item);
            fields[field][index] = item
            break;
        case 'sentiment':
            fields[field][index] = item.toUpperCase();
            break;
        case 'type':
            if(item !== 'video'){
                fields[field][index] = item.toUpperCase();;
            }
            break;
        default:
            fields[field][index] = item;
    }
    return fields
    
}

const langsItems = (items, item) => {
    if(item !== 'kz' && item !== 'ru' && item !== 'en'){
        item = "OTHERS"
    }
    let lang = items.indexOf(item)
    if( lang === -1){
        items.push(item)
        lang = items.indexOf(item)
    }
    return {index: lang, items: items, item: item}
}

const getLabels = (items, type = 'value', settings, checkItems) => {
    let labels = [];
    let values = [];
    let text = [];

    let itemsList = [];
    let langList = [];
    let colors = ["#5EAAE1", "#E25353", "#29B593", "#F58219", "#5555A0", "#EAE460", "#B5DD5F", "#9A9EC3", "#134E8D", "#00CA5F", " #AA4A44"];
    let fields = {};
    let fieldsType = {seriesIndex: null, dataPointIndex: null}
    let indexes = null;
    let datas = items.map(k => 0);
    let total = 0;
    let keywords = [];

    items.forEach((k, i) => {
        let t = k.values ? k.values : k.pivots;
        
        if(k.value === 'null'){
            return
        }
        switch(settings.label){
            case 'value':
            case 'PopularValue':
                let tagsName = null
                if(k.field === 'tags'){
                    tagsName = k.value//getTagName(k.uuid, checkItems)
                    if(!tagsName) return;
                }
                values[i] = {name: k.field === 'tags' ? tagsName : getTranslate(k.value), list: k.value, data: []}
                
                break;
            case 'valueSentiment':
            case 'PopularValueSentiment':
                //values[i] = {name: k.value, list: k.value, data: []}
                //fields = setFields(fields, k.field, k.value, i);
               break;
            case 'sentiment':
                values[i] = {name: getTranslate(k.value), list: k.value.toUpperCase(), data: []}
                if(settings.label === 'sentiment'){
                    if(!colors){
                        colors = []
                    }
                    colors[i] = getColorsByChartType('sentiment', k.value)
                }
                break;
            case 'postType':
                if(k.value !== 'video')
                    values[i] = {name: getTranslate(k.value), list: k.value.toUpperCase(), data: []}
                
                break;
            case 'languageDate':
                break;
            case 'involement':

                text[i] = k.value
                labels[i] = getTranslate(k.value)

                break;
            case 'followers_count':

                text[i] = k.value
                //datas[i] = 0
                if(k.field === 'media_type') labels[i] = getTranslate(k.value)
                else labels[i] = k.value;
                break;
            case 'field':
                labels[i] = k.value;
                break;
            case 'LanguageSentiment':
                if(i === 0){
                    colors = []
                }
                break;
            case 'ages':
                text[i] = k.value
                if(!values.length)
                    values[0] = {name: getTranslate('COUNT'), data: []}
                labels[i] = k.value
                values[0].data[i] = k.count
                total += k.count
                //setFields()
                break;
            case 'genders':
                values[i] = {name: k.value, data: [k.count]}
                break;
            case 'keywords':
                values[i] = {name: k.value, data: [], count: k.count}
                break
            case 'tags':
                let tagName = k.value
                let tagColor = k.color
                let tagTextColor = k.textColor
                if(checkItems && checkItems.length){
                    tagName = k.value//getTagName(k.uuid, checkItems)
                    tagColor = k.color ? k.color : ''
                }
                
                if(!tagName)
                    return
                values[i] = {name: tagName, data: [], count: k.count, color: tagColor, textColor: tagTextColor}

                break;
            case 'popular':
                values[i] = {
                                name: k.word, 
                                count: k.count, 
                                neutral: k.neutralCount, 
                                positive: k.positiveCount, 
                                negative: k.negativeCount, 
                                undefined: k.undefinedCount, 
                                advertising: k.advertising, 
                                agression: k.aggression, 
                                swearing: k.swearing, 
                                rowNum: k.rownum
                            }
                            total += k.count
            break;
                
        }
        //fields = setFields(fields, k.field, k.value, i);
        Array.isArray(t) && t.map((p, iterator)=>{
            
                switch(settings.label){
                    case 'valueSentiment':
                    case 'PopularValueSentiment':
                        itemsList = getItems(itemsList, p.value)
                        let sentiment = getTypeValues(p, itemsList)
                        let tagName = k.value
                        if(k.field === 'tags'){
                            tagName = k.value//getTagName(k.uuid, checkItems)
                            if(!tagName) return;
                        }
                        if(!values[sentiment.index]){
                            values[sentiment.index] = {name: sentiment.name, data: []}
                        }
                        values[sentiment.index].name = getTranslate(sentiment.name)
                        values[sentiment.index].data[i] = sentiment.count ? sentiment.count : 0;

                        labels[i] = k.field === 'tags' ? tagName : k.value
                        colors[sentiment.index] = getToneColor(p.value);
                        if(settings.label !== 'PopularValueSentiment'){
                            fields = setFields(fields, k.field, k.uuid ? k.uuid : k.value, i);
                            fields = setFields(fields, p.field, sentiment.name, sentiment.index);
                            fieldsType.seriesIndex = k.field;
                            fieldsType.dataPointIndex = p.field;
                        }
                        total += sentiment.count

                        break;
                    case 'value':
                    case 'PopularValue':
                        //let tagNames = k.value
                        labels[iterator] = getTranslate(p.value);
                        if(!values[i])
                            values[i] = {name: getTranslate(p.value), data: [0,0,0,0]}
                        values[i].data[iterator] = p.count
                        if(settings.label !== 'PopularValue'){
                            fields = setFields(fields, p.field, p.value, iterator);
                            fields = setFields(fields, k.field, k.uuid ? k.uuid : k.value, i);
                            fieldsType.seriesIndex = p.field;
                            fieldsType.dataPointIndex = k.field;
                        }
                        total += p.count
                        break;
                    case 'sentiment':
                        labels[iterator] = getTranslate(p.value);
                        values[i].data[iterator] = p.count
                        fields = setFields(fields, 'date', p.value, iterator);
                        fields = setFields(fields, 'sentiment', k.value, i);
                        fieldsType.seriesIndex = 'date';
                        fieldsType.dataPointIndex = 'sentiment';
                        colors[i] = getToneColor(k.value);
                        total += p.count
                        break;
                    case 'postType':
                        if(k.value !== 'video'){
                            labels[iterator] = p.value
                            values[i].data[iterator] = p.count
                            text[iterator] = p.value
                        }
                        fields = setFields(fields, 'type', k.value, i);
                        fields = setFields(fields, 'date', p.value, iterator);
                        fieldsType.seriesIndex = 'date';
                        fieldsType.dataPointIndex = 'type';
                        total += p.count
                        break;
                    case 'typeOfPosts':
                        if(k.value !== 'total'){

                            let items = langsItems(langList, k.value)
                            langList = items.items
                            let index = items.index;
                            let item = items.item
                            labels[index] = getTranslate(item);
                            text[index] = getTranslate(item);

                            itemsList = getItems(itemsList, p.value)
                            let sentiment = getTypeValues(p, itemsList)
                            if(!values[sentiment.index]){
                                values[sentiment.index] = {name: sentiment.name, data: [0, 0, 0, 0]}
                            }
                            
                            values[sentiment.index].name = getTranslate(sentiment.name);
                            
                            values[sentiment.index].data[index] += sentiment.count
                            fields = setFields(fields, 'language', item, index);
                            fields = setFields(fields, 'type', p.value, sentiment.index);
                            fieldsType.seriesIndex = 'language';
                            fieldsType.dataPointIndex = 'type';
                        }
                        else{
                            total = p.count;
                        }
                        break;
                    case 'languageDate':
                        labels[iterator] = p.value
                        let items = langsItems(langList, k.value)
                        
                        langList = items.items
                        let index = items.index;
                        let item = items.item
                        
                        if(!values[index]){
                            values[index] = {name: getTranslate(item), data: []}
                            values[index].data[iterator] = 0;
                        }
                        if(item !== 'OTHERS') values[index].data[iterator] = p.count
                        else{
                            if(!values[index].data[iterator])
                                values[index].data[iterator] = 0;
                            values[index].data[iterator] += parseInt(p.count)
                        }
                        fields = setFields(fields, 'language', item, index);
                        fields = setFields(fields, 'date', p.value, iterator);
                        fieldsType.seriesIndex = 'date';
                        fieldsType.dataPointIndex = 'language';
                        total += p.count
                        break;
                    case 'involement':
                        itemsList = getItems(itemsList, p.value)
                        if(!values[iterator]){
                            values[iterator] = {name: getTranslate(p.value), data: []}
                            colors[iterator] = getColorsByChartType('sentiment', p.value)
                        }
                        values[iterator].data[i] = p.count
                        //fields = setFields(fields, p.field, p.value, iterator);
                        fieldsType.seriesIndex = p.field;
                        total += p.count
                        break;
                    case 'followers_count':
                        if(k.value !== 'null'){
                            if(p.field === 'language'){

                                let ls = getLanguageIndex(itemsList, p.value)
                                indexes = ls.index;
                                itemsList = ls.items;
                                let name = ls.name
                                if(!values[indexes])
                                    values[indexes] = {name: getTranslate(name), list: p.value.toUpperCase(), data: [p.count]}
                                else
                                    values[indexes].data[i] = p.count
                                fields = setFields(fields, p.field, p.value, indexes);
                                fields = setFields(fields, k.field, k.value, i)
                            }
                            else if(p.field === 'sentiment'){
                                itemsList = getItems(itemsList, p.value)
                                let sentiment = getTypeValues(p, itemsList)
                                if(!values[sentiment.index]){
                                    
                                    values[sentiment.index] = {name: getTranslate(p.value), data: [...datas]}
                                }
                                colors[sentiment.index] = getToneColor(p.value)
                                text[sentiment.index] = p.value;
                                values[sentiment.index].data[i] = p.count
                                //if(!values[sentiment.index].data[i]) values[sentiment.index].data[i] = 0
                                if(p.field === 'sentiment'){
                                    fields = setFields(fields, p.field, p.value, sentiment.index);
                                }
                                //else {
                                    if(k.field === 'media_type') fields = setFields(fields, 'mediaType', k.value.toUpperCase(), i);
                                    else fields = setFields(fields, k.field, k.value, i);
                                //}
                                
                            }
                            else{
                                if(!values[iterator]){

                                    values[iterator] = {name: getTranslate(p.value), list: p.value.toUpperCase(), data: []}
                                }
                                text[iterator] = p.value;
                                values[iterator].data[i] = p.count
                                if(p.field === 'sentiment'){
                                    fields = setFields(fields, p.field, p.value, iterator);
                                }
                                else if(p.field === 'media_type'){
                                    fields = setFields(fields, 'mediaType', p.value.toUpperCase(), iterator);
                                }
                                //else {
                                    fields = setFields(fields, k.field, k.value, i);
                                //}
                                
                            }
                            if(k.field === 'media_type') fieldsType.seriesIndex = 'mediaType'
                            else fieldsType.seriesIndex = k.field
                            
                            fieldsType.dataPointIndex = p.field
                            total += p.count
                        }
                        break;
                    case 'field':
                        if(!values[iterator])
                            values[iterator] = {name: getTranslate(p.field), data: []}
                        values[iterator].data[i] = p.value
                        fields = setFields(fields, p.field, p.value, iterator);
                        
                        break;
                    case 'LanguageSentiment':
                        if(k.value !== 'total'){
                                let items = langsItems(langList, k.value)
                                langList = items.items
                                let index = items.index;
                                let item = items.item
                                
                                itemsList = getItems(itemsList, p.value)
                                let sentiment = getTypeValues(p, itemsList)
                                if(!values[sentiment.index]){
                                    values[sentiment.index] = {name: '', data: [0, 0, 0, 0]}
                                }
                                values[sentiment.index].name = getTranslate(sentiment.name);
                                values[sentiment.index].data[index] += sentiment.count
                                labels[index] = getTranslate(item);
                                colors[sentiment.index] = getToneColor(p.value);
                                fields = setFields(fields, 'language', item, index);
                                fields = setFields(fields, 'sentiment', p.value, sentiment.index);
                                fieldsType.seriesIndex = 'language';
                                fieldsType.dataPointIndex = 'sentiment';
                        }
                        else{
                            total = p.count
                        }
                        break;
                    case 'keywords':
                    case 'tags':
                        itemsList = getItems(itemsList, p.value)
                        let sentiments = getTypeValues(p, itemsList)
                        values[i][p.value] = p.count
                        labels[i] = k.value
                        colors[sentiments.index] = getToneColor(p.value);
                        fields = setFields(fields, k.field, k.value, i);
                        fields = setFields(fields, p.field, sentiments.name, sentiments.index);
                        fieldsType.seriesIndex = k.field;
                        fieldsType.dataPointIndex = p.field;
                        //values[i].data = keywords
                        total += p.count
                        break;
                }
                
            })
    })

    let retValues = {labels: labels, data: values, text: text, values: values, fields: fields, fieldsType: fieldsType, total: total}
    if(colors) retValues.colors = colors
    return retValues
}

export const getExtendedData = createSelector(getFirst, (mediaTypes) => {
    let d = {data: [], values: [], labels: [], colors: ["#F58219", "#9AAAB5", "#9A9EC3", "#EE6766", "#3373B7"], topicIDs: [], chartType: null, pageType: [], title: '', isChanges: true}
    d = checkData(mediaTypes, d)
    return d
})

export const getExtendedDataS = createSelector(getFirst, (mediaTypes) => {
    let d = {data: [], values: [], labels: [], colors: null, topicIDs: [], chartType: null, title: '', isChanges: true}
    d = checkData(mediaTypes, d)
    return d
})

export const getExtendedThird = createSelector(getFirst, (mediaTypes) => {
    let d = {data: [], values: [], labels: [], colors: null, topicIDs: [], chartType: null, title: '', isChanges: true, tags: mediaTypes.tags}
    d = checkData(mediaTypes, d)
    return d
})
const getTagsWithName = (items, tags) => {
    let ret = items.filter(k => {
        let r = tags.findIndex(j => j.uuid === k.value)
        if(r !== -1){
            k.value = tags[r].name
            k.uuid = tags[r].uuid
            k.color = tags[r].color
            k.textColor = tags[r].textColor
            return k
        }
    })
    return ret;
}

const checkData = (mediaTypes, d) => {
    d.topicIDs = mediaTypes && mediaTypes.topicId ? mediaTypes.topicId : [];
    d.chartType = mediaTypes && mediaTypes.chartType ? mediaTypes.chartType : [];
    d.pageType = mediaTypes && mediaTypes.pageType ? mediaTypes.pageType : null;
    d.title = mediaTypes && mediaTypes.chartSettings ? mediaTypes.chartSettings.title : '';
    d.height = mediaTypes && mediaTypes.chartSettings ? mediaTypes.chartSettings.height : 290;
    d.width = mediaTypes && mediaTypes.chartSettings ? mediaTypes.chartSettings.width : '100%';
    d.chartId = mediaTypes && mediaTypes.type ? mediaTypes.type : null;
    d.max = mediaTypes && mediaTypes.chartSettings && mediaTypes.chartSettings.max ? mediaTypes.chartSettings.max : null
    d.total = 0
    if(mediaTypes && mediaTypes.data && mediaTypes.data.length){
        let data = (mediaTypes.type === 'Tags' || mediaTypes.type === 'TagsRange' || mediaTypes.type === 'TagsList') ? getTagsWithName(mediaTypes.data, mediaTypes.chartSettings.tags) : mediaTypes.data;
        let ret = mediaTypes.chartType === 'column' || mediaTypes.chartType === 'line' || mediaTypes.chartType === 'area' ? getLabels(data, mediaTypes.chartNumber, mediaTypes.chartSettings) : getSecondData(data, mediaTypes.chartNumber, mediaTypes.chartSettings);
        d.labels = ret.labels;
        d.data = ret.data
        d.text = ret.text
        d.colors = ret.colors ? ret.colors : null
        d.fields = ret.fields
        d.fieldsType = ret.fieldsType
        d.total = ret.total
        
        //d.graphicType: , //graphicType: {label: 'date', data: 'sentiment'}
    }

    if(mediaTypes && mediaTypes.chartSettings && mediaTypes.chartSettings.graphicType) d.graphicType = mediaTypes.chartSettings.graphicType

    return d
}

const getSecondData = (items, type = 'value', settings) => {
    let colors = ["#5EAAE1", "#F58219", "#29B593", "#E25353", "#5555A0", "#EAE460", "#B5DD5F", "#9A9EC3", "#134E8D", "#00CA5F", " #AA4A44"];
    let labels = [];
    let values = [];
    let itemsList = [];
    let fields = {};
    let total = 0;
    let fieldsType = {
        seriesIndex: null,
        dataPointIndex: null
    }
    items.forEach((k, i) => {
        if(settings.label !== 'involement'){
            
            if(settings.label === 'PieValue'){
                let langItems = getLangItems(k, itemsList)
                itemsList = langItems.items
                let index = langItems.index
                values = itemsList;
                //values.push(k.count);
                labels = itemsList.map(k => getTranslate(k.name));
                values = itemsList.map(k => k.count);
                if(itemsList[index]) fields = setFields(fields, k.field, itemsList[index].name, index);
                if(i === items.length - 1)
                itemsList.forEach((k, j) => {
                    fields = setFields(fields, 'language', k.name, j)
                })
            }
            else{
                labels[i] = getTranslate(k.value);
                values[i] = k.count;
            }
            if(settings.label === 'sentiment'){
                colors[i] = getToneColor(k.value);
            }

        }
        else if(settings.label === 'involement'){
            labels[i] = getTranslate(k.value);
            k.pivots.forEach((p,j) => {
                if(!values[i])
                    values[i] = 0
                values[i] += Number(p.count)
                total += parseInt(p.count) ? parseInt(p.count) : 0     
            })
        }
        if(settings.label !== 'genders' && settings.label !== 'authorGeography' && settings.label !== 'PieValue' && settings.label !== 'involement')
            fields = setFields(fields, k.field, k.value, i);
        else if(settings.label === 'genders'){

            if(k.value === 'Male')
                colors[i] = "#29B593";
            else colors[i] = "#E25353";
        }
        fieldsType.seriesIndex = k.field;
        total += k.count && k.count !== undefined ? k.count : 0
    })
    
    return {labels: labels, data: values, fields: fields, fieldsType: fieldsType, colors: colors, total: total}
}
