const Titles = {
    "_COMMENT_0_":"авторизация",
    "LOGIN" : "LOGIN",
    "PASSWORD" : "PASSWORD",
    "WELLCOME_TO_THE" : "Welcome",
    "FCBK_ECHO": "FCBK Echo",
    "MEDIA_MONITORING" : "Media Monitoring",
    "DON'T_REMEMBER_PASSWORD" : "DON'T REMEMBER PASSWORD",
    "PREVIOUS_VERISON" : "Previous version of the system",
    "SIGN_IN" : "Sing in",
    "DON'T_HAVE_ACCOUNT" : "Don't have account?",
    "REGISTRATION" : "Registration",
    "WRONG_EMAIL" : "WRONG EMAIL ADDRESS",
    "EMAIL_AND_PASSWORD_COMBINATION_WASN'T_FOUND" : "COMBINATION OF EMAIL AND PASSWORD WASN'TFOUND",
    "_COMMENT_1":"модальное окно восстановления пароля",
    "PASSWORD_RESTORE" : "Password restore",
    "CONTINUE" : "Continue",
    "SORRY_SUCH_MAIL_OR_LOGIN_IS_NOT_REGISTERED" : "SORRY, SUCH EMAIL OR LOGIN IS NOT REGISTERED",
    "TRY_AGAIN" : "Try again",
    "CANCEL" : "Cancel",
    "THE_LETTER_WITH_INSTRUCTIONS_WAS_SENT_TO_YOUR_EMAIL_ADDRESS" : "THE LETTER WITH INSTRUCTIONS WAS SENT TO YOUR EMAIL ADDRESS",
    "CLOSE" : "Close",
    "_COMMENT_2":"окно регистрации пользователя",
    "USER_REGISTRATION" : "User registration",
    "UNIQUE_LOGIN" : "UNIQUE LOGIN",
    "SORRY_SUCH_LOGIN_IS_BUSY" : "SORRY, SUCH LOGIN IS BUSY",
    "8-12_CHARACTER_LATIN_CAPITAL_LETTERS_NUMBERS" : "8-12 CHARACTER, LATIN, CAPITAL LETTERS, NUMBERS",
    "PASSWORD_REPEAT" : "PASSWORD REPEAT",
    "SORRY_WRONG_PASSWORD_REPEAT" : "SORRY, WRONG PASSWORD REPEAT",
    "OPTIONAL_FIELDS" : "OPTIONAL FIELDS",
    "FULL_NAME" : "FULL NAME",
    "YOUR_FULL_NAME" : "YOUR FULL NAME",
    "EMAIL_EXAMPLE" : "EXAMPLE@EMAIL.COM",
    "PHONE" : "PHONE",
    "YOUR_PHONE_NUMBER" : "YOUR PHONE NUMBER",
    "NOT_VALID_EMAIL" : "NOT VALID EMAIL ADDRESS",
    "NOT_VALID_PHONE" : "NOT VALID PHONE NUMBER",
    "SEND" : "Send",
    "ALLREADY_HAVE_ACCOUNT" : "Allready have account?",
    "_COMMENT_3":"форма обратной связи",
    "FEEDBACK_FORM" : "Feedback form",
    "CONTACT_DETAILS" : "CONTACT DETAILS",
    "PHONE_NUMBER_OR_E-MAIL_ADDRESS_SEPARATED_WITH_A_COMMA" : "PHONE NUMBER_OR_E-MAIL_ADDRESS_SEPARATED_WITH_A_COMMA",
    "EMAIL_OR_LOGIN" : "E-MAIL ADDRESS OR LOGIN",
    "EMAIL" : "EMAIL",
    "MESSAGE_TEXT" : "MESSAGE TEXT",
    "WRITE_HERE_WHAT_YOU_ARE_INTERESTED_IN" : "WRITE HERE, WHAT YOU ARE INTERESTED IN",
    "WE_ARE_ALWAYS_AWAILABLE_AT" : "We are always awailable at:",
    "OUR_EMAIL" : "echo@1cb.kz",
    "OR_BY_PHONE" : "or by phone:",
    "OUR_PHONE" : "+7 (727) 325 35 00",
    "_COMMENT_4":"основное меню",
    "FAVORITE_POSTS" : "Favorite posts", 
    "FAVORITE_AUTHORS_AND_SOURCES" : "favorite authors and sources",
    "BLACK_LIST" : "Black list",
    "TRASHBIN" : "Trashbin",
    "LIGHT" : "light",
    "MIX" : "mix",
    "DARK" : "dark",
    "RUSSIAN" : "Русский",
    "KAZAKH" : "Қазақ",
    "ENGLISH" : "English",
    "BELORUSSIAN": "Belorussian",
    "UKRAINIAN": "Ukrainian",
    "GERMAN": "German",
    "DUTCH": "Dutch",
    "FRENCH": "French",
    "ITALIAN": "Italian",
    "SPANISH": "Spanish",
    "DANISH": "Danish",
    "CATALAN": "Catalan",
    "SLOVENIAN": "Slovenian",
    "PORTUGUESE": "Portuguese",
    "ROMANIAN": "Romanian",
    "SLOVAK": "Slovak",
    "NORWEGIAN": "Norwegian",
    "FINNISH": "Finnish",
    "POLISH": "Polish",
    "GREEK": "Greek",
    "ESTONIAN": "Estonian",
    "SWEDISH": "Swedish",
    "HUNGURIAN": "Hungarian",
    "LITHUANIAN": "Lithuanian",
    "THAI": "Thai",
    "SHORT_ANALYTICS" : "Short analitics",
    "SELECTION" : "Selection",
    "EXTENDED_ANALYTICS" : "Extended analitics",
    "TOPICS_COMPARISON" : "Topics comparison",
    "REPORTS_BUILDER" : "Reports builder",
    "AUTO_RULES" : "Auto rules",
    "STATISTIC_DESIGN" : "Statistic design",
    "NOTIFICATIONS" : "Notifications",
    "FAQ" : "FAQ",
    "CONTACT_US" : "Contact us",
    "_COMMENT_5":"верхнее меню краткой аналитики",
    "TOPICS" : "Topics",
    "MASS_MEDIA" : "Mass media",
    "SOC_MEDIA" : "Soc. media",
    "TOTAL_1" : "Total",
    "DIAGRAMS" : "Diagrams",
    "COMPARISON" : "Comparison",
    "_COMMENT_5_1":"фильтры страницы краткой аналитики по темам",
    "ALPHABETICALLY_A-Z_↓" : "ALPHABETICALLY A-Z ↓",
    "ALPHABETICALLY_Z-A_↑" : "ALPHABETICALLY Z-A ↑",
    "LAST_VIEWED_DATE_↓" : "LAST VIEWED DATE ↓",
    "LAST_VIEWED_DATE_↑" : "LAST VIEWED DATE ↑",
    "MONITORING_START_DATE_↓" : "MONITORING START DATE ↓",
    "MONITORING_START_DATE_↑" : "MONITORING START DATE ↑",
    "BY_LAST_PUBLICATION_DATE_↓" : "ПО ДАТЕ ПОСЛЕДНЕЙ ПУБЛИКАЦИИ ↓",
    "BY_LAST_PUBLICATION_DATE_↑" : "ПО ДАТЕ ПОСЛЕДНЕЙ ПУБЛИКАЦИИ ↑",
    "NUMBER_OF_NEW_POSTS_↓" : "NUMBER OF NEW POSTS ↓",
    "NUMBER_OF_NEW_POSTS_↑" : "NUMBER OF NEW POSTS ↑",
    "NUMBER_OF_POSTS_FOUND_↓" : "NUMBER OF POSTS FOUND ↓",
    "NUMBER_OF_POSTS_FOUND_↑" : "NUMBER OF POSTS FOUND ↑",
    "_COMMENT_5_2":"фильтры страницы краткой аналитики по масс-медиа, по социальным медиа, по суммарному значению",
    "NUMBER_OF_NEW_POSTS_FOR_THIS_DAY_↓" : "NUMBER OF NEW POSTS FOR THIS DAY ↓",
    "NUMBER_OF_NEW_POSTS_FOR_THIS_DAY_↑" : "NUMBER OF NEW POSTS FOR THIS DAY ↑",
    "NUMBER_OF_NEW_POSTS_FOR_THIS_WEEK_↓" : "NUMBER OF NEW POSTS FOR THIS WEEK ↓",
    "NUMBER_OF_NEW_POSTS_FOR_THIS_WEEK_↑" : "NUMBER OF NEW POSTS FOR THIS WEEK ↑",
    "NUMBER_OF_POSTS_FOR_THE_ENTIRE_MONITORING_PERIOD_↓" : "NUMBER OF POSTS FOR THE ENTIRE MONITORING PERIOD ↓",
    "NUMBER_OF_POSTS_FOR_THE_ENTIRE_MONITORING_PERIOD_↑" : "NUMBER OF POSTS FOR THE ENTIRE MONITORING PERIOD ↑",
    "NUMBER_OF_NEW_POSTS_WITH_A_POSITIVE_TONE_↓" : "NUMBER OF NEW POSTS WITH A POSITIVE TONE ↓",
    "NUMBER_OF_NEW_POSTS_WITH_A_POSITIVE_TONE_↑" : "NUMBER OF NEW POSTS WITH A POSITIVE TONE ↑",
    "NUMBER_OF_NEW_POSTS_WITH_A_NEUTRAL_TONE_↓" : "NUMBER OF NEW POSTS WITH A NEUTRAL TONE ↓",
    "NUMBER_OF_NEW_POSTS_WITH_A_NEUTRAL_TONE_↑" : "NUMBER OF NEW POSTS WITH A NEUTRAL TONE ↑",
    "NUMBER_OF_NEW_POSTS_WITH_A_NEGATIVE_TONE_↓" : "NUMBER OF NEW POSTS WITH A NEGATIVE TONE ↓",
    "NUMBER_OF_NEW_POSTS_WITH_A_NEGATIVE_TONE_↑" : "NUMBER OF NEW POSTS WITH A NEGATIVE TONE ↑",
    "NUMBER_OF_NEW_POSTS_WITH_AGGRESIVE_CONTENT_↓" : "NUMBER OF NEW POSTS WITH AGGRESIVE CONTENT ↓",
    "NUMBER_OF_NEW_POSTS_WITH_AGGRESIVE_CONTENT_↑" : "NUMBER OF NEW POSTS WITH AGGRESIVE CONTENT ↑",
    "NUMBER_OF_NEW_POSTS_IN_SOURCES_WITH_A_LARGE_AUDIENCE_↓" : "NUMBER OF NEW POSTS IN SOURCES WITH A LARGE AUDIENCE ↓",
    "NUMBER_OF_NEW_POSTS_IN_SOURCES_WITH_A_LARGE_AUDIENCE_↑" : "NUMBER OF NEW POSTS IN SOURCES WITH A LARGE AUDIENCE ↑",
    "_COMMENT_6":"Прочие элементы страницы краткой аналитики",
    "NEW_MESSAGES" : "NEW MESSAGES",
    "WEEK" : "WEEK",
    "TOTAL_2" : "Total",
    "TODAY" : "TODAY",
    "TODAY_STATISTIC" : "TODAY STATISTIC (FROM 00:00)",
    "_COMMENT_7":"страница выборки",
    "TOPIC_NOT_SELECTED" : "Topic not selected",
    "ALL_ON_PAGE" : "All on page",
    "ALL_LIST" : "All list",
    "SHOW" : "SHOW",
    "ADD_POST" : "Add post",
    "DOWNLOAD" : "Download",
    "SELECTED" : "SELECTED",
    "COMMON_TAGS" : "COMMON TAGS",
    "SHARE" : "Share",
    "TO_FAVORITE" : "To favorite",
    "TO_BLACK_LIST" : "To black list",
    "FROM_BLACK_LIST" : "From black list",
    "TO_TRASHBIN" : "To trashbin",
    "FROM_TRASHBIN" : "From trashbin",
    "ANSWER" : "Answer",
    "ADD_TAG" : "Add tag",
    "ARTICLE" : "ARTICLE",
    "TRANSCRIBATION" : "TRANSCRIBATION",
    "POST_3" : "POST",
    "REPOST" : "SHARE",
    "EXTENDED_REPOST" : "EXTENDED SHARE",
    "COMMENT" : "COMMENT",
    "review" : "Reviews",
    "REVIEWS" : "Reviews",
    "REVIEW_2": "Review",
    "TODAY_2" : "Today",
    "WEEK_2" : "Week",
    "MONTH" : "Month",
    "YEAR": "Year",
    "TEXT_FILTER" : "SEARCH TEXT",
    "TEXT_FILTER_INSTRUCTION": "SEARCH TEXT INSTRUCTIONS",
    "_COMMENT_8":"панель фильтрации",
    "SELECTION_DATES_FILTER" : "Select period",
    "BEGIN" : "Begin",
    "END" : "End",
    "MON" : "MON",
    "TUE" : "TUE",
    "WED" : "WED",
    "THU" : "THU",
    "FRI" : "FRI",
    "SAT" : "SAT",
    "SUN" : "SUN",
    "JAN" : "January",
    "FEB" : "Febrary",
    "MAR" : "March",
    "APR" : "April",
    "MAY" : "May",
    "JUN" : "June",
    "JUL" : "Jule",
    "AUG" : "August",
    "SEP" : "September",
    "OKT" : "Oktober",
    "NOV" : "November",
    "DEC" : "December",
    "SOURCE_TYPE" : "Type of source",
    "SOCIAL_NETWORKS" : "Social networks",
    "SOCIAL" : "Social",
    "FACEBOOK" : "Facebook",
    "INSTAGRAM" : "Instagram",
    "VKONTAKTE" : "Vkontakte",
    "YOUTUBE" : "YouTube",
    "TWITTER" : "Twitter",
    "TELEGRAM" : "Telegram",
    "ODNOKLASSNIKI" : "Odnoklassniki",
    "RUTUBE" : "RuTube",
    "YANDEXZEN" : "YandexZen",
    "TIKTOK" : "TikTok",
    "MOIMIR" : "Moi Mir",
    "RADIO" : "Radio (transcribation)",
    "TELEVISION" : "Television (transcribation)",
    "TV" : "Television (transcribation)",
    "PRINT_MASS_MEDIA_PAPER" : "Print mass media (paper version)",
    "PRINT_MASS_MEDIA_WEB" : "Print mass media (internet version)",
    "INTERNET_MASS_MEDIA" : "Internet mass media",
    "INTERNET" : "Internet",
    "NEWS_AGENCIES" : "News agencies",
    "NEWS_AGENCY" : "News agency",
    "POST_TYPE" : "Type of post",
    "ARTICLE_2" : "Article",
    "TRANSCRIBATION_2" : "Transcribation",
    "POST_2" : "Post",
    "REPOST_2" : "Share",
    "ADDED_REPOST_2" : "Extended share",
    "COMMENT_2" : "Comment",
    "SOURCES" : "Sources",
    "TAGS" : "Tags",
    "KEY_WORDS" : "Key words",
    "TONE_AGGRESSION_ADVERTISEMENT" : "Tone, aggresion, advertisement",
    "NEUTRAL" : "Neutral",
    "POSITIVE" : "Positive",
    "NEGATIVE" : "Negative",
    "WITH_OBSCENE_VOCABULARY" : "With obscene vocabulary",
    "WITH_AGGRESSION" : "With aggression",
    "WITH_ADVERTISEMENT" : "With advertisement",
    "WITH_CHECKED_TONE" : "With checked tone",
    "WITH_UNDEFINED_TONE" : "With undefined tone",
    "WITH_TONE_MODIFIED_BY_AUTORULE" : "With tone modified by autorule",
    "MODIFIED_BY_AUTORULE" : "Modified by autorule", 
    "AUDIENCE_MAS_MEDIA_COVERAGE_INVOLVEMENT" : "Media scale",
    "INTERNATIONAL_MAS_MEDIA" : "International mass media",
    "REPUBLICAN_MAS_MEDIA" : "Republican mass media",
    "REGIONAL_MAS_MEDIA" : "Regional mass media",
    "PRINT_MAS_MEDIA" : "Printed mass media",
    "PRINT" : "Printed mass media",
    "VERIFICATION_STATUS": "verification status",
    'VERIFICATIONSTATUS': 'Verification status',
    "VERIFIED": "Verified",
    "DELETED": "Deleted",
    "DUPLICATE": "Duplicate",
    "NONRELEVANT": "Non relevant",
    "OLD": "Old",
    "RESTORED": "Restored",
    "UNVERIFIED": "Unverified",
    "ANY": "Any",
    "MORE_THAN_MILLION_SUBSCRIBERS" : "More than 1 000 000 subscribers",
    "LESS_THAN_MILLION_MORE_THAN_ONE_HUNDRED_THOUSANDS_SUBSCRIBERS" : "Less than 1 000 000 and more than 100 000 subscribers",
    "LESS_THAN_ONE_HUNDRED_THOUSANDS_MORE_THAN_TEN_THOUSANDS_SUBSCRIBERS" : "Less than 100 000 and more than 10 000 subsribers",
    "LESS_THAN_TEN_THOUSANDS_MORE_THAN_THOUSAND_SUBSCRIBERS" : "Less than 10 000 andd more than 1 000 subscribers",
    "LESS_THAN_THOUSAND_SUBSRIBERS" : "Less than 1 000 subscribers",
    "AUDIENCE_NOT_DETERMINDED" : "Audience not determinded",
    "POST_LANGUAGE" : "Post language",
    "KAZAKH_2" : "Kazakh",
    "RUSSIAN_2" : "Russian",
    "ENGLISH_2" : "English",
    "NOT_DETERMINDED" : "Not determinded",
    "GEOGRAPHY" : "Geography",
    "NUR_SULTAN" : "Nur-sultan",
    "ALMATY" : "Almaty",
    "SHYMKENT" : "Shymkent",
    "AKMOLA_REGION" : "Akmola region",
    "AKTOBE_REGION" : "Aktobe region",
    "ALMATY_REGION" : "Almaty region",
    "ATYRAU_REGION" : "Atyrau region",
    "EAST_KAZAKHSTAN_REGION" : "East-Kazakhstan region",
    "ZHAMBYL_REGION" : "Zhambyl region",
    "WEST_KAZAKHSTAN_REGION" : "West-Kazakhstan region",
    "KARAGANDY_REGION" : "Karagandy region",
    "KOSTANAY_REGION" : "Kostanay region",
    "KYZYLORDA_REGION" : "Kyzylorda region",
    "MANGISTAU_REGION" : "Mangistau region",
    "PAVLODAR_REGION" : "Pavlodar region",
    "NORT_KAZAKHSTAN_REGION" : "Nort-Kazakhstan region",
    "TURKESTAN_REGION" : "Turkestan region",
    "ABAY_REGION" : "Abay region",
    "GETISU_REGION" : "Getisu region",
    "ULYTAU_REGION" : "Ulytau region",
    "RUSSIA" : "Russia",
    "KIRGIZSTAN" : "Kirgizstan",
    "CIS" : "CIS",
    "OTHERS": "Others",
    "OTHER" : "Other",
    "NOT_DETERMINDED_2" : "Not determinded",
    "LOCATION" : "Location",
    "PERSON" : "Person",
    "ORGANIZATION" : "Organization",
    "_COMMENT_9":"избранные публикации",
    "TOPIC" : "TOPIC",
    "TOPICS" : "TOPICS",
    "BREAKDOWN_BY" : "BREAKDOWN BY",
    "HOURS": "Hours",
    "DAYS" : "Days",
    "WEEKS" : "Weeks",
    "MONTHS" : "Months",
    "CHOOSE_ALL_TOPICS" : "Choose all topics",
    "CHOOSE_ALL_SUBTOPICS" : "Choose all subtopics",
    "_COMMENT_10":"избранные авторы и источники",
    "TITLE" : "TITLE",
    "TYPE" : "TYPE",
    "SOURCE" : "SOURCE",
    "SUBSCRIBERS" : "SUBSCRIBERS",
    "LAST_POST_DATE" : "LAST POST DATE",
    "PUBL_TOTAL" : "PUBLICATIONS IN TOTAL",
    "PUBL_IN_SELECTION" : "PUBLICATIONS IN SELECTION",
    "MARKS" : "MARKS",
    "TONE" : "TONE",
    "TONES" : "TONES",
    "ACTIONS" : "ACTIONS",
    "AUTHORS_AND_SOURCES" : "Authors and sources",
    "POSTS_2" : "Posts",
    "_COMMENT_10_1":"фильтры таблицы авторов и источников",
    "ALPHABETICALLY_A-Z_LATIN_↓" : "ALPHABETICALLY A-Z ↓",
    "ALPHABETICALLY_Z-A_LATIN_↑" : "ALPHABETICALLY A-Z ↑",
    "ASC" : "↓",
    "DESC" : "↑",
    "DATE_↓" : "BY DATE ↓",
    "DATE_↑" : "BY DATE ↑",

    "LIKES_↓": "LIKES_↓",
    "LIKES_↑": "LIKES_↑",

    "COMMENTS_↓": "COMMENTS_↓",
    "COMMENTS_↑": "COMMENTS_↑",

    "SHARES_↓": "SHARES_↓",
    "SHARES_↑": "SHARES_↑",

    "COVERAGE_↓": "COVERAGE_↓",
    "COVERAGE_↑": "COVERAGE_↑",
    "AGGRESIVE_FIRST" : "AGGRESIVE FIRST",
    "OBSCENE_FIRST" : "OBSCENE FIRST",
    "ADVERTISEMENT_FIRST" : "ADVERTISEMENT FIRST",
    "POSITIVE_TONE_FIRST_↓" : "POSITIVE TONE FIRST ↓",
    "POSITIVE_TONE_FIRST_↑" : "POSITIVE TONE FIRST ↑",
    "NEUTRAL_TONE_FIRST_↓" : "NEUTRAL TONE FIRST ↓",
    "NEUTRAL_TONE_FIRST_↑" : "NEUTRAL TONE FIRST ↑",
    "NEGATIVE_TONE_FIRST_↓" : "NEGATIVE TONE FIRST ↓",
    "NEGATIVE_TONE_FIRST_↑" : "NEGATIVE TONE FIRST ↑",
    "_COMMENT_11":"развернутая аналитика",
    "DOWNLOAD_REPORT" : "Download report",
    "FORMAT_REPORT_AND_WILL_BE_SENDED_TO_EMAIL": "Format report and it will be sended to your email",
    "REPORT_IN_PROGRESS_AND_WILL_AVAILABLE_IN_NOTIFICATIONS": "Report in progress and will available in notifications",
    "REPORT_IN_PROGRESS_AND_WILL_BE_SENDED_TO_EMAIL": "Report in progress and will be sended to email",
    "DASHBOARDS_LAYOUT" : "Dashboards layout",
    "MENTIONING_BY_DATE" : "Mentioning by date",
    "MENTIONING_BY_LANG" : "Mentioning by language",
    "NEWSBREAK" : "Newsbreaks",
    "TOTAL" : "TOTAL",
    "CREATE" : "Create",
    "MERGE_NEWSBREAKS" : "Merge newsbreaks",
    "NEUTRAL_3" : "NEUTRAL",
    "NOT_DETERMINDED_3" : "NOT DETERMINDED",
    "POSITIVE_3" : "POSITIVE",
    "NEGATIVE_3" : "NEGATIVE",
    "TONE_BY_DATE" : "Tone by date",
    "TONE_BY_LANG" : "Tone by language",
    "RUSSIAN_3" : "RUSSIAN",
    "KAZAKH_3" : "KAZAKH",
    "ENGLISH_3" : "ENGLISH",
    "POST_TYPES_BY_DATES" : "Types of posts by dates",
    "POST_TYPES_BY_LANG" : "Types of posts by languages",
    "SPECIAL_MARKS_BY_DATES" : "Special marks by dates",
    "SPECIAL_MARKS_BY_LANG" : "Special marks by languages",
    "AGGRESION" : "AGGRESION",
    "OBSCENE_SPEECH" : "OBSCENE SPEECH",
    "ADVERTISEMENT" : "ADVERTISEMENT",
    "AUTHORS" : "Authors",
    "AUTHORS_NAME": "Authors name",
    "INFLUENCE_OF_AUTHORS" : "Influence of authors",
    "NUMBER_OF_SUBSCRIBERS" : "NUMBER OF SUBSCRIBERS",
    "COMMUNITIES" : "Communities",
    "INFLUENCE_OF_COMMUNITIES" : "Influence of communities",
    "MENTIONS" : "Mentions",
    "TONE" : "Tone",
    "SENTIMENT" : "Tone",
    "TYPES_OF_POSTS" : "Types of posts",
    "SPECIAL_MARKS" : "Special marks",
    "TOP_20_MASS_MEDIA" : "Top-20 mass media",
    "MASS_MEDIA_TYPE" : "Type of mass media",
    "REGIONS" : "Regions",
    "COVERAGE": "Coverage",
    "SITE_COVERAGE": "Site coverage",
    "REACH_OF_SOURCE" : "Reach of source",
    "REACH_OF_SOURCE_BY_SENTIMENT" : "Reach of source by sentiment",
    "REACH_OF_SOURCE_BY_COUNT" : "Reach of source by count",
    "POPULAR_WORDS" : "Popular words",
    "ENTITIES" : "Entities",
    "GEOGRAPHY_OF_AUTHORS" : "Geography of authors",
    "AUTHOR_STATISTICS" : "Statistics of authors",
    "AUTHOR_STATISTICS_GENDER" : "Author statistics by gender",
    "AUTHOR_STATISTICS_AGE" : "Author statistics by age",
    "TOP_20_MASS_MEDIA_BY_LANG" : "Top-20 mass media by languages",
    "TONE_BY_MASS_MEDIA_TYPES" : "Tone by types of source",
    "NEWS_AGENCIES_2" : "NEWS_AGENCIES",
    "WEBSITES" : "WEBSITES",
    "PRINT_MASS_MEDIA" : "PRINT MASS MEDIA",
    "TELEVISION_2" : "TELEVISION",
    "TONE_BY_REGIONS" : "Tone by regions",
    "SOURCE_TYPES_BY_SCALE" : "Types of sources by scale",
    "NUR_SULTAN_2" : "NUR-SULTAN",
    "ALMATY_2" : "ALMATY",
    "SHYMKENT_2" : "SHYMKENT",
    "AKMOLA_REGION_2" : "AKMOLA REGION",
    "AKTOBE_REGION_2" : "AKTOBE REGION",
    "ALMATY_REGION_2" : "ALMATY REGION",
    "ATYRAU_REGION_2" : "ATYRAU REGION",
    "EAST_KAZAKHSTAN_REGION_2" : "EAST KAZAKHSTAN REGION",
    "ZHAMBYL_REGION_2" : "ZHAMBYL REGION",
    "WEST_KAZAKHSTAN_REGION_2" : "WEST KAZAKHSTAN REGION",
    "KARAGANDY_REGION_2" : "KARAGANDY REGION",
    "KOSTANAY_REGION_2" : "KOSTANAY REGION",
    "KYZYLORDA_REGION_2" : "KYZYLORDA REGION",
    "MANGISTAU_REGION_2" : "MANGISTAU REGION",
    "PAVLODAR_REGION_2" : "PAVLODAR REGION",
    "NORT_KAZAKHSTAN_REGION_2" : "NORT KAZAKHSTAN REGION",
    "TURKESTAN_REGION_2" : "TURKESTAN REGION",
    "ABAY_REGION_2" : "ABAY REGION",
    "GETISU_REGION_2" : "GETISU REGION",
    "ULYTAU_REGION_2" : "ULYTAU REGION",
    "REGIONAL" : "REGIONAL",
    "CENTRAL" : "CENTRAL",
    "INTERNATIONAL" : "INTERNATIONAL",
    "TAGS_LIST" : "List of tags",
    "TAGS_CLOUD" : "Cloud of tags",
    "TAGS_BY_TIME" : "Tags by time",
    "POSTS_BY_TAGS" : "Posts by tags",
    "TAG" : "TAG",
    "EDIT_TAG": "Edit tag",
    "NUMBER_OF_ASSIGNMENTS" : "NUMBER OF ASSIGNMENTS",
    "SPECIAL_MARKS_2" : "SPECIAL MARKS",
    "TONES_OF_POSTS" : "TONES OF POSTS",
    "VIEW_POSTS" : "View posts",
    "shares_count": "SHARES",
    "likes_count": "LIKES",
    "comments_count" : "COMMENTS",
    "GRADE": "Grade",
    "POSTS" : "POSTS",
    "REPOSTS" : "SHARES",
    "LIKES" : "LIKES",
    "COMMENTS" : "COMMENTS",
    "ADDED_REPOSTS" : "EXTENDED SHARES",
    "LIST_OF_KEYWORDS" : "List of keywords",
    "KEYWORDS_CLOUD" : "Cloud of keywords",
    "KEYWORDS_BY_TIME" : "Keywords by time",
    "POSTS_BY_KEYWORDS" : "Posts by keywords",
    "LIST_OF_POPULAR_WORDS" : "List of popular words",
    "POPULAR_WORDS_CLOUD" : "Cloud of popular words",
    "POPULAR_WORDS_BY_TIME" : "Popular words by time",
    "POSTS_BY_POPULAR_WORDS" : "Posts by popular words",
    "POPULAR_WORDS_BY_SENTIMENT" : "Popular words by sentiment",
    "LIST_OF_ENTITIES" : "List of entities",
    "ENTITIES_CLOUD" : "Cloud of entities",
    "ENTITIES_BY_TIME" : "Entities by time",
    "POSTS_BY_ENTITIES" : "Posts by entities",
    "Male": "Men",
    "Female": "Weman",
    "WEMAN" : "Weman",
    "MEN" : "Men",
    "_COMMENT_12":"сравнение тем",
    "TOPICS_COMPARISON_BY_MASS_MEDIA_TYPE" : "Comparison of topics by type of mass media",
    "MASS_MEDIA_TYPE_&_SOCIAL_NETWORKS" : "Types of mass media and social networks",
    "LANGUAGE" : "Language",
    "MASS_MEDIA_STATUS" : "Status of mass media",
    "TOPICS_COMPARISON_BY_MENTIONS": "Comparison of topics by mentions",
    "TOPICS_COMPARISON_BY_MEDIA_TYPE" : "Comparison of topics by type of mass media",
    "TOPICS_COMPARISON_BY_TONE" : "Comparison of topics by tone",
    "TOPICS_COMPARISON_BY_SPECIAL_MARKS" : "Comparison of topics by special marks",
    "TOPICS_COMPARISON_BY_LANG" : "Comparison of topics by languagies",
    "TOPICS_COMPARISON_BY_MASS_MEDIA_STATUS" : "Comparison of topics by statuses of mass media",
    "TOPICS_COMPARISON_BY_REGIONS" : "Comparison of topics by regions",
    "_COMMENT_13":"страница конструктора отчетов",
    "LIST_OF_REPORTS" : "List of reports",
    "CREATE_REPORT" : "Create report",
    "REPORT_BUILDER" : "Report builder",
    "PAGES_ORIENTATION" : "ORIENTATION OF PAGES",
    "APPLY" : "Apply",
    "DOWNLOAD_FINISHED_REPORT" : "Download ready report",
    "SORTING" : "Sorting",
    "EMPTY_DOCUMENT" : "Report data is empty",
    "DATE_OF_CREATION" : "DATE OF CREATION",
    "DATE_OF_GENERATION" : "DATE OF GENERATION",
    "REPORT_DELETING" : "Deleting report",
    "CONFIRM_REPORT_DELETING" : "CONFIRM REPORT DELETING",
    "AVAILABLE_ELEMENTS" : "Available elements",
    "TEXT" : "Text",
    "TABLE" : "Table",
    "CHART" : "Chart",
    "LIST" : "List",
    "VALUE_WITH_DESCRIPTION" : "Value with description",
    "EXPORTED_FILTERS" : "Exported filters",
    "DATA_OF_EXTENDED_ANALYTICS_PAGE" : "Data of extended analytics page",
    "DATA_OF_TOPICS_COMPARISON_PAGE" : "Data of topics comparison page",
    "DATA_OF_FAVORITE_POSTS_PAGE" : "Data of favorite posts page",
    "DATA_FO_FAVORITE_AUTHORS_AND_SOURCES_PAGE" : "Data of favorite authors and sources page",
    "PERIOD" : "Period",
    "BREAKDOWN_ON" : "BREAKDOWN ON",
    "TOPIC_&_SUBTOPIC" : "Topic & subtopic",
    "SUBTOPIC" : "SUBTOPIC",
    "SUBTOPICS" : "SUBTOPICS",
    "SET_TOPIC_FOR_THE_WHOLE_REPORT" : "Set topic for the whole report",
    "SAVED_FILTER_SETTINGS" : "Saved filter settings",
    "SET_FILTER_FOR_THE_WHOLE_REPORT" : "Set filter for the whole report",
    "DELETE" : "Delete",
    "PAGE" : "PAGE",
    "SECTION" : "SECTION",
    "NAME" : "NAME",
    "DESIGN" : "DESIGN",
    "FIELDS" : "FIELDS",
    "_COMMENT_14":"Страница автоправил",
    "LIST_OF_AUTORULES" : "List of autorules",
    "CREATE_AUTORULE" : "Create autorule",
    "ONLY_ADDINGS_TO_THE_BLACK_LIST" : "ONLY ADDINGS TO THE BLACK LIST",
    "ONLY_ADDINGS_TO_THE_FAVORITES" : "ONLY ADDINGS TO THE FAVORITES",
    "ONLY_CHANGES_OF_VALUES" : "ONLY CHANGES OF VALUES",
    "ONLY_NOTIFICATIONS_SENDING" : "ONLY SENDING OF NOTIFICATIONS",
    "ONLY_DELETING_OF_POSTS" : "ONLY DELETING OF POSTS",
    "AUTORULE_TITLE" : "AUTORULE TITLE",
    "TYPE_OF_ACTION" : "TYPE OF ACTION",
    "NUMBER_OF_ACTIVATIONS" : "NUMBER OF ACTIVATIONS",
    "LAST_POST" : "Last post",
    "OPEN_ON_PAGE" : "Open of page",
    "AUTORULE_EDITING" : "Autorule editing",
    "AUTORULE" : "AUTORULE",
    "CHOOSE_THE_GROUP_OF_VALUES" : "CHOOSE THE GROUP OF VALUES",
    "CHOOSE_THE_VALUE" : "CHOOSE THE VALUE",
    "CHOOSE_THE_TYPE_OF_COMPARISON" : "CHOOSE THE TYPE OF COMPARISON",
    "ENTER_THE_VALUE" : "ENTER THE VALUE",
    "ADD_STRING" : "Add string",
    "CHOOSE_THE_TYPE_OF_ACTION" : "CHOOSE THE TYPE OF ACTION",
    "ADD_ACTION" : "ADD ACTION",
    "SAVE" : "Save",
    "NUMBER" : "Number",
    "VARIANT" : "Variant",
    "AUTHOR" : "Author",
    "TITLE_2" : "Title",
    "SOURCE_2" : "Soutce",
    "KEY_PARAGRAPH_TEXT" : "Text of key paragraph",
    "POST_TEXT" : "Text of post",
    "SOURCE_TAGS" : "Source tags",
    "DATE_OF_POST" : "Date of post",
    "INVOLVEMENT_INDEX" : "Involvment index",
    "NUMBER_OF_DUBLICATES" : "Number of dublicates",
    "NUMBER_OF_COMMENTS" : "Number of comments",
    "NUMBER_OF_LIKES" : "Number of likes",
    "NUMBER_OF_SHARES" : "Number of shares",
    "NUMBER_OF_VIEWS" : "Number of views",
    "SIZE_OF_AUDIENCE" : "Size of audience",
    "GEOGRAPHY_OF_POST" : "Geography of post",
    "PRESENCE_OF_AGGRESSION" : "Presence of aggression",
    "PRESENCE_OF_ADVERTISEMENT" : "Presence of advertisement",
    "PRESENCE_OF_OBSCENE_SPEECH" : "Presence of obscene speech",
    "TAG_2" : "Tag",
    "TYPE_OF_POST" : "Type of post",
    "TONE_OF_POST" : "Tone of post",
    "TOPIC_2" : "Topic",
    "NOT_EQUAL" : "Not equal",
    "EQUAL" : "Equal",
    "DOES_NOT_CONTAIN" : "Doesn't contain",
    "CONTAIN" : "Contain",
    ">" : ">",
    "<" : "<",
    "=" : "=",
    ">=" : ">=",
    "<=" : "<=",
    "!=" : "!=",
    "ADD_TO_THE_FAVORITES" : "Add to the favorites",
    "ADD_TO_THE_BLACK_LIST" : "Add to the black list",
    "CHANGE_VALUES" : "Change values",
    "SEND_NOTIFICATION" : "Send notification",
    "POST" : "Post",
    "OBSCENE_SPEECH_2" : "Obscene speech",
    "ADVERTISEMENT_2" : "Advertisement",
    "TO_EMAIL" : "To email",
    "TO_TELEGRAM" : "To telegram",
    "YES" : "Yes",
    "NO" : "No",
    "NEUTRAL_4" : "Neutral",
    "NOT_DETERMINDED_4" : "Not determinded",
    "POSITIVE_4" : "Positive",
    "NEGATIVE_4" : "Negative",
    "_COMMENT_15":"модальные окна",
    "STATUSES_OF_SERVER_REQUESTS" : "Statuses of server requests",
    "PERIOD_2" : "PERIOD",
    "DATA" : "DATA",
    "STATUS" : "STATUS",
    "READY_SENT_TO_THE_EMAIL" : "Ready - sent to email",
    "READY" : "Ready",
    "REQUEST_IS_PROCESSING_PLEASE_WAIT" : "Request is processing please wait",
    "ERROR" : "Error",
    "ERROR_RE_REQUEST_REQUIRED" : "Error - rerequest if required",
    "ERROR_CONTACT_SUPPORT" : "Error - contact support",
    "GETTING_SELECTION_REPORT" : "Getting selection report",
    "UPLOAD" : "Upload",
    "GETTING_CUSTOM_REPORT" : "Getting custom report",
    "RESEND_POST" : "Resend post",
    "SEND_TO_THE_EMAIL" : "SEND TO THE EMAIL",
    "SEND_TO_THE_TELEGRAM" : "SEND TO THE TELEGRAM",
    "EMAIL_ADDRESS" : "EMAIL ADDRESS",
    "THOOSE_NAME_OF_THE_GROUP" : "THOOSE NAME OF THE GROUP",
    "PHONE_OF_THE_TELEGRAM_USER" : "PHONE OF THE TELEGRAM USER",
    "SAVE_LINK_TO_THE_CLIPBOARD" : "Save link to the clipboard",
    "SAVE_FILTER_SETTINGS" : "Save filter settings",
    "ENTER_SETTINGS_NAME" : "ENTER SETTINGS NAME",
    "ADDING_POST" : "Adding post",
    "POST_LINK" : "LINK TO THE POST",
    "SCAN_LINK" : "Scan link",
    "YOUR_LINK_SCAN_RESULT_WILL_BE_DISPLAYED_HERE" : "THE RESULT OF YOUR LINK SCAN WILL BE DISPLAYED HERE",
    "CONTACT_SUPPORT" : "Contact support",
    "ADD_EXISTING_TAGS" : "Add existing tag",
    "CREATE_NEW_TAG" : "Create new tag",
    "TEXT_OF_TAG" : "TEXT OF TAG",
    "ENTER_TEXT_OF_TAG" : "ENTER TEXT OF TAG",
    "FILL_COLOR" : "FILL COLOR",
    "TAG_PREVIEW" : "TAG PREVIEW",
    "_COMMENT_16":"база знаний и FAQ",
    "CONTENT" : "Content",
    "CONTENT_SEATCH" : "CONTENT SEATCH",
    "TEXT_DEEP_SEARCH" : "TEXT DEEP SEARCH",
    "REPORT_MISTAKE" : "Report mistake",
    "IMAGE" : "Image",
    "MISTAKE_IN_THE_KNOWLEDGE_BASE" : "Mistake in the knowledge",
    "KNOWLEDGE_BASE_SECTION_NAME" : "KNOWLEDGE BASE SECTION NAME",
    "KNOWLEDGE_BASE_ARTICLE_NAME" : "KNOWLEDGE BASE ARTICLE NAME",
    "DESCRIPTION" : "DESCRIPTION",
    "DESCRIBE_MISTAKE_OR_INACCURACY_YOU_ENCOUNTERED" : "DESCRIBE MISTAK OR INACCURACY YOU'WE ENCOUNTERED",
    "_COMMENT_17":"настройки",
    "USERS" : "Users",
    "DIGESTS" : "Digests",
    "REPORTS" : "Reports",
    "SAVED_FILTERS" : "Saved filters",
    "LIST_OF_FILTERS" : "List of filters",
    "CONNECTED_ACCOUNTS" : "Connected accounts",
    "USERS_LOGS" : "Users logs",
    "_COMMENT_18":"настройки прав пользователей",
    "LIST_OF_USERS" : "List of users",
    "#" : "#",
    "EMAIL_2" : "EMAIL",
    "CREATE_USER" : "Create user",
    "EDITING_USER_RIGHTS" : "Editing users rights",
    "RIGHTS_LIST_FILTER" : "Rights list filter",
    "ACTIVATED_FIRST" : "Activated first",
    "DEACTIVATED_FIRST" : "Deactivated first",
    "CREATE_USERS" : "Create users",
    "EDIT_DELETE_USERS" : "Edit/delete users",
    "VIEW_USERS_LIST" : "View list of users",
    "CREATE_TOPICS" : "Create topics",
    "EDIT_DELETE_TOPICS" : "Edit/delete topics",
    "VIEW_TOPICS_LIST" : "View list of topics",
    "FAVORITES_LISTS_WORKING" : "Favorites lists working",
    "TRASHBIN_LISTS_WORKING" : "Trashbin lists working",
    "BLACK_LISTS_WORKING" : "Black lists working",
    "AUTORULES_CREATION" : "Autorules creation",
    "EDIT_DELETE_AUTORULES" : "Edit/delete autorules",
    "AUTORULES_VIEW" : "Autorules view",
    "VIEW_TOPICS_SELECTION" : "View topics selection",
    "MANUAL_POST_ADDING" : "Manual post add",
    "POST_TONE_CHANGING" : "Post tone change",
    "CREATE_FILTERS_SETTING" : "Filters settings create",
    "EDIT_DELETE_FILTER_SETTINGS" : "Edit/delete filters settings",
    "APPLY_FILTER_SETTINGS" : "Filters settings apply",
    "CREATE_REPORTS" : "Create reports",
    "EDIT_DELETE_REPORTS" : "Edit/delete reports",
    "VIEW_DOWNLOAD_REPORTS" : "View & download reports",
    "VIEW_EXTENDED_ANALYTICS" : "View extended analytics",
    "DOCUMENTS_DOWNLOAD_ANALYTICS_SELECTION" : "Documents download (analytics/selection)",
    "VIEW_TOPICS_COMPARISON" : "View topics comparison",
    "LIST_OF_DIGESTS" : "List of digests",
    "DIGESTS_CREATION" : "Create digests",
    "EDIT_DELETE_DIGESTS" : "Edit/delete digests",
    "VIEW_DIGESTS_LIST" : "view list of digests",
    "CREATE_EMAIL_NOTIFICATIONS" : "Create email notifications",
    "EDIT_DELETE_EMAIL_NOTIFICATIONS" : "Edit/delete email notifications",
    "VIEW_EMAIL_NOTIFICATIONS_LIST" : "View list of email notifications",
    "CREATE_TELEGRAM_NOTIFICATIONS" : "Create Telegram notifications",
    "EDIT_DELETE_TELEGRAM_NOTIFICATIONS" : "Edit/delete Telegram notifications",
    "VIEW_TELEGRAM_NOTIFICATIONS_LIST" : "View Telegram notifications list",
    "ANSWER_POSTS" : "Answer posts",
    "LINKING_THIRD_PARTY_ACCOUNTS" : "Linking third party accounts",
    "EDIT_DELETE_LINKED_THIRD_PARTY_ACCOUNTS" : "Edit/delete linked third party accounts",
    "CREATE_NEWSBREAKS" : "Create newsbreaks",
    "EDIT_DELETE_NEWSBREAKS" : "Edit/delete newsbreaks",
    "VIEW_NEWSBREAK_SELECTION" : "View newsbreaks selection",
    "_COMMENT_19":"настройки данных и списка тем пользователей",
    "USER_DATA_EDIT" : "User data edit",
    "PHONE_NUMBER" : "PHONE NUMBER",
    "CHANGE_PASSWORD" : "Change password",
    "NEW_PASSWORD" : "NEW PASSWORD",
    "REPEAT_NEW_PASSWORD" : "REPEAT NEW PASSWORD",
    "LIST_OF_ASSIGNED_TOPICS" : "LIST OF ASSIGNED TOPICS",
    "LIST_OF_RIGHTS" : "LIST OF RIGHTS",
    "LIST_OF_USER_ASSIGNED_TOPICS_EDIT" : "Edit list of user assigned topics",
    "LIST_OF_TOPICS_FILTER" : "LIST OF TOPICS_FILTER",
    "SORT_BY" : "SORT BY",
    "DELETE_USER" : "User delete",
    "CONFIRM_THIS_USER_DELETE" : "CONFIRM THIS USER DELETE",
    "YOU_HAVE_UNSAVED_CHANGES_IN_DATA" : "You have unsaved changes in data",
    "DONT_SAVE" : "Don't save",
    "_COMMENT_20":"настройки дайджестов",
    "TOPIC_NAME" : "TOPIC NAME",
    "PERIODICITY" : "PERIODICITY",
    "SENDING_TIME" : "SENDING TIME",
    "FORMAT" : "FORMAT",
    "CREATE_DIGEST" : "Create digest",
    "DIGEST_DISTRIBUTION_SETUP" : "Digest distribution setup",
    "NUMBER_OF_DIGESTS_PER_DAY" : "NUMBER OF DIGESTS PER DAY",
    "DIGESTS_SENDING_TIME" : "DIGESTS SENDING TIME",
    "DIGESTS_SENDING_DAYS" : "DIGESTS SENDING DAYS",
    "CHOOSE_ALL" : "Choose all",
    "MONDAY" : "Monday",
    "TUESDAY" : "Thuesday",
    "WEDNESDAY" : "Wednesday",
    "THURSDAY" : "Thursday",
    "FRIDAY" : "Friday",
    "SATURDAY" : "Saturday",
    "SUNDAY" : "Sunday",
    "DIGESTS_SENDING_DATES" : "DIGESTS SENDING DATES",
    "ENTER_DATE" : "ENTER DATE",
    "ADD" : "Add",
    "RECIPIENTS_EMAILS" : "RECIPIENTS EMAILS",
    "ENTER_EMAIL" : "ENTER EMAIL",
    "CHARTS" : "CHARTS",
    "DIGEST_WITHOUT_CHARTS" : "Digest without charts",
    "DIGEST_FORMAT" : "DIGEST FORMAT",
    "DIGEST_SETTINGS_VIEW" : "Digest settings view",
    "DIGEST_DELETE" : "Digest delete",
    "CONFIRM_THIS_DIGEST_DELETE" : "CONFIRM THIS DIGEST DELETE",
    "_COMMENT_21":"настройки тем",
    "TOPICS_LIST" : "List of topics",
    "EDIT_DATE" : "EDIT DATE",
    "KEY_WORDS_2" : "KEY WORDS",
    "STOP_WORDS" : "STOP WORDS",
    "CREATE_TOPIC" : "Create topic",
    "SUBTOPICS_LIST" : "List of subtopics",
    "CREATE_SUBTOPIC" : "Create subtopic",
    "TOPIC_SETTINGS_EDIT" : "Topic settings edit",
    "SUBTOPIC_SETTINGS_EDIT" : "Subtopic settings edit",
    "TOPIC_SETTINGS_VIEW" : "Topic settings view",
    "GENERAL" : "General",
    "ADVANCED" : "Advanced",
    "ENTER_TITLE" : "ENTER TOPIC NAME",
    "SELECTION_LIMIT" : "SELECTION LIMIT",
    "CONNECTION_WITH_CABINET" : "CONNECTION WITH CABINET",
    "OTHER_2" : "Other",
    "MAIN" : "Main",
    "COMPETITORS" : "Competitors",
    "PRODUCTS" : "Products",
    "PERSONS" : "Persons",
    "AFFILIATED_COMPANIES" : "Affiliated companies",
    "COMPANIONS" : "Companions",
    "PROMO" : "Promos",
    "DEPARTMENTS" : "Departments",
    "AKIMS" : "Akims",
    "MANAGEMENT" : "Management",
    "ALL" : "All",
    "WORLD_NEWS" : "World news",

    "AZERBAIJAN" : "Azerbaijan",
    "ARMENIA" : "Armenia",
    "BELARUS" : "Belarus",
    "KAZAKHSTAN" : "Kazakhstan",
    "KYRGYZSTAN" : "Kyrgyzstan",
    "MOLDOVA" : "Moldova",
    "RUSSIA" : "Russia",
    "TAJIKISTAN" : "Tajikistan",
    "TURKMENISTAN" : "Turkmenistan",
    "UZBEKISTAN" : "Uzbekistan",
    "UZBEKISTAN" : "Өзбекстан",

    "KEY_WORDS_CREATION" : "KEY WORDS CREATION",
    "ENTER_NEW_WORD" : "ENTER NEW WORD",
    "WORDS" : "WORDS",
    "TOPIC_GEOGRAPHY" : "TOPIC GEOGRAPHY",
    "LINKS_TO_PROFILES_&_SOURCES" : "LINKS TO PROFILES AND SOURCES",
    "LINKS_TO_PROFILES" : "LINKS TO PROFILES",
    "LINKS_TO_SOURCES" : "LINKS TO SOURCES",
    "PROFILE_OR_SOURCE_URL" : "PROFILE OR SOURCE URL",
    "SETTINGS_IMPORT" : "SETTINGS IMPORT",
    "TOPIC_DELETE" : "Topic delete",
    "CONFIRM_THIS_TOPIC_DELETE" : "CONFIRM THIS TOPIC DELETE",
    "_COMMENT_22":"настройки отчета",
    "REPORT_PREVIEW_WINDOW" : "Report preview window",
    "REPORT_SETTINGS" : "Report settings",
    "REPORT_DESIGN" : "Report design",
    "GRAPHICS_AND_TABLES" : "Graphics and tables",
    "REPORT_ELEMENT" : "Report element",
    "TOPIC_NAME_2" : "Topic name",
    "HEADERS_AND_FOOTERS" : "Headers and footers",
    "COLLECTION_MAIN_INFORMATION" : "Main information about collection",
    "MENTIONING_DYNAMICS" : "Mentioning dynamics",
    "POPULAR_RESOURCES" : "Popular resources",
    "GEOGRAPHY_OF_RESOURCES" : "Geography of resources",
    "MEDIA_TYPE" : "Media type (international, regional etc.)",
    "AUTHORS_OF_POSTS" : "Authors of posts (for social networks)",
    "COMMUNITIES_FOR_SN" : "Communities (for social networks)",
    "INVOLVMENT" : "Involvment (for social networks)",
    "STATISTIC_OF_AUTHORS" : "Statistic of authors (for social networks)",
    "GEOGRAPHY_OF_AUTHORS_FOR_SN" : "Geography of authors (for social networks)",
    "PUBLICATION_DETAILS" : "Publication details",
    "TEXT_REPRESENTATION": "Text representation",
    "NAME_OF_PUBLICATION" : "Name of publication",
    "ADDRESS" : "Address (URL)",
    "DATE" : "Date",
    "DUBLICATES" : "Dublicates",
    "PUBLICATION_SHORT_TEXT" : "Publication short text",
    "PUBLICATION_TEXT" : "Publication text",
    "NUMBERING" : "Numbering",
    "SEPARATE_DUBLICATES" : "Separate dublicates",
    "GROUP_BY" : "Group by",
    "GROUPING_TYPE": "Grouping type",
    "GROUP" : "Group",
    "TONE_2" : "tones",
    "MASS_MEDIA_TYPES" : "mass media types",
    "RESOURCE" : "Resource",
    "RESOURCES" : "Resources",
    "WITHOUT_GROUPING" : "without grouping",
    "TAGS_2" : "tags",
    "TONES_AND_MESS_MEDIA_TYPES" : "tones and mass media types",
    "MEDIA_TYPES_AND_TONES" : "mass media types and tones",
    "SUBTHEMES" : "subthemes",
    "_COMMENT_23":"настройки уведомлений",
    "LIST_OF_NOTIFICATIONS" : "List of notifications",
    "CREATED" : "CREATED",
    "ACTIVATED" : "ACTIVATED",
    "CONDITION_OF_ACTIVATION" : "CONDITION OF ACTIVATION",
    "CREATE_NOTIFICATION" : "Create notification",
    "CREATE_SUBSCRIPTION_NOTIFICATION" : "Create notifications to subscription",
    "NOTIFICATION_EDIT" : "Edit notification",
    "NOTIFICATION_VIEW" : "view notification",
    "EDIT_COMPANY" : "Edit company",
    "SUBTHEME_NAME" : "SUBTHEME NAME",
    "MEDIA_TYPE_2" : "MEDIA TYPE",
    "MEDIATYPE" : "MEDIA TYPE",
    "REACTIONS" : "REACTIONS",
    "NUMBER_OF_POSTS" : "Number of posts",
    "NUMBER_OF_NEW_POSTS" : "Number of new posts",
    "NUMBER_OF_SELECTED_POSTS" : "Number of selected posts",
    "CHANGE_OF_NUMBER_OF_PUBLICATIONS" : "Change of number of publications",
    "WITH_NEGATIVE_TONE" : "With negative tone",
    "WITH_NEUTRAL_TONE" : "With neutral tone",
    "WITH_POSITIVE_TONE" : "With positive tone",
    "WITH_UNIDENTIFIED_TONE" : "With unidentified tone",
    "WITH_OBSCENE_SPEECH" : "With obscene speech",
    "WITHOUT_REGARD_TO_TONE_AND_MARKS" : "Without regard to tone and marks",
    "PER_DAY" : "Per day",
    "PER_WEEK" : "Per week",
    "PER_MONTH" : "Per month",
    "YOU_CAN_ENTER_NEGATIVE_VALUES_WITH_-_SIGN" : "You can enter negative values with - sign.",
    "NOTIFICATON_DELETE" : "Notification delete",
    "CONFIRM_THIS_NOTIFICATION_DELETE" : "CONFIRM THIS NOTIFICATION DELETE",
    "_COMMENT_24":"настройки telegram",
    "NUMBER_GROUP" : "NUMBER/GROUP",
    "PHONE_NUMBERS_OR_GROUP_NAMES" : "PHONE NUMBERS OR GROUP NAMES",
    "HOW_TO_SETUP_SENDING" : "HOW TO SETUP SENDING?",
    "CHOOSE_GROUP_NAME" : "CHOOSE GROUP NAME",
    "CHOOSE_PHONE_NUMBER" : "CHOOSE PHONE NUMBER",
    "RESET_ALL" : "Reset all",
    "_COMMENT_25":"настройки фильтров",
    "FILTER_DELETE" : "Filter delete",
    "CONFIRM_THIS_FILTER_DELETE" : "CONFIRM THIS FILTER DELETE",
    "_COMMENT_26":"настройки подключенных учетных записей",
    "LIST_OF_ACCOUNTS" : "List of accounts",
    "CREATE_ACCOUNT" : "Create account",
    "UPDATE_ACCOUNT": "Update account",
    "SHOW_ACCOUNT": "Show account",
    "ACCOUNT_DELETE" : "Account delete",
    "CONFIRM_ACCOUNT_DELETE" : "CONFIRM THIS ACCOUNT DELETE",
    "CONNECTION_OF_ACCOUNT" : "Connection of account",
    "RESOURCE" : "RESOURCE",
    "CHOOSE_RESOURCE_NAME" : "CHOOSE RESOURCE NAME",
    "ENTER_LOGIN" : "ENTER LOGIN",
    "ENTER_PASSWORD" : "ENTER PASSWORD",
    "_COMMENT_27":"логи пользователей",
    "CHOOSE_ALL_2" : "Choose all",
    "LOG_FOR_SELECTED" : "Log for selected",
    "VIEW_LOG" : "View log",
    "DATE_2" : "Date",
    "TIME" : "TIME",
    "NEXT_ACTION_TIME" : "NEXT ACTION TIME",
    "MOVING" : "MOVING",
    "CREATING" : "CREATING",
    "CHANGING" : "CHANGING",
    "DELETING" : "DELETING",
    "_COMMENT_28":"Сообщения для пользователя",
    "ONE_SHOULD_BE":"ONE OF THE FIELDS MUST BE FILLED",
    "EXIT":"Exit",
    "_COMMENT_29":"упущенное",
    "ARE_YOU_REALLY_WANT_TO_EXIT":"Are you really want to exit",
    "CLEAR_DATA_CONTENTS_DELETED_KEYWORDS": "Clear data contents deleted keywords?",
    "EVERY_PUBLICATION_TELEGRAM": "EVERY PUBLICATION", 
    "NEGATIVE_SENTIMENT_COUNT_TELEGRAM": "NEGATIVE SENTIMENT COUNT",
    "NEUTRAL_SENTIMENT_COUNT_TELEGRAM": "NEUTRAL SENTIMENT COUNT", 
    "POSITIVE_SENTIMENT_COUNT_TELEGRAM": "POSITIVE SENTIMENT COUNT", 
    "UNDEFINED_SENTIMENT_COUNT_TELEGRAM": "UNDEFINED SENTIMENT COUNT", 
    "NEGATIVE_SENTIMENT_PERCENT_TELEGRAM": "NEGATIVE SENTIMENT PERCENT", 
    "NEUTRAL_SENTIMENT_PERCENT_TELEGRAM": "NEUTRAL SENTIMENT PERCENT", 
    "POSITIVE_SENTIMENT_PERCENT_TELEGRAM": "POSITIVE SENTIMENT PERCENT", 
    "UNDEFINED_SENTIMENT_PERCENT_TELEGRAM": "UNDEFINED SENTIMENT PERCENT", 
    "EVERY_PUBLICATION_PERCENT_TELEGRAM": "EVERY PUBLICATION PERCENT",
    "EVERY_PUBLICATION": "EVERY PUBLICATION",
    "EVERY_PUBLICATION_PERCENT": "EVERY PUBLICATION PERCENT",
    "PERCENT": "PERCENT",
    "ALERT_POLICY_AGREGATE_COUNT": "ALERT - COUNT",
    "ABSOLUTE_COUNT": "ABSOLUTE COUNT",
    "AGGREGATE_PERCENT": "PERCENT",
    "AGGREGATE_COUNT": "COUNT",
    "AGGREGATE": "AGGREGATE",
    "METRCIC": "Metrics",
    "METRIC_TYPES": "Metric type",
    "POLICY": "POLICY",
    "HOURLY": "Hour",
    "DAILY" : "Day",
    "WEEKLY" : "Week",
    "MONTHLY" : "Month",
    "POLICY": "POLICY",    
    "PUBLICTION": "Публикацию",
    "RESOURCE": "Resource",
    "FROM_FAVOURITE": "Убрать из избранных",
    "PROFILE_URL": "Profile URL",
    "RESOURCE_URL": "Resource URL",
    "PROFILE": "Profile",
    "PROFILES": "Profiles",
    "DELIVERY_TYPE": "Delivery type",
    "DAYS_COUNT" : "DAYS COUNT",
    "ADMIN": "Administrator",
    "COMPANY_MANAGEMENT" : "Company management",
    "CREATE_COMPANY" : "Create company",
    "USER_MANAGEMENT" : "User management",
    "TOPIC_MANAGEMENT" : "Topic management",
    "WITHOUT_FILTER_TYPE": "Without filter type",
    "WITH_TAGS": "With tags",
    "WITHOUT_TAGS": "Without tags",
    "VIEW_FILTER": "View filter",
    
    'TOPIC_NAME': 'Topic name', 
    'PERIOD': 'Period', 
    'REQUEST_DATE': 'Request date', 
    'COLONTITLES': 'Colontitles', 
    'TABLE_OF_CONTENTS': 'Nable of contents',
    'MAIN_INFO': 'Meida info',
    'SENTIMENT_DYNAMICS': 'Sentiment dynamics', 
    'LANGUAGE_DYNAMICS': 'Language dynamics', 
    'LANGUAGE_TOTAL': 'Language total', 
    'MEDIA_TYPE_DYNAMICS': 'Meida type dynamics', 
    'MEDIA_TYPE_TOTAL': 'Media type total', 
    'MEDIA_TYPE_BY_SENTIMENT': 'Meida type by sentiment', 
    'KEYWORDS': 'Keywords',
    'STOPWORDS': 'Stopwords', 
    'POPULAR_WORDS': 'Popular words', 
    'TAGS': 'Tags', 
    'POPULAR_RESOURCES': 'Popular resources', 
    'MEDIA_GEOGRAPHY': 'Media geography',
    'MEDIASCALE': 'Media scale',  
    'MEDIA_SCALE': 'Media scale', 
    'SOCIAL_DOMAIN_DYNAMICS': 'Social domain dynamics', 
    'SOCIAL_DOMAIN': 'Social domain', 
    'AUTHORS': 'Authors', 
    'GROUPS': 'Groups', 
    'AUTHORS_GEOGRAPHY': 'Authors gegraphy', 
    'PROFILE_AGE': 'Profile age', 
    'PROFILE_GENDER': 'Пол профайля', 
    'INVOLVEMENT': 'Involvement', 
    'PUBLICATION_TYPE_BY_SENTIMENT': 'Publication type by sentiment', 
    'PUBLICATION_TYPE_TOTAL': 'Publication ype total', 
    'MENTIONS_FEED': 'Mention feed',
    'CREATE_REPORT_SETTINGS': 'Create report settings',
    'DOCUMENT_TITLE': 'Document title', 
    'DOCUMENT_DOMAIN': 'Document domain', 
    'DOCUMENT_AUTHOR': 'Document author', 
    'DOCUMENT_URL': 'Document URL', 
    'DOCUMENT_TYPE': 'Document type', 
    'DOCUMENT_SENTIMENT': 'Document sentiment', 
    'DOCUMENT_LOCATION': 'Document location', 
    'DOCUMENT_DATE': 'Document date', 
    'DOCUMENT_COVERAGE': 'Document coverage', 
    'DOCUMENT_TAGS': 'Document tags', 
    'DOCUMENT_TEXT': 'Document text', 
    'DOCUMENT_NUMERATION': 'Document numeration',

    'HIGHLIGHTED_FULL_TEXT': 'Highlighted full text', 
    'PLAIN_FULL_TEXT': 'Plain full text', 
    'HIGHLIGHTED_FRAGMENTS': 'Highlighted fragments',

    "ADD_POLICY": "Add policy",
    "DELETE_POLICY": "Delete policy",

    'BY_SENTIMENT': 'By sentiment', 
    'BY_MEDIA_TYPE': 'By media type', 
    'BY_DOMAIN': 'By domain', 
    'NO_GROUPING': 'No grouping', 
    'BY_TAGS': 'By tags', 
    'BY_SENTIMENT_AND_MEDIA_TYPE': 'By sentiment and media type', 
    'BY_MEDIA_TYPE_AND_SENTIMENT': 'By media type and sentiment', 
    'BY_SUBTOPICS': 'By subtopics',
    'COMPANY': 'COMPANY',
    'COMPANY_1': 'COMPANY',
    'COMPANIES': 'Companies',
    'ACCOUNTS': 'Accounts',
    'ACCOUNT': 'Account',
    'COMPANY_NAME': 'Company name',
    'EXIT_COMPANY': 'Exit company',
    'UPDATE': 'Update',
    'COMPANY_ACCOUNTS': 'Company accounts',
    'COMPANY_TOPICS': 'Company topics',
    'ACCOUNT_TOPICS': 'Account topics',
    'TOPIC_ACCOUNTS': 'topic accounts',
    'ACCOUNT_LOCALE': 'Locale',
    'ATTACH': 'Attach',
    'DETACH': 'Detach',
    'ATTACH_ACCOUNT_TO_TOPIC': "Attach account to topic",
    'ATTACH_TOPIC_TO_ACCOUNT': "Attach topic to account",
    'ATTACH_TOPIC_TO_MARKER': "Attach topic to marker",
    'ATTACH_ACCOUNT_TO_COMPANY': 'Attach account to company',
    'ATTACH_ACCOUNT_TO_COMPANY_AT_FIRST': 'Attach account to company at first',
    'DETACH_ACCOUNT_FROM_COMPANY': 'Detach account from company',
    'ACTIVE': 'Active',
    'WITH_TAG': 'with tag',
    'WITHOUT_TAG': 'without tag',
    "EXPIRE_DATE" : "Expire date",
    "BIN": "BIN",
    "ROLE": 'Role',
    "SURE_WANT_TO_DELETE": "Do you really want to delete?",
    "SURE_WANT_TO_STOP": "Do you really want to stop?",
    "SURE_WANT_TO_DETACH": "Do you really want to detach?",
    "SURE_WANT_TO_EXIT": "Do you really want to exit?",
    "SURE_WANT_TO_EXIT_COMPANY": "Do you really want to exit company?",
    "AUTHORITIES": "Authorities",
    "ROLE_SUPER_ADMIN": "Super administrator",
    "ROLE_ADMIN": "Administrator",
    "ROLE_MARKER": "Marker",
    "ROLE_USER": "User",
    "STATES": "States",
    "EVERYTHING_EXCEPT_KAZAKHSTAN" : "Everything except Kazakhstan",
    "MASS_INCLUDE": "Media include",
    "SOCIAL_INCLUDE": "Social include",
    "SOCIAL_ALLOW_UNDEFINED_LOCATION": "social allow undefined location",
    "PAUSE": "pause",
    "TOPIC_CREATED_SUCCEFULLY": "Topic created successfully",
    "SUBTOPIC_CREATED_SUCCEFULLY": "Subtopic created successfully",
    "TOPIC_CREATED_WITH_ERRORS": "Topic created with errors",
    "SUBTOPIC_CREATED_WITH_ERRORS": "Topic created with errors",
    "TOPIC_NOT_CREATED": "Topic not created",
    "TOPIC_STARTED": "Topic successfully started",
    "TOPIC_STOPED": "Topic successfully stoped",
    "TOPIC_DELETED": "Topic successfully deleted",
    "SUBTOPIC_NOT_CREATED": "Subtopic not created",
    "USER_TAGS": "User tags",
    "ITEM_NAME": "Name",
    "WORD_TITLES": "WORD TITLES",
    "WORD_ANALYTICS": "WORD ANALYTICS",
    "PDF_TITLES": "PDF TITLES",
    "PDF_ANALYTICS": "PDF ANALYTICS",
    "EXCEL_TITLES": "EXCEL TITLES",
    "EXCEL_ANALYTICS": 'EXCEL ANALYTICS',
    "JSON_FEED": "JSON FEED",
    "JSON_ANALYTICS": "JSON ANALYTICS",
    "JSON_FEED_ANALYTICS": "JSON FEED ANALYTICS",
    "INACTIVE": "Inactive",
    "BLOCKED": "Blocked",
    "CONTRACT_EXPIRED": "Срок действия договора истек",
    "DATE_CREATED_AT_↓" : "BY_CREATED AT DATE ↓",
    "DATE_CREATED_AT_↑" : "BY CREATED AT DATE ↑",
    "DATE_UPDATED_AT_↓" : "BY UPDATED AT DATE ↓",
    "DATE_UPDATED_AT_↑" : "BY UPDATED AT DATE ↑",
    "SET_ACTIVE" : "Active",
    "SET_PAUSE" : "Pause",
    "SET_DELETE" : "Delete",
    "SET_VIEW": "View",
    "SET_EDIT": "Edit",
    "SET_ADD": "Add",
    "SET_REMOVE": "Remove",
    "SET_COPY": "Copy",
    "SET_RETURN": "Return",
    "SET_GO_TO_COMPANY": "Go to company",
    "TOPICS_VISIBILITY": "Topics visiblity",
    "SEARCH_BY_TITLE": "Search by title",
    "MARKERS": "Markers",
    "ARCHIVE_COLLECTION": "Archival collection",
    "ARCHIVE_COLLECTION_BY_PRFILES": "Archival collection by profiles",
    "ARCHIVE_COLLECTION_BY_RESOURCES": "Archival collection by resources",
    "URL_IS_ALREADY_BEING_CHECKED": "Profile URL is alredy being checked",
    "URL_IS_NOT_BEING_CHECKED": "Profile URL is not being checked",
    "DOMAIN_IS_ALREADY_BEING_CHECKED": "Domain is alredy being checked",
    "DOMAIN_IS_NOT_BEING_CHECKED": "Domain is not being checked",
    "MESSAGE_SENDED": "Message are sended",
    "THANK_FOR_MESSAGE": "Thank you for your message, it has been processed.",
    "FIELD_NOT_BE_EMPTY": "The contact or email field must not be empty",
    "HOW_CAN_WE_CONTACT_TO_YOU": "How can we contact you",
    "OBLIGATORY_FIELD": "Obligatory field",
    "ONLY_NUMBERS_AND_LETTERS_AT_LEAST_3": "Только буквы и числа, не менее 3-х символов",
    "ONLY_NUMBERS_AND_LETTERS": "Только буквы и числа",
    "ONLY_LETTERS": "Only letters",
    "FEED_TYPES": "Feed types",
    "MEDIA_TYPES" : "Media types",
    "SENTIMENTS": "Sentiments",
    "LANGUAGES": "Languages",
    "MEDIA_SCALES": "Media scales",
    "PROFILE_LOCATIONS": "Profiles locations",
    "tv": "Television",
    "print": "Print mass media",
    "news_agency": "News agency",
    "internet": "Internet ",
    "social": "Soicial network",
    "positive": "Positive",
    "undefined": "Undefined",
    "negative": "Negative",    
    "neutral": "Neutral",
    "regional": "Regional",
    "central": "Central",
    "international": "Iernational",
    "is_swearing": "Swearing",
    "ISSWEARING": "Swearing",
    "DOWNLOADS": "Download",
    "post": "Post",
    "comment": "Comment",
    "share": "Share",
    "article": "Article",
    "extended_share": "Extended share",
    "VIDEO": "Video",
    "video": "Video",
    "COLLAPSE": "Collapse",
    "READ_MORE": "Read more",
    "RETURN_BACK_TO": "Return to",
    "DELAYED_START_TIME": "DELAYED START TIME",
    "STARS_ON_CENTER_OR_END": "The asterisk wildcard symbol(*) may only be used at the end or in the middle of a keyword (stop word).",
    "NO_AMPERSANDS": "The AND logical operator (ampersand, &&) may only be used to connect two or more keywords (stop words), must be separated from them by a space symbol on either side, and cannot be placed at the beginning or the end of the input string.",
    "THREE_AMPERSANDS": "The use of three or more consecutive ampersand (&) symbols is forbidden.",
    "ONE_SYMBOL_WITH_STAR": "The asterisk wildcard symbol(*) may only be used with keywords (stop words) two symbols long or more; combinations such as a*, b* etc. are forbidden.",
    "NO_CHARACHTERS": "The following symbols are forbidden: :;!,%()\/\\@",
    "SINGLE_STAR": "Asterisk and question mark wildcard symbols detached from keywords (stop words) are forbidden.",
    "EXCLUSIVE_QUERIES_WITHOUT_SUBQERIES" : "Exclusive queries must not have subqueries.",
    "QUERY_WITHOUT_EXCLUSIVE_SUBQERIES" : "The query must not have exclusive subqueries.",
    "SELECT_FILTER": "Select filter",
    "SELECT_LANGUAGE": "Select language",
    "SELECT_ROLE": "Select role",
    "is_advertising": "Advertising",
    "ISADVERTISING": "Advertising",
    "reviewInclude": "Review include",
    "WITH_ADVERTISING": "Advertising content",
    "WITHOUT_ADVERTISING": "Without ads",
    "WITHOUT_ADVERTISING_FILTERS": "No ad filters",
    "profile_followers_count": "Followers count",
    "WITHOUT_SWEARING": "Without profanity",
    "WITH_SWEARING": "Profanity",
    "WITHOUT_SWEARING_FILTERS": "No profanity filters",
    "ALL_DOCUMENTS": "All documents", 
    "UNKNOWN": "Unknown",

    "FOR_RELEVANT_COLLECTION": "For relevant collection in the topic, it is necessary to prepare KEYWORDS and STOPWORDS using the logical operators of the system:",
    "LOGICAL_OPERATORS_WITH_EXAMPLES": "Logical operators + examples:",
    "OPERATOR": "Operator",
    "WHAT_FOR_USE_IT": "What to use it for",
    "EXAMPLES_OF_KEYWORDS_WITH_OPERATORS": "Examples of keywords with operators",
    "RESULT_IN_PUBLICATION_TEXT": "Results in the text of the publication",
    "STAR": "* (asterisk)",
    "MATCHES_ENDING_TO_KEYWORD": "Matches the ending to the keyword, including the ending that can be null, and also declines it.",
    "TALDYKORGAN_STAR": "Taldykorgan*",
    "TALDYKORGAN_DECLINATION": "Taldykorgan, Taldykorgans, Taldykorganical etc.",
    "CAN_USE_COMPLEX_PHRASES": "You can use more complex phrases",
    "TALDYKORGAN_AVIA_BASE": "Taldykorgan* avia* bas*",
    "TALDYKORGAN_AVIA_BASE_DECLINATION": "Taldykorgan Aviation base, Taldykorganical Aviation basical etc.",
    "LINKING_MULTIPLE_PARTS": "The connection of several parts of keywords that are located within the text of one publication. When searching, the requirement that all parts of the associated keyword must be present is met. In this case, the order of the parts of the keyword does not matter.",
    "TALDYKORGAN_SUBSIDIES": "Taldykorgan* && subsid*",
    "CONSTRUCTIVE_DIALOG_IN_TALDYKORGAN": "In Taldykorgan, authorities and business entered into a constructive dialogue. In the region, 4.5 thousand agricultural entities received 10.5 billion tenge in the form of subsidies.",
    "TALDYKORGAN_AUTHORITIES_AND_SUBSIDIES": "Taldykorgan* author* && agricultur && subsid*",

    "TO_SEARCH_ENTER_WORDS": "To search, enter the words you need, click add. Further entered words will be displayed below in the list",
    "FOR_MORE_PRECISE": "For a more precise search, use the following search operators:",
    "EXCLUSIVE": "exclusive",
    "NEGATIVE_2": "negative",
    "SEARCH_HIGHLIGHT": "search highlight:",
    "EXAMPLES_OF_USING_SEARCH_OPERATORS": "Examples of using search operators",
    "FOLLOWING_SEARCH_OPERATORS": "The following search operators are supported",
    "FUZZY_SEARCH": "fuzzy search",
    "AND": "and",
    "PRESUMPTIVE_SEARCH": "presumptive search",
    "CAN_BE_USED_A_WORD": "can be used at the end, middle or beginning of a word:",
    "WILL_RETURN": "will return",
    "ETC": "etc.",
    "USED_TO_MATCH_FIXED": "used to match a fixed number of characters (arbitrary)",
    "RETURNED_CORONAVIRUS": "coron?virus will return both coronavirus and coronovirus",
    "RETURNED_AMERICA": "america?? will return americans and americano, but will not return american or america",
    "USED_AT_THE_END": "used at the end of a phrase of two or more words to search for text in which these words are no more than N words apart:",
    "GAVE_AN_ORDER": "President order”~1 will return the result “President gave order”, while in order to get the result “President of Kazakhstan gave order”, the request will have to be formulated as “president order”~3",
    "QUERIES_FORMULATED_INDIVIDUAL": "queries can be formulated both in individual words and in multi-word phrases",
    "SEARCH_OPERATORS_COMBINED": "search operators can be combined: “coron?virus*”, “cancel? select*”~10",
    "HISTORICAL_MIGRATION": "Historical migration",
    "VIEW_HISTORICAL_MIGRATION": "View historical migration",
    "CREATE_HISTORICAL_MIGRATION": "Create historical migration",
    "COLLECTION": "Collection",
    "ACCOUNT_NAME": "Account name",
    "DATE_START": "Start date",
    "DATE_END": "End date",
    "EXPECTED_DOCUMENTS": "Expected documents count",
    "CURRENT_DOCUMENTS": "Current documents count",
    "LOADED_DOCUMENTS_PERCENT": "Loaded documents percent",
    "START_MIGRATION": "Start migration",
    "GET_EXPECTED_DOCUMENTS": "Get migration expected documents",
    "EXPECTED_DOCS / CURRENT_DOCS": "Expected / current documents",
    "PAUSED": "Paused",
    "СOMPLETE": "complete",
    "FAILED": "Failed",
    "RETRY": "Retry",
    "PENDING": "Pending",
    "common_location_country_id": "Location",
    "COMMONLOCATIONCOUNTRYID": "Location",
    "WITHOUT_VALUE": "Without value",
    "HIDE_LEFT_PANEL": "Hide left panel",
    "HIDE_RIGHT_PANEL": "Hide right panel",
    "SHOW_LEFT_PANEL": "Show left panel",
    "SHOW_RIGHT_PANEL": "Show right panel",
    "SEARCH_TAG": "Search tag",
    "NO_DATA_TODAY": "Absent from the memories today",
    "NO_DATA": "Absent memories",
    "DOMAIN": "Domain",
    "OPEN_IN_MODAL": "Open in modal window",
    "FULL_LIST": "Full list",
    "SELECTED_SPACE": "selected",
    "TOPICS_SELECTED": "topics",
    "TOPICS_SELECTED_1": "topics",
    "TOPICS_SELECTED_2": "topics",
    "SUBTOPICS_SELECTED": "subtopics",
    "SUBTOPICS_SELECTED_1": "subtopics",
    "SUBTOPICS_SELECTED_2": "subtopics",
    "CODE": "Code",
    "BOT": "Bot",
    "ADD_TELEGRAM_BOT": "Add Telegram bot",
    "SUBSCRIBE_TO_TELEGRAM": "Subscribe to telegram",
    "DEAR_USER": "Dear user",
    "FIND_TELEGERAM_BOT": "Find a bot on telegram and send him the code",
    "PRESS_TO_COPY": "Press to copy",
    "SUBSCRIPTION_TO_NOTIFICATIONS": "Subscriptions to notifications",
    "en": "English",
    "ru": "Russian",
    "kz": "Kazakh",
    "kk": "Kazakh",
    "be": "Belarusian",
    "uk": "Ukrainian",
    "fr": "French",
    "kt": "Kazakh (transliterate)",
    "da": "Danish",
    "ca": "Catalan",
    "nl": "Dutch, Flemish",
    "eo": "Esperanto",
    "de": "German",
    "no": "Norwegian",
    "gl": "Galician",
    "it": "Italian",
    "sk": "Slovak",
    "sv": "Swedish",
    "fi": "Finnish",
    "es": "Spanish",
    "is": "Icelandic",
    "pt": "Portuguese",
    "rt": "Russian (transliterate)",
    "sl": "Slovenian",
    "ro": "Romanian",
    "pl": "Polish",
    "el": "Greek (Modern)",
    "et": "Estonian",
    "hu": "Hungarian",
};

export default Titles;