import FiltersController from "./filters-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "store/side-bar";
import { getAllFilters, deleteMyFilter } from "store/filters";
import { clearSearchList } from 'store/search-text';

let mapStateToProps = (state) => {
    return {
        myFilters: state.Filters.myFilters,
        header: state.filtersSettings.filterHeader,
        controlButtons: state.filtersSettings.controlButtons,
        selectedLanguage: state.languages.selectedLanguage,
    }
}

const FiltersContainer = connect(mapStateToProps, {  getValue, 
                                setRightMenuComponent, 
                                setShowRightMenu, 
                                setRemoveRightMenuComponent,
                                getAllFilters,
                                clearSearchList,
                                deleteMyFilter})(FiltersController)

export default FiltersContainer