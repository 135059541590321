import { connect } from "react-redux";
import React from "react";
import { getValue } from 'store/languages';
import TopicItemController from "./topic-item-controller";
import { 
            getTopicData, 
            createTopic, 
            editTopic, 
            setTopicItem, 
            clearTopicItem, 
            createSubTopic, 
            getSubtopics, 
            setItemByType, 
            removeItemByType, 
            setTopicItemUpdated, 
            setSelectedAdvanced, 
            setSelectedAdvancedAll, 
            setSelectedChanged, 
            updateTopicSettings, 
            checkProfile, 
            setCheckProfile, 
            checkResource, 
            setCheckResource, 
            setSelectedUser, 
            topicVisibilitiesToAccount, 
            topicNotVisibleToAccount, 
            getTopicAccounts } 
        from "store/topic-item";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent, setRemoveLeftMenuComponent } from "store/side-bar";
import { addKeyword, addStopword, addProfiles, deleteKeyword, deleteStopword, deleteProfiles, setType } from "store/keywords";
import { getTopicItemKeywords } from "selectors/topic-item-selector";
import { getGeoplaceCountries, getRegions, getCountries } from "store/geoplace";
import { getAdminUsers, getAdminActiveUsers } from "selectors/admin-topics-selector";
import { getInternationalCountries } from "selectors/topic-item-selector";
import { modalShowToggle } from "store/modal";
import { getSelectedTopicsUUID, getSelectedSubTopicsUUID } from "selectors/topic-menu-selector";
import { setSelectedSubtopicsAll } from "store/topic-menu"

let mapStateToProps = (state) => {
    return {
        role: state.Account.role,
        //topicId: state.TopicItem.id,
        //topic: state.TopicItem.data,
        selectedLanguage: state.languages.selectedLanguage,
        name: state.TopicItem.name,
        relations: state.TopicItem.relations,
        themeKeywords: getTopicItemKeywords(state),
        //keywords: state.Keywords.keywords,
        //stopwords: state.Keywords.stopwords,
        //profiles: state.Keywords.profiles,
        mediaLimit: state.TopicItem.mediaLimit,
        socialNetworkLimit: state.TopicItem.socialNetworkLimit,
        showDeleteOld: state.Keywords.showDeleteOld,
        deleteOld: state.Keywords.deleteOld,
        topicItem: state.TopicItem.topicItem,
        topicItemUpdated: state.TopicItem.topicItemUpdated,
        massMediaType: state.TopicItem.massMediaType,
        massMediaScale: state.TopicItem.massMediaScale,
        sentimentList: state.TopicItem.sentimentList,
        selectedAdvancedItems: state.TopicItem.selectedAdvancedItems,
        selectedChanged: state.TopicItem.selectedChanged,
        countries: getInternationalCountries(state),
        regions: state.geoplace.regions,
        domains: state.TopicItem.domains,
        publicationType: state.TopicItem.publicationType,
        languages: state.TopicItem.languages,
        sng: state.TopicItem.sng,
        checkProfileItem: state.TopicItem.checkProfileItem,
        checkResourceItem: state.TopicItem.checkResourceItem,
        selectedRole: state.AdminMode.selectedRole,
        activeUsers: getAdminActiveUsers(state),
        selectedUser: state.TopicItem.selectedUser,
        oldSelectedUser: state.TopicItem.oldSelectedUser,
        selectedTopics: getSelectedTopicsUUID(state),
        selectedSubtopics: getSelectedSubTopicsUUID(state),
        selectedTopicsItems: state.TopicMenu.selectedTopics,
    }
}

const TopicItemContainer = connect(mapStateToProps, { 
                                                        getValue, 
                                                        createTopic, 
                                                        editTopic, 
                                                        getTopicData, 
                                                        clearTopicItem, 
                                                        createSubTopic, 
                                                        getSubtopics,
                                                        setItemByType,
                                                        removeItemByType,
                                                        setTopicItemUpdated,
                                                        addKeyword, addStopword, addProfiles, deleteKeyword, deleteStopword, deleteProfiles, setType,
                                                        setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent,
                                                        setSelectedAdvanced,
                                                        setSelectedAdvancedAll,
                                                        setSelectedChanged,
                                                        getGeoplaceCountries,
                                                        getRegions,
                                                        getCountries,
                                                        updateTopicSettings,
                                                        checkProfile,
                                                        setCheckProfile,
                                                        checkResource, 
                                                        setCheckResource,
                                                        modalShowToggle,
                                                        setSelectedUser,
                                                        topicVisibilitiesToAccount,
                                                        topicNotVisibleToAccount,
                                                        getTopicAccounts,
                                                        setSelectedSubtopicsAll
                                                    })(TopicItemController);

export default TopicItemContainer