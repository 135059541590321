import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { StandartButton } from "commons/form-controls";
import { useNavigate } from "react-router-dom";
import s from "./mobile-header.module.scss";
import MobileMenu from "./menu/mobile-menu";
import PanelListOfPublicationsContainer from "../panel-list-of-publications/panel-list-of-publications-container";
import useComponentVisible from "hooks/useComponentVisible";
import { SelectDropdown } from "commons/input-controls";
import Dropdown from "items/dropdown/dropdown";

const MobileHeader = (props) => {

    let navigate = useNavigate();

    const getLanguageItems = (LanguageItems) => {
        return LanguageItems.map( k => {return {name: k.name, short_name: k.short_name, title: props.getValue(k.title)}})
    }

    const getMenuByRole = (role) => {
        switch(role){
            case 'ROLE_SUPER_ADMIN':
                //if(props.adminCompanyId){
                let menuButtons = props.superAdminTopButtons ? props.superAdminTopButtons : []

                if(props.superAdminMediumButtons){
                    menuButtons = menuButtons.concat(props.superAdminMediumButtons)
                }
                if(props.superAdminRightTopButtons){
                    let n = [...props.superAdminRightTopButtons].reverse()
                    menuButtons = menuButtons.concat(n)
                }
                if(props.superAdminRightBottomButtons){
                    let b = [...props.superAdminRightBottomButtons]
                    menuButtons = menuButtons.concat(b);
                }
                return menuButtons
            case 'ROLE_ADMIN':
                let adminButtons = props.topButtons ? props.topButtons : [];
                if(props.mediumButtons)
                    adminButtons = adminButtons.concat(props.adminMediumButtons)
                if(props.rightTopButtons)
                    adminButtons = adminButtons.concat(props.rightTopButtons)
                if(props.rightBottomButton){
                    let b = [...props.rightBottomButton]
                    adminButtons = adminButtons.concat(b);
                }
                return adminButtons

            case 'ROLE_USER':
                let userButtons = props.topButtons ? props.topButtons : []
                if(props.mediumButtons){
                    let u = [...props.mediumButtons]
                    userButtons = userButtons.concat(u)
                }
                if(props.rightTopButtons){
                    let r = [...props.rightTopButtons]
                    userButtons = userButtons.concat(r)}
                    
                if(props.rightBottomButton){
                    let b = [...props.rightBottomButton]
                    userButtons = userButtons.concat(b);
                }
                return userButtons
            case 'ROLE_MARKER':
                let markerButtons = props.markerTopButtons ? props.markerTopButtons : []
                if(props.markerMediumButtons)
                    markerButtons = markerButtons.concat(props.markerMediumButtons)
                return markerButtons
        }
    }

    const LanguageItems = [
        {name: props.getValue('ru'), value: 'ru'},
        {name: props.getValue('en'), value: 'en'},
        {name: props.getValue('kz'), value: 'kz'}]

    const [showMenu, setShowMenu] = useState(false);
    const [menu, setMenu] = useState(getMenuByRole(props.role));
    const [selectedLanguage, setSelectedLnguage] = useState(props.selectedLanguage);

    let {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false);

    useEffect(() => {
        setMenu(getMenuByRole(props.role));
        setSelectedLnguage(props.selectedLanguage);
    }, [props])

    const showMenuToggle = () => {
        //setShowMenu(!showMenu)
        setIsComponentVisible(!isComponentVisible)
    }

    const showExitModal = (e, name) => {
        if(e === 'Exit'){
            if(props.role && props.role === 'ROLE_SUPER_ADMIN' && (props.adminCompanyId, props.accountId)){
                props.removeSuperAdminCompany(props.companyId, props.accountId);
            }
            props.exit();

        }
        
        else if(e === 'exitCompany') props.removeSuperAdminCompany(props.adminCompanyId, props.accountId);
    }

    const setSelectedPage = (name, type, text, title) => {
        if(type === 'modal'){
            switch(name){
                case 'Exit':
                    props.modalShowToggle(name, true, showExitModal, title, props.getValue('SURE_WANT_TO_EXIT'), props.getValue("EXIT"));
                    break;
                case 'ExitCompany':
                    props.modalShowToggle('exitCompany', true, showExitModal, title, props.getValue('SURE_WANT_TO_EXIT_COMPANY'), props.getValue('EXIT'));
                    break;
                case 'CallUs':
                    props.modalShowToggle(name, true, null, props.getValue('FEEDBACK_FORM'))
                    break;
                case 'NOTIFICATIONS':
                    props.modalShowToggle(name, true, null, props.getValue('STATUSES_OF_SERVER_REQUESTS'));
                    break;
                case 'ACCOUNT':
                    props.modalShowToggle('Account', true, null, props.getValue('ACCOUNT'));
                    break;
            }
            /*if(name === "Exit"){
                this.props.modalShowToggle(name, true, this.showExitModal, title, 'Вы действительно хотите выйти из системы?', "Выйти");
            }
            else{
                this.props.modalShowToggle(name, true, null, "Форма обратной связи")
            }*/

        }
        else
            this.props.selectPage(name);
            
    }

    const goto = (type, link, name) => {
        if(type !== 'nav')
            setSelectedPage(name, type, '', '')
        props.clearAllSelected(name, props.pageName)
        setIsComponentVisible(false)
    }

    const setSelectedLanguagetoToggle = (e, item) => {
        if(props.isAuth)
            props.setlocale('Languages ',item)
        else
            props.setSelectedLanguage(item)
    }

    /*const Languages = {
        items: props.LanguageItems,
        icon: '',
        callback: props.isAuth ? props.languageCallback : props.setSelectedLanguage,
        selected: props.selectedLanguage
    },*/

    return (
        <div className={s.MobileHeader} ref={ref}>
            <div className={s.LogoBlock}>
                { <Logo width="150" height="50"/> }
                <div className={s.languageBlok} >
                <Dropdown 
                    items={getLanguageItems(props.LanguageItems)}
                    callback={setSelectedLanguagetoToggle}
                    selected={props.getValue(selectedLanguage)}
                    width={100}/>
                    {props.isAuth ? <StandartButton icon={MenuIcon} callback={showMenuToggle} stroke={'white'}/> : null}
                </div>
                
            </div>
            { isComponentVisible ? <MobileMenu menu={menu} callback={goto} getValue={props.getValue} isSuperadmin={props.isSuperadmin} pageName={props.pageName}/> : null}
            { props.isShowPanel && <PanelListOfPublicationsContainer /> }
        </div>
    )
}

export default MobileHeader;