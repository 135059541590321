import { InputCheckBox, InputText } from "commons/input-controls";
import React, {useState, useEffect} from "react";
import { getTranslate } from "assets/titles/title-text";
import Badge from "items/badge/badge";
import s from "./list.module.scss";

const List = (props) => {
    const [list, setList] = useState(props.list ? props.list : []);
    const [selectedList, setSelectedList] = useState(props.selected ? props.selected : []);
    const [rerender, setRerender] = useState(false);
    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        setList(props.list ? props.list : []);
        setSelectedList(props.selected ? props.selected : [])
        setRerender(!rerender);
    }, [props]);

    const setSelectedItems = (isSelected, selected) => {
        let selectedItems = selectedList
        let f = selectedItems.indexOf(selected)
        if(f !== -1){
            selectedItems.splice(f, 1);
        }
        else{
            selectedItems.push(selected)
        }
        setSelectedList(selectedItems);
        props.setSelected(selectedItems);
        setRerender(!rerender)
    }

    const getName = (userId) => {
        let f = list.findIndex(k => k.id === userId)
        return f !== -1 ? list[f].name : userId
    }

    const addSearchText = (text) => {
        setSearchText(text+'')
    }

    const getItems = (k) => {
        return <div className={s.listItem} key={k.id}>
                    <InputCheckBox isChecked={selectedList.indexOf(k.id) !== -1} name={k.id} title={k.role ? getTranslate(k.role) : k.name} callback={setSelectedItems}/>
                    <span>{k.name}</span>
                </div>
    }

    const items = !props.showOnly ? (searchText ? list.filter((k) => k.name.indexOf(searchText) !== -1 || selectedList.indexOf(k.id) !== -1).map(k => getItems(k)) : list.map(k => getItems(k)))
                                    : selectedList.length ? selectedList.map((k, i) => <div key={i} className={s.listItem}><span>{i+1}. {getName(k)}</span></div>) : null

    
                                    const itemsList = selectedList.map((k,i) => <div key={i}><span>{i+1}. {getName(k)}</span></div>)

    return (
        <div className={s.List}>
            {list && list.length ? <div className={s.ListBlock}>
                {!props.showOnly ? <div className={s.ListHeader}>
                    <InputText value={searchText} callback={addSearchText} placeholder={getTranslate('SEARCH_BY_TITLE')}/>
                </div> : null}
                <div className={s.ListBody}>
                    {items}
                </div>
                
            </div> : <div className={s.list}></div>}
            {
                props.showList && selectedList.length >= 1 && !props.showOnly ? <div>
                    <div className={s.formRow}>
                        <span className="labelText">{ props.listTitle ? getTranslate(props.listTitle) : getTranslate('LIST') }</span>
                        {<Badge sum={selectedList.length} />}
                    </div>
                    <div className={s.attached}>
                        {itemsList}
                    </div>
                </div> : null
            }
        </div>
    )
}

export default List;