import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import s from "./table.module.scss";

let mapStateToProps = (state) => ({
    isAuth: state.authorization.isAuth,
    deviceType: state.header.deviceType,
    showRightMenu: state.SideBarMenu.showRightMenu,
})



const TableItem = (props) => {
    const getLastColumnWidth = () => {
        if(props.deviceType === 'mobile'){
            return 80+'px'
        }
        else{
            if(props.controlButtons){
                return props.controlButtons.length * 50+'px'
            }
            else{
                let w = 0
                if(props.data.length > 1){
                    w = props.data[1] && props.data[1][0] ? props.data[1][0][props.data[1][0].length-1].props.items.length*40+['px'] : 'auto'
                }
                return w
            }
        }

    }

    const [selectedItem, setSelectedItem] = useState(props.selectedItem ? props.selectedItem : null);
    const [hiddenColumns, setHiddenColumns] = useState(props.hiddenColumns ? props.hiddenColumns : [])
    const [hidden, setHidden] = useState(props.hidden ? props.hidden : [])
    const [lastColumnWidth, setLastColumnWidth] = useState(getLastColumnWidth())

    useEffect(() => {
        setSelectedItem(props.selectedItem);
        setHiddenColumns(props.hiddenColumns ? props.hiddenColumns : [])
        setHidden(props.hidden ? props.hidden : [])
        setLastColumnWidth(getLastColumnWidth())
    }, [props.selectedItem, props.hiddenColumns, props.hidden])

    const selectItem = (item) => {
        props.selectItem && props.selectItem(item)
    }

    const getHeader = (data) => {
        if(!data)
            return
        return data.map((h, i)=>{

            let wd = i === 0 ? '30px' : 'auto';
            
            return (hidden.indexOf(i) === -1) && <th data-hidden={props.showRightMenu && props.hiddenInEdit && props.hiddenInEdit.length && props.hiddenInEdit.indexOf(i) !== -1 ? 'hidden' : 'visibble'} title={h} key={i} width={wd}>{h}</th>
        })
    }

    const getBody = (data) => {
        
        let retData = [];
        if(!data){
            return
        }
        for(let i=0; i<data.length; i++){
            retData[i] = <tr className={(selectedItem !== null && Number(selectedItem) === i) ? s.SelectedRow : ''} key={i} onClick={selectItem.bind(this, i)}>{getRows(data[i], i)}</tr>
        }
       return retData;
    }

    const getStyles = (index) => {
        return 
    }

    const getRows = (data, i) => {
        if(!data.length){
            return;}
        let n = data.map((e,j)=>{
            let r = false;
            let w = 0

            if(j === data.length - 1 && (e && e.props)){
                if(e.props.items && e.props.items.length){
                    r = true
                    w = e.props.items.length*30
                    //setControllButtonWidth(e.props.items.length*30)
                }
            }
            //setControllButtonWidth(w)
            let wd = props.tableColWidth && props.tableColWidth.index == j ? props.tableColWidth.width : j === 0 ? '30px' : ( j === data.length -1 ? lastColumnWidth : 'auto')
            let styles = props.data[2] ? props.data[2][i] : []
            return (hiddenColumns.indexOf(j) === -1 && hidden.indexOf(j) === -1) && <td data-hidden={props.showRightMenu && props.hiddenInEdit && props.hiddenInEdit.length && props.hiddenInEdit.indexOf(j) !== -1 ? 'hidden' : 'visibble'} style={ j=== 1 ? {...styles} : {}} width={wd} key={j}>{e}</td>
        })
        if(props.controlButtons){
            (n.push(<td key={data.length+1} width={props.deviceType === 'mobile' ? 80 : props.controlButtons.length * 50}>{props.controllerButtons[i]}</td>))
        }


        return n;
    }

    const getTableWidth = () => {
       return props.height && Number(props.height) ? (props.height < 50 * (props.data[1] && props.data[1].length) ? 'calc(100% - 0.95em)' : '100%') : '100%'
    }

    return (
        <div className={s.tableBlock}>

            <div>
                <div className={s.TableTitle}>
                    <h1 className={s.tableHeaderText}>{ props.titleText }</h1>
                    {props.addItemButton}
                </div>
                
                <table className={props.showRightMenu ? s.ShortTable : ''}>
                    <thead style={{width: getTableWidth()}}>
                        <tr>
                            {getHeader(props.data[0])}
                            {
                                
                                <th  width={lastColumnWidth}></th>
                            }
                        </tr>
                    </thead>
                    <tbody style={{maxHeight: props.height ? props.height : 'auto'}}>
                        {getBody(props.data[1])}
                    </tbody>

                </table>
            </div>

        </div>
    )
}


const Table = connect(mapStateToProps, {})(TableItem)

export default Table

//export default TableItem;