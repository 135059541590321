import ApexChartController from "./apex-chart-controller";
import { connect } from "react-redux";
import { setSelectedMyFilterMenu, setMySelectedFilter, filtersChanged } from "store/filters"
import { setStartDate, setEndDate, setGrouping, setDateChanged, setGroupingByPeriod } from "store/calendar";
import { setTreeSelectedItems, setTreeSelectedUUId, setToNull } from "store/tree"
import { setReturnBackFilters, setReturnBackPageName, setReturnBackGrouping, setMenuSelected } from "store/return-back-button";
import { getStartDate, getEndDate } from "selectors/calendar-selector";
import { setSettingsMenuToNull } from "store/settings-menu"
import { setSelectedTopicsAll, setSelectedSubtopicsAll } from "store/topic-menu";

let mapStateToProps = (state) => {
    return {
        selectedMyFilter: state.Filters.selectedMyFilter,
        selectedItem: state.Tree.selectedItem,
        selectedId: state.Tree.selectedId,
        selectedUUId: state.Tree.selectedUUId,
        selectedSubId: state.Tree.selectedSubId,
        selectedSubUUId: state.Tree.selectedSubUUId,
        selected: state.settingsPageMenu.selectedMenuItem,
        grouping: state.Calendar.grouping, 
        startDate: getStartDate(state),
        endDate: getEndDate(state),
        deviceType: state.header.deviceType
    }
}

const ApexChartContainer = connect(mapStateToProps, {
                                                    setSelectedMyFilterMenu, 
                                                    setMySelectedFilter, 
                                                    filtersChanged, 
                                                    setStartDate, 
                                                    setEndDate,
                                                    setGroupingByPeriod,
                                                    setDateChanged, 
                                                    setTreeSelectedItems, 
                                                    setTreeSelectedUUId, 
                                                    setToNull, 
                                                    setSettingsMenuToNull, 
                                                    setReturnBackFilters, 
                                                    setReturnBackPageName, 
                                                    setReturnBackGrouping,
                                                    setSelectedTopicsAll,
                                                    setSelectedSubtopicsAll,
                                                    setMenuSelected})(ApexChartController);
export default ApexChartContainer;