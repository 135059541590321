import { AnalyticService } from "../connection/analytic-service";
import { PopularWordsService } from "connection/popular-words-service";
import { checkErrorMessage, getErrorMessage } from "./errors";
import { GeoplaceService } from "connection/geoplace-service"
import Menu from "assets/menu/menu";
import axios from "axios";

const SET_CLEAR_DATA = "SET-CLEAR-DATA";

const SET_ANALYTIC_IS_FETCHING = "SET-ANALYTIC-IS-FETCHING";

const SET_ANALYTIC_STARTED = "SET-ANALYTIC-STARTED";
const SET_FIRST_DATA = "SET-FIRST-DATA";
const SET_SECOND_DATA = "SET-SECOND-DATA";
const SET_THIRD_DATA = "SET-THIRD-DATA";
const SET_LOCATIONS_NAME = "SET-LOCATIONS-NAME";
const SET_FIRST_MAX = "SET-FIRST-MAX";
const SET_SECOND_MAX = "SET-SECOND-MAX";

const getDateTimeFormat = (date) => {
    return date//moment(date).format('YYYY-MM-DDTHH:mm:ss')+'Z'
}

const geoplace = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL+"geoplace/api/v1/"
})

const extendedAnalyticData = {
    menuItems: Menu.AnlyticPages,
    isFetching: false,
    fisrt: [],
    second: [],
    third: [],
    started: false,
    locationsName: null,
    firstMax: null,
    secondMax: null
}

let ExtendedAnalytic = (state = extendedAnalyticData, action) =>{
    switch(action.type){
        case SET_CLEAR_DATA:
            return {...state, first: [], second: []}
        case SET_ANALYTIC_IS_FETCHING:
            return {...state, isFetching: action.isFetching}
        case SET_FIRST_DATA:
            return {...state, first: action.fisrt}
        case SET_SECOND_DATA:
            return {...state, second: action.second}
        case SET_THIRD_DATA:
            return {...state, third: action.third}
        case SET_ANALYTIC_STARTED:
            return {...state, started: action.started}
        case SET_LOCATIONS_NAME:
            return {...state, locationsName: action.locationsName }
        case SET_FIRST_MAX:
            return {...state, firstMax: action.max}
        case SET_SECOND_MAX:
            return {...state, secondMax: action.max}
        default:
            return {...state}
    }
}

export default ExtendedAnalytic;

/*Set analytic data state */

export const setFirstMax = (max) => {
    return {
        type: SET_FIRST_MAX,
        max: max
    }
}

export const setSecondMax = (max) => {
    return {
        type: SET_SECOND_MAX,
        max: max
    }
}

export const setStarted = (started) => {
    return {
        type: SET_ANALYTIC_STARTED,
        started: started
    }
}

const setFirstData = (data) => {
    return {
        type: SET_FIRST_DATA,
        fisrt: data
    }
}

const setSecondData = (data) => {
    return {
        type: SET_SECOND_DATA,
        second: data
    }
}

const setThirdData = (data) => {
    return {
        type: SET_THIRD_DATA,
        third: data
    }
}

export const setAnalyticIsFetching = (isFetching) => {
    return {
        type: SET_ANALYTIC_IS_FETCHING,
        isFetching: isFetching
    }
}

export const setClearData = () => {
    return {
        type: SET_CLEAR_DATA,
        data: []
    }
}

export const setLocationsName = (locationsName) => {
    return {
        type: SET_LOCATIONS_NAME,
        locationsName: locationsName
    }
}

const getLocationsId = (data) => {
    let ret = data.map(k => k.value)
    return ret
}

/*Get analytic data state */

export const getDataType = (type, getData, setData, topicId, since, till, grouping, filters, subtopics, pageType, chartType = 'column', chartNumber, chartSettings) => {
    return (dispatch) => {
        getData(topicId, getDateTimeFormat(since), getDateTimeFormat(till), grouping, filters, subtopics).then(data => {
           if(data.length && chartSettings.max && data.length > chartSettings.max){
                data.length = chartSettings.max
            }
            dispatch(setData({data: [...data], topicId: subtopics && subtopics.length ? subtopics[0] : topicId, type: type, chartType: chartType, chartNumber: chartNumber, chartSettings: chartSettings, pageType}));
            if(type === 'AuthorsLocations'){
                let locations = getLocationsId(data)
                dispatch(getGeoplaceDictionary(locations))
            }
        }).catch( error => {    
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            dispatch(setAnalyticIsFetching(false))
        })
    }
}

export const getGeoplaceDictionary = (locationsIds) => {
    return (dispatch) => {
        GeoplaceService.getGeoplaceDictionary(locationsIds).then( data=> {
            dispatch(setLocationsName(data))
        })
    }
}

export const getDataByType = (type, topicId, since, till, grouping, filters, subtopics, tags, maxSize) => {
    return(dispatch)=>{
        dispatch(setAnalyticIsFetching(true));
        switch(type){
            case 'MENTIONS':
                dispatch(getDataType('languageRange', AnalyticService.getAnalyticLanguageRange, setFirstData, topicId, since, till, grouping, filters, subtopics, type, 'area', 'first',{label: 'languageDate', title: 'MENTIONING_BY_DATE', graphicType: {label: 'date', data: 'language'}, height: 290, width: '98%'}));
                dispatch(getDataType('language', AnalyticService.getAnalyticLanguage, setSecondData, topicId, since, till, grouping, filters, subtopics, type, 'donut', 'second',{label: 'PieValue', title: 'MENTIONING_BY_LANG', height: 290, width: '98%'}));
                dispatch(setThirdData([]))
                break;
            case 'TONE':
                dispatch(getDataType('SentimentRange', AnalyticService.getSentimentRange, setFirstData, topicId, since, till, grouping, filters, subtopics, type, 'area', 'first',{label: 'sentiment', title: 'TONE_BY_DATE', graphicType: {label: 'date', data: 'sentiment'}, height: 270, width: '98%'}));
                dispatch(getDataType('Sentiment', AnalyticService.getSentiment, setSecondData, topicId, since, till, grouping, filters, subtopics, type, 'column', 'second',{label: 'LanguageSentiment', title: 'TONE_BY_LANG', height: 270, width: '47%'}));
                dispatch(getDataType('SentimentData', AnalyticService.getSentimentRange, setThirdData, topicId, since, till, grouping, filters, subtopics, type, 'donut', 'third',{label: 'sentiment', title: 'SENTIMENT', graphicType: {label: 'sentiment', data: 'sentiment'}, height: 270, width: '47%'}))
                break;
            case 'TYPES_OF_POSTS':
                dispatch(getDataType('PublicationRange', AnalyticService.getPublicationTypeRange, setFirstData, topicId, since, till, grouping, filters, subtopics, type, 'area', 'first',{label: 'postType', title: 'POST_TYPES_BY_DATES', graphicType: {label: 'date', data: 'type'}, height: 290, width: '98%'}));
                dispatch(getDataType('Publication', AnalyticService.getPublicationType, setSecondData, topicId, since, till, grouping, filters, subtopics, type, 'column', 'second',{label: 'typeOfPosts', title: 'POST_TYPES_BY_LANG', height: 290, width: '98%'}));
                dispatch(setThirdData([]))
                break;
            case 'AUTHORS':
                dispatch(getDataType('AuthorsSentiment', AnalyticService.getAuthorsSentiment, setFirstData, topicId, since, till, grouping, filters, subtopics, type, 'column', 'first',{label: 'followers_count', title: 'AUTHORS', graphicType: {label: 'author', data: 'sentiment'}, height: 450, width: '100%', max: maxSize.first}));
                dispatch(getDataType('FollowersCount', AnalyticService.getFollowersCounts, setSecondData, topicId, since, till, grouping, filters, subtopics, type, 'column', 'second', {label: 'field', title: 'INFLUENCE_OF_AUTHORS', height: 450, width: '100%', max: maxSize.second}));
                
                dispatch(setThirdData([]))
                break;
            case 'TOP_20_MASS_MEDIA':
                dispatch(getDataType('Top20', AnalyticService.getTop20BySentiment, setFirstData, topicId, since, till, grouping, filters, subtopics, type, 'column', 'first', {label: 'followers_count', title: 'TOP_20_MASS_MEDIA', graphicType: {label: 'domain', data: 'sentiment'}, height: 450, width: '100%'}));
                dispatch(getDataType('Top20Languages', AnalyticService.getTop20ByLanguages, setSecondData, topicId, since, till, grouping, filters, subtopics, type, 'column', 'second', {label: 'followers_count', title: 'TOP_20_MASS_MEDIA_BY_LANG', height: 450, width: '100%'}));
                dispatch(setThirdData([]))
                break;
            case 'SOURCE_TYPE':
                dispatch(getDataType('MediaType', AnalyticService.getMediaTypesSentiments, setFirstData, topicId, since, till, grouping, filters, subtopics, type, 'column', 'first', {label: 'followers_count', title: 'TONE_BY_MASS_MEDIA_TYPES', graphicType: {label: 'mediaType', data: 'sentiment'}, height: 450, width: '100%'}));
                dispatch(setSecondData(null))
                dispatch(setThirdData([]))
                break;
            case 'TAGS':
                dispatch(getDataType('TagsList', AnalyticService.getTagsSentiments, setThirdData, topicId, since, till, grouping, filters, subtopics, type, 'column', 'third', {label: 'tags', title: 'POSTS_BY_TAGS', tags: tags, height: 290, width: '100%'}));
                dispatch(getDataType('Tags', AnalyticService.getTagsSentiments, setFirstData, topicId, since, till, grouping, filters, subtopics, type, 'column', 'first', {label: 'valueSentiment', title: 'POSTS_BY_TAGS', tags: tags, height: 290, width: '100%'}),);
                dispatch(getDataType('TagsRange', AnalyticService.getTagsRange, setSecondData, topicId, since, till, grouping, filters, subtopics, type, 'line', 'second', {label: 'value', title: 'TAGS_BY_TIME', tags: tags, height: 290, width: '100%'}));
                break;
            case 'REACH_OF_SOURCE':
                dispatch(getDataType('AuthorsInvolvementSentiment', AnalyticService.getInvolementSentiment, setFirstData, topicId, since, till, grouping, filters, subtopics, type, 'column', 'first', {label: 'involement', title: 'REACH_OF_SOURCE_BY_SENTIMENT', height: 290, width: '100%'}));
                dispatch(getDataType('AuthorsInvolvementMediaTypes', AnalyticService.getInvolementSentiment, setSecondData, topicId, since, till, grouping, filters, subtopics, type, 'donut', 'second', {label: 'involement', title: 'REACH_OF_SOURCE_BY_COUNT', height: 290, width: '100%'}));
                dispatch(setThirdData([]))
                break;
            case 'KEY_WORDS':
                dispatch(getDataType('KeyWords', AnalyticService.getKeywordsSentiment, setFirstData, topicId, since, till, grouping, filters, subtopics, type, 'column', 'first', {label: 'valueSentiment', title: 'POSTS_BY_KEYWORDS', height: 450, width: '100%'}));
                dispatch(getDataType('KeyWordsRange', AnalyticService.getKeywordsSentimentRange, setSecondData, topicId, since, till, grouping, filters, subtopics, type, 'line', 'second', {label: 'value', title: 'KEYWORDS_BY_TIME', height: 290, width: '100%'}));
                dispatch(getDataType('KeyWordsList', AnalyticService.getKeywordsSentiment, setThirdData, topicId, since, till, grouping, filters, subtopics, type, 'column', 'first', {label: 'keywords', title: 'POSTS_BY_KEYWORDS', height: 290, width: '100%'}));
                break;
            case 'GEOGRAPHY_OF_AUTHORS':
                dispatch(getDataType('AuthorsLocations', AnalyticService.getAuthorsLocationsIds, setFirstData, topicId, since, till, grouping, filters, subtopics, type, 'donut', 'first', {label: 'authorGeography', title: 'GEOGRAPHY_OF_AUTHORS', height: 450, width: '100%', max: maxSize.first}));
                dispatch(setSecondData(null))
                dispatch(setThirdData([]))
                break;
            case 'AUTHOR_STATISTICS':
                dispatch(getDataType('AuthorsAge', AnalyticService.getAuthorsAge, setFirstData, topicId, since, till, grouping, filters, subtopics, type, 'column', 'first', {label: 'ages', title: 'AUTHOR_STATISTICS_AGE', height: 290, width: '100%'}));
                dispatch(getDataType('AuthorsGender', AnalyticService.getAuthorsGender, setSecondData, topicId, since, till, grouping, filters, subtopics, type, 'donut', 'first', {label: 'genders', title: 'AUTHOR_STATISTICS_GENDER', height: 290, width: '100%'}));
                dispatch(setThirdData([]))
                break;
            case 'POPULAR_WORDS':
                dispatch(getDataType('PopularWordsBurst', PopularWordsService.getPopularWordsBurst, setFirstData, topicId, since, till, grouping, filters, subtopics, type, 'area', 'first', {label: 'PopularValue', title: 'POPULAR_WORDS_BY_TIME', graphicType: {label: 'date', data: 'language'}, height: 290, width: '100%'}));
                dispatch(getDataType('PopularWordsFacets', PopularWordsService.getPopularWordsFacets, setSecondData, topicId, since, till, grouping, filters, subtopics, type, 'column', 'second', {label: 'PopularValueSentiment', title: 'POPULAR_WORDS_BY_SENTIMENT', height: 450, width: '100%'}));
                dispatch(getDataType('PopularWords', PopularWordsService.getPopularWords,setThirdData, topicId, since, till, grouping, filters, subtopics, type, 'column', 'third', {label: 'popular', title: 'POPULAR_WORDS', height: 290, width: '100%'}));
                break;
            }
    }
}