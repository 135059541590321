import React, {useState, useEffect} from "react";
import List from "pages/settings-page/list/list";
import { StandartButton } from "commons/form-controls";
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import s from "./admin-topics-add-item.module.scss"
import { InputText } from "commons/input-controls";

const AdminTopicsAddItem = (props) => {

    const [accounts, setAccounts] = useState(props.accounts);
    const [markers, setMarkers] = useState(props.markers);
    const [selectedAccounts, setSelectedAccounts] = useState(props.topicAccounts);
    const [isMarkers, setIsMarkers] = useState(props.isMarker);

    useEffect(() => {
        setAccounts(props.accounts);
        setMarkers(props.markers);
        setSelectedAccounts(props.topicAccounts);
        setIsMarkers(props.isMarker);
    }, [props]);

    const old = props.topicAccounts ? [...props.topicAccounts] : []

    const setSelectedAccount = (accounts) => {
        setSelectedAccounts(accounts);
    }

    const getToDetachAccounts = () => {
        let detached = old.filter(k => selectedAccounts.indexOf(k) === -1)
        return detached
    }

    const getAttached = () => {
        let attached = selectedAccounts.filter(k => old.indexOf(k) === -1)

        return attached
    }

    const attachTopics = () => {
        let detach = getToDetachAccounts();
        let attach = getAttached();
        
        if(!isMarkers)
            props.attachAccountsToTopic(props.companyId, selectedAccounts, props.topicId);
        else
            props.attachMarkersToTopic(selectedAccounts, props.topicId)
        /*if(!isMarkers)
            props.detachAccountsFromTopic(props.companyId, detach, props.topicId);
        else
            props.detachMarkersfromTopic(detach, props.topicId)*/
        props.closeRightMenu();
    }

    const setIsMarkersType = (isMarkers) => {
        props.setIsMarker(isMarkers)
    }
    
    return (
        <div className={s.AdminTopicsAddItem}>
            <div className="formRow">
                <div className="labelText">{props.getValue('TOPIC')}</div>
                <InputText value={props.topicName} readOnly={true}/>
            </div>
            <div className="formRow">
                <div className="labelText">{props.getValue('ACCOUNTS')}</div>
                <div className={s.buttonsBlock}>
                    <StandartButton background={!isMarkers ? '' : 'white'} title={props.getValue('USERS')} callback={setIsMarkersType.bind(this, false)}/>
                    <StandartButton background={isMarkers ? '' : 'white'} title={props.getValue('MARKERS')} callback={setIsMarkersType.bind(this, true)}/>
                </div>
                <List list={isMarkers ? markers : accounts} setSelected={setSelectedAccount} selected={selectedAccounts} showOnly={false} showList={true} listTitle={'TOPIC_ACCOUNTS'}/>
            </div>
             
            <StandartButton title={props.getValue('ATTACH')} callback={attachTopics} icon={PlusIcon} stroke={'white'}/>
        </div>
    )
}

export default AdminTopicsAddItem;