import React, {useState, useEffect} from "react";
import s from "./topic-item.module.scss";
import { StandartButton } from "commons/form-controls";
import { InputText } from "commons/input-controls";
import GeneralTopicSettings from "./general/general";
import AdvancedTopicSettings from "./advanced/advanced";

const TopicItem = (props) => {

    const [topicItemType, setTopicItemType] = useState(false);
    const [topicTitle, setTopicTitle] = useState(props.topic && props.topic.name ? props.topic.name : '');
    const [parentTopicId, setParentTopicId] = useState(props.topicId ? props.topicId : null);

    useEffect(() => {
        setTopicTitle(props.topic && props.topic.name ? props.topic.name : '');
        setParentTopicId(props.topicId ? props.topicId : null);
    }, [props.topic, props.topicId])

    const setTopicTitleText = (e) => {
        setTopicTitle(e)
    }

    const switchTopicItemType = (topicItemType) => {
        setTopicItemType(topicItemType)
    }


    const getTextPlaceHolder = (type) => {
        switch(type){
            case 'create':
                return props.getValue('ENTER_TITLE');
            case 'createSubtopic':
                return props.getValue('ENTER_TITLE');
        }
    }

    const createTopic = (title, keywords, stopwords, profiles, resources, limit, migration, profileMigration, deleteOld, parentId, usersIds) => {
        let key = keywords.map(k => k.keyword)
        let stop = stopwords.map(k => k.keyword)
        let prof = profiles.map(k => k.url)
        let res =  resources.map(k => k.domain)
        setTopicTitle('');
        if(props.isSubtopic){
            limit = null
            usersIds = null

        }
        props.createTopic(title, key, stop, prof, res, limit, migration, profileMigration, deleteOld, parentId, usersIds, props.selectedRole);
        if(props.selectedSubtopics && props.selectedSubtopics.length)
            props.setSelectedSubtopicsAll([props.selectedTopicsItems[0]], [])
        props.closeRightMenu()
    }

    const attachAccountsToTopics = (userIds, topicId) => {
        props.topicVisibilitiesToAccount(userIds, topicId)
    }

    const editTopic = (topicId, topicTitle, limit, keywords, stopwords, socnetProfiles, resources, migration, profileMigration, deleteOld, settings = {}) => {
        let key = keywords.map(k => k.keyword)
        let stop = stopwords.map(k => k.keyword)
        let prof = socnetProfiles.map(k => k.url)
        let res =  resources.map(k => k.domain)
        if(props.isSubtopic){
            limit = null
        }
        props.updateTopic(topicId, topicTitle, limit, key, stop, prof, res, migration, profileMigration, deleteOld, settings)
    }

    return (
        <div className={s.TopicItem}>
            <div className={s.TopButtons}>
                <StandartButton title={props.getValue('GENERAL')} callback={switchTopicItemType.bind(this, false)} background={topicItemType ? 'white' : null}/>
                {props.isEdit === 'create' || props.isEdit === 'createSubtopic' || props.isSubtopic ? null : <StandartButton disabled={props.isEdit === 'create' || props.isEdit === 'createSubtopic'} title={props.getValue('ADVANCED')} callback={switchTopicItemType.bind(this, true)} background={!topicItemType ? 'white' : null}/>}
            </div>
            <div className="formRow">
                <div className="labelText">{props.getValue('ITEM_NAME')}</div>
                {/*props.isEdit !== 'create' && <InputText callback={setTopicTitleText} value={topicTitle} placeholder={props.getValue('ENTER_TOPIC_NAME')} readOnly={props.isEdit === 'show' && true}/>*/}
                {<InputText callback={setTopicTitleText} value={topicTitle} placeholder={getTextPlaceHolder(props.isEdit)} readOnly={(props.isEdit === 'show' || topicItemType) ? true : false}/>}
            </div>
        { !topicItemType ?      <GeneralTopicSettings {...props} topicName={topicTitle} createTopic={createTopic} editTopic={editTopic} parentTopicId={parentTopicId} attachAccountsToTopics={attachAccountsToTopics}/>
                            :
                                <AdvancedTopicSettings {...props} saveTopicSettings={props.saveTopicSettings}/>
            }
        </div>
    )
}

export default TopicItem;