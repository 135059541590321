import React, {useState} from "react";
import TableItem from "items/table/table";
import { StandartButton } from "commons/form-controls";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";

const Telegram = (props) => {

    let [selectedItem, setSelectedItem] = useState(0);

    const getButtonTitle = (deviceType) => {
        return deviceType === 'mobile' 
                ? ""
                : props.getValue('CREATE_SUBSCRIPTION_NOTIFICATION');
    }

    const selectTableITem = (item) => {
        if(selectedItem !== item){
            setSelectedItem(item);
        }
    }

    const createDigest = (type) => {
        props.onTableItemsClick(type)
    }

    const addItemButton = <StandartButton name={props.getValue('CREATE_SUBSCRIPTION_NOTIFICATION')} title={getButtonTitle(props.deviceType)} icon={PlusIcon} fill='white' callback={createDigest.bind(this, "create")}/>

    const getHiddenColumns = (deviceType) => {
        switch(deviceType){
            case 'mobile':
                return [0,2,3,4,5]
            case 'tablet':
                return [0,2,3,4]
            default:
                return []
        }
    }

    return(
        <div>
            {<TableItem 
                        addItemButton={addItemButton} 
                        data={[props.tableHeader, props.data]}
                        hiddenColumns={props.hiddenColumns}
                        getValue={props.getValue}
                        selectedItem={selectedItem}
                        selectItem={selectTableITem}
                        hidden={getHiddenColumns(props.deviceType)}
                        hiddenInEdit={[5]}
                        titleText={props.getValue('NOTIFICATIONS')}/>}
        </div>
        
    )
}

export default Telegram;

