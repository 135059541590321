import React, {useState, useEffect} from "react";
import { InputCheckBox, InputText } from "commons/input-controls";
import s from '../advanced.module.scss';

const AdvancedItem = (props) => {

    const getSng = () => {
        let n = true;
        props.sng.forEach( item => {
            if(props.selected.indexOf(item.id) === -1){
                n = false
            }
        })
        return n
    }

    const [title, setTitle] = useState(props.title);
    const [items, setItems] = useState(props.items);
    const [selected, setSelected] = useState(props.selected);
    const [selectedAll, setSelectedAll] = useState(Object.keys(props.selected).length === props.items.length ? true : false)
    const [showBlock, setShowBlock] = useState(props.showBlock);
    const [isSNGSelected, setIsSNGSelected] = useState(getSng());
    const [isKazakhstanSelected, setIsKazakhstanSelected] = useState(props.selected.indexOf('424311521') !== -1 ? true : false);
    const [isKazakhstanNotSelected, setIsKazakhstanNotSelected] = useState((Object.keys(props.selected).length === props.items.length-1 && !props.selected[424311521]) ? true : false)
    const [isReviewInclude, setIsReviewInclude] = useState(props.reviewInclude);
    const [filterText, setFilterText] = useState('');
    const [rerender, setRerender] = useState(false);

    useEffect(()=>{
        setItems(props.items);
        setSelected(props.selected)
        setSelectedAll(Object.keys(props.selected).length === props.items.length ? true : false);
        setTitle(props.title);
        setShowBlock(props.showBlock);
        setIsSNGSelected(getSng());
        setIsKazakhstanSelected(props.selected.indexOf('424311521') !== -1 ? true : false);
        setIsKazakhstanNotSelected((Object.keys(props.selected).length === props.items.length-1 && !props.selected[424311521]) ? true : false);
        setIsReviewInclude(props.reviewInclude);
        setRerender(!rerender);
    }, [props.items, props.selected, props.title, props.selectedChanged, props.showBlock, props.regions, props.reviewInclude]);

    const onKeyTextChange = (e) => {
        setFilterText(e.toLowerCase())
    }
    
    const setSelectedItem = (e, id, list, title) => {
        props.callback(title, e, id, list);
    }

    const setSelectedAllItems = (item, items, except = null, e) => {
        props.setSelectedItemsAll(title, e, items, except)
    }

    const setReviewInclude = (e, id, list, title) => {
        props.setReviewInclude(e)
    }

    const getItem = (titleN, index, id = null, list, t) => {
        
        let s = selected.indexOf(id ? id.toString() : list) !== -1 ? true : false
        return  filterText ? 
                    props.getValue(titleN).toLowerCase().indexOf(filterText) >= 0 ? 
                                                                <div className={s.item} key={id ? id : index}> 
                                                                    <InputCheckBox isDisabled={props.isEdit === 'show'} isChecked={s} name={id ? t+'-'+id : t+'-'+index} callback={setSelectedItem.bind(this, titleN, id, list, title)} title={titleN} />
                                                                    <span>{props.getValue(titleN)}</span>
                                                                </div> 
                                                                : null
                            :   <div className={s.item} key={id ? id : index}> 
                                    <InputCheckBox isDisabled={props.isEdit === 'show'} isChecked={s} name={id ? t+'-'+id : t+'-'+index} callback={setSelectedItem.bind(this, titleN, id, list, title)} title={props.getValue(titleN)} />
<                                   span>{props.getValue(titleN)}</span>
                                </div> 
    }

    const getRegion = <div className={s.SocialRegions}>
            {/*<div className={s.formRow}>
                <InputCheckBox isDisabled={props.isEdit === 'show'} isChecked={isKazakhstanSelected} name={props.getValue('KAZAKHSTAN')+title } callback={setSelectedItem.bind(this, props.getValue('KAZAKHSTAN'), 424311521, 'KAZAKHSTAN', title)} title={ props.getValue('KAZAKHSTAN') } />
                <span>{ props.getValue('KAZAKHSTAN') }</span>
            </div>
            <div className={s.formRow}>
                <InputCheckBox isDisabled={props.isEdit === 'show'} isChecked={isKazakhstanNotSelected} name={props.getValue('EVERYTHING_EXCEPT_KAZAKHSTAN')+title } callback={setSelectedAllItems.bind(this, props.title, items, [{name: props.getValue('KAZAKHSTAN'), id: 424311521}])} title={props.getValue('EVERYTHING_EXCEPT_KAZAKHSTAN')} />
                <span>{ props.getValue('EVERYTHING_EXCEPT_KAZAKHSTAN') }</span>
</div>
            <div className={s.formRow}>
                <InputCheckBox isDisabled={props.isEdit === 'show'} isChecked={isSNGSelected} name={props.getValue('CIS')+title } callback={setSelectedAllItems.bind(this, props.title, props.sng, [])} title={props.getValue('CIS')} />
                <span>{ props.getValue('CIS') }</span>
            </div>*/}
        </div>

    const itemList = items.map((k, i) => {
        return getItem(k.name ? k.name : k.list, i, k.id ? k.id : null, k.list, title)
    });

    return (
        <div className={s.AdvancedItem}>
            <div className={s.HeaderBlock}>
                {showBlock ? <div className={s.Item}>
                    {title === 'massCountryId' || title === 'socialLocationId' ? getRegion : null}
                    { items.length > 7 ? <div className={s.formRow}>
                        <InputText value={filterText} callback={onKeyTextChange} name="filterText" type="text" placeholder={props.getValue('ENTER_TITLE')} />
                    </div> : null}
                    <div className={s.formRow}>
                        <InputCheckBox isDisabled={props.isEdit === 'show'} isChecked={selectedAll} name={props.title} callback={setSelectedAllItems.bind(this, props.title, items, [])} title={props.getValue("CHOOSE_ALL")} />
                        <span>{ props.getValue('CHOOSE_ALL') }</span>
                    </div>
                </div>
                : <div></div> }
            </div>
            
            {showBlock ? <div className={s.ItemsBlock}>{itemList}</div> : <div></div> }

        </div>
    )
}

export default AdvancedItem;