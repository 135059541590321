import React, { useEffect, useState } from "react";
import TableItem from "items/table/table";
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { StandartButton } from "commons/form-controls";
import PaginatorContainer from "items/paginator/paginator-container";

const CompaniesTable = (props) => {
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        setSelectedItem(props.selected)
    }, [props])

    const selectTableItem = (item) => {
        //props.setSelected(item);
        props.setCompanyId(props.companies.companies[item].id, props.companies.companies[item].name, item);
        props.setAccountId(null); 
        props.setTopicId(null)

    }

    const addNewCompany = () => {
        props.showRightMenu('create')
    }

    const addItemButton = <StandartButton 
        icon={PlusIcon} 
        fill={'white'}
        title={props.getValue('CREATE_COMPANY')}
        callback={addNewCompany}
    />
    const tableBlock = props.isSuperadmin && !props.adminCompanyId 
            ? <React.Fragment>
                <PaginatorContainer />
                <TableItem 
                        data={[props.tableHeader, props.data]}
                        height={320}
                        addItemButton={addItemButton}
                        hiddenColumns={props.hiddenColumns}
                        selectItem={selectTableItem}
                        selectedItem={selectedItem}
                        hiddenInEdit={[2,4]}        
            /> 
            </React.Fragment>
            : <div></div>
    return (
            tableBlock 
    )
}

export default CompaniesTable