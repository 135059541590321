import React,{ useEffect, useState } from "react";
import List from "pages/settings-page/list/list";
import { StandartButton } from "commons/form-controls";
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import s from './admin-markers-add-item.module.scss'

const AdminMarkerAddItem = (props) => {
    const [topics, setTopics] = useState(props.adminTopics);
    const [selectedTopics, setSelectedTopics] = useState(props.markerTopics ? props.markerTopics : []);
    
    useEffect(() => {
        setTopics(props.adminTopics);
        setSelectedTopics(props.markerTopics ? props.markerTopics : []);
    }, [props]);

    const setTopicsItems = (topics) => {
        setSelectedTopics(topics)
    }

    const attachTopics = () => {
        props.attachTopicsToAccount(props.isSuperadmin, props.markerId, selectedTopics, props.companyId, props.role)
        props.closeRightMenu()
    }

    const getTopicsName = (topicId) => {
        let findedTopic = topics.findIndex(f => f.id === topicId)
        return topics[findedTopic] ? topics[findedTopic].name : topicId
    }

    const atacchedTopics = selectedTopics.map((k,i) => <div key={k}><span>{i+1}. {getTopicsName(k)}</span></div>)

    return (
        <div className={s.AdminMarkerAddItem}>
            <List showOnly={props.showOnly} list={topics} setSelected={setTopicsItems} selected={selectedTopics} showList={true} listTitle={'MAREKER_TOPICS'}/>
            {!props.showOnly ? <StandartButton title={props.getValue('ATTACH')} callback={attachTopics} icon={PlusIcon} stroke={'white'}/> : null }
            {/*selectedTopics && selectedTopics.length && !props.showOnly ? <div>
                <div className={s.formRow}>
                    <span className="labelText">{props.getValue('MAREKER_TOPICS')}</span>
                </div>
                <div className={s.attachedTopics}>
                    { atacchedTopics }
                </div>
            </div> : null*/}

        </div>
    )
}

export default AdminMarkerAddItem;